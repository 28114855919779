import React, { useEffect } from "react";
import { LoadingOverlay, Text, DEFAULT_THEME } from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthInfo } from "@propelauth/react";
import { IconX, IconCheck } from "@tabler/icons-react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { IError } from "interfaces/errors";
import { getFetcher } from "fetchers/fetchers";
import { logPageView } from "utils/segment";
import {
  errorNotification,
  successNotification,
  syncNotification,
} from "utils/notifications";

export const IntegrationOauthCallback: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { integrationName } = useParams();
  const authInfo = useAuthInfo();
  const queryClient = useQueryClient();

  const integration = integrationName || "salesforce";
  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");

  const { data: integrations } = useQuery<any, IError>(
    ["/api/integrations/organization"],
    () => getFetcher("/api/integrations/organization", authInfo?.accessToken)
  );

  useEffect(() => {
    if (!authInfo.loading) {
      logPageView(
        authInfo.user?.email,
        authInfo.user?.firstName + " " + authInfo.user?.lastName
      );
    }
    // eslint-disable-next-line
  }, [authInfo.loading]);

  const { isLoading, isError } = useQuery<any, IError>(
    [`/api/integrations/${integration}/callback`, code],
    () =>
      getFetcher(
        `/api/integrations/${integration}/callback?code=${code}`,
        authInfo?.accessToken
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["/api/integrations/organization"]);
        successNotification(
          "Integration",
          t(`Your ${integration} integration has been added.`)
        );
        if (integrations.length === 1) {
          syncNotification("Integration");
        }
        navigate("/integrations");
      },
      onError: (error: IError) => {
        console.error(error);
        errorNotification("Integration");
        navigate("/integrations");
      },
    }
  );

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Text size="xl">{t("Finishing up your OAuth")}</Text>
      <Text size="lg">
        {t("Please not leave this page until we are finished")}
      </Text>
      <div style={{ width: "100%", height: "100%", position: "relative" }}>
        <LoadingOverlay
          loaderProps={{ size: "xl" }}
          visible={isLoading}
          overlayOpacity={0}
        />
        <LoadingOverlay
          loaderProps={{ size: "xl" }}
          loader={
            isError ? (
              <IconX size={60} color={DEFAULT_THEME.colors.red[5]} />
            ) : (
              <IconCheck size={60} color={DEFAULT_THEME.colors.green[5]} />
            )
          }
          visible={!isLoading}
          overlayOpacity={0}
        />
      </div>
    </div>
  );
};
