import React, { useEffect, useState } from "react";
import { useAuthInfo, RedirectToLogin, useActiveOrg } from "@propelauth/react";
import {
  Text,
  createStyles,
  Card,
  Group,
  Button,
  rem,
  Center,
  Badge,
  Loader,
  LoadingOverlay,
  Container,
  Space,
  Anchor,
  Image,
} from "@mantine/core";
import logo from "assets/logo.png";
import LoadingFullPage from "../../utils/Loading";
import { logPageView } from "../../utils/segment";
import { getURL } from "../../utils/env";
import { Link, useNavigate } from "react-router-dom";
import { getCurrentOrg } from "../../utils/org";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { OrgWithOnboarding } from "types/types";
import { IconCheck } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },

  item: {
    "& + &": {
      paddingTop: theme.spacing.sm,
      marginTop: theme.spacing.sm,
      borderTop: `${rem(1)} solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },
  },

  switch: {
    "& *": {
      cursor: "pointer",
    },
  },

  title: {
    lineHeight: 1,
  },

  link: {
    color: "inherit",
    textDecoration: "inherit",
  },
}));

export default function Homepage() {
  const { classes } = useStyles();
  const authInfo = useAuthInfo();
  const navigate = useNavigate();
  let accessToken: string | null = null;
  if (!authInfo.loading) {
    accessToken = authInfo.accessToken;
  }
  const selectedOrg = useActiveOrg();
  const [orgID, setOrgID] = useState<string>("");
  useEffect(() => {
    if (orgID && orgID.length > 0) {
      return;
    }
    if (!authInfo.loading) {
      getCurrentOrg(selectedOrg, authInfo, accessToken).then((value) => {
        if (value) {
          let localOrgID = value.orgId;
          if (!localOrgID) {
            setOrgID("");
          } else {
            setOrgID(localOrgID);
          }
        }
      });
    }
  }, [authInfo, accessToken, orgID, selectedOrg]);

  useEffect(() => {
    if (!authInfo.loading) {
      logPageView(
        authInfo.user?.email,
        authInfo.user?.firstName + " " + authInfo.user?.lastName
      );
    }
    // eslint-disable-next-line
  }, [authInfo.loading]);

  const orgFetcher = useQuery({
    queryKey: [authInfo.accessToken, orgID, getURL("/onboarding")],
    queryFn: async () => {
      const { data } = await axios.get(getURL("/onboarding"), {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authInfo.accessToken,
          "Org-ID": orgID,
        },
      });

      return data;
    },
    enabled: (function () {
      if (authInfo.loading) {
        return false;
      }
      if (!orgID) {
        return false;
      }
      if (authInfo.accessToken) {
        return true;
      }
      return false;
    })(),
  });

  const orgWithOnboarding: OrgWithOnboarding | undefined = orgFetcher.data;

  if (!authInfo.loading && !authInfo.isLoggedIn) {
    return <RedirectToLogin />;
  }
  if (authInfo.loading) {
    return <LoadingFullPage></LoadingFullPage>;
  }

  const user = authInfo.user;

  const inviteYourTeammates = (redirect: boolean) => {
    let onboardingURL = getURL("/onboarding/invited_their_team_mates");
    fetch(onboardingURL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Org-ID": orgID,
      },
    }).then((data) => {
      let jsonPromise = data.json();
      Promise.resolve(jsonPromise).then((jsonResponse) => {
        if (redirect) {
          navigate("/settings/user-management");
        }
      });
    });
  };

  const exploreMetrics = (redirect: boolean) => {
    let onboardingURL = getURL("/onboarding/explored_metrics");
    fetch(onboardingURL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Org-ID": orgID,
      },
    }).then((data) => {
      let jsonPromise = data.json();
      Promise.resolve(jsonPromise).then((jsonResponse) => {
        if (redirect) {
          navigate("/metrics");
        }
      });
    });
  };

  return (
    <>
      <div style={{ height: "100%", width: "100%" }}>
        <Center mx="auto" h={"100%"}>
          <Container>
            <Card withBorder radius="md" p="xl" className={classes.card}>
              <Image
                maw={150}
                mx="auto"
                radius="md"
                src={logo}
                alt="Bearworks Logo"
              />
              <Text fz="lg" className={classes.title} fw={500}>
                {<>Welcome, {user.firstName}</>}
              </Text>
              <Text fz="xs" c="dimmed" mt={3} mb="xl">
                Complete these steps to begin using Bearworks
              </Text>
              <div
                style={{ width: "100%", height: "100%", position: "relative" }}
              >
                <LoadingOverlay visible={orgFetcher.isLoading} />
                <Group
                  position="apart"
                  className={classes.item}
                  noWrap
                  spacing="xl"
                >
                  <div>
                    <Link to="/integrations" className={classes.link}>
                      <Text ta="left">Link a data source</Text>
                      <Text ta="left" size="xs" color="dimmed">
                        Integrate with popular data sources
                      </Text>
                    </Link>
                  </div>
                  {orgFetcher.isLoading && <Loader></Loader>}
                  {orgWithOnboarding?.set_up_data_source === false && (
                    <Link to="/integrations">
                      <Button>Integrate</Button>
                    </Link>
                  )}
                  {orgWithOnboarding?.set_up_data_source && (
                    <Link to="/integrations" className={classes.link}>
                      <Badge color="green" size="xl" variant="filled">
                        <IconCheck size={15} />
                      </Badge>
                    </Link>
                  )}
                </Group>
                <Group
                  position="apart"
                  className={classes.item}
                  noWrap
                  spacing="xl"
                >
                  <div>
                    {(orgWithOnboarding?.has_set_up_stripe === false ||
                      !orgWithOnboarding?.has_set_up_stripe) && (
                      <Link to="/integrations" className={classes.link}>
                        <Text ta="left">Link stripe to your account</Text>
                        <Text ta="left" size="xs" color="dimmed">
                          Get an out of the box KPI dashboard
                        </Text>
                      </Link>
                    )}
                    {orgWithOnboarding?.has_set_up_stripe && (
                      <Link to="/dash/17" className={classes.link}>
                        <Text ta="left">You linked stripe to your account</Text>
                        <Text ta="left" size="xs" color="dimmed">
                          See your out of the box KPI dashboard
                        </Text>
                      </Link>
                    )}
                  </div>
                  {orgFetcher.isLoading && <Loader></Loader>}
                  {orgWithOnboarding?.has_set_up_stripe === false && (
                    <Link to="/integrations">
                      <Button>Integrate</Button>
                    </Link>
                  )}
                  {orgWithOnboarding?.has_set_up_stripe && (
                    <Link to="/dash/17" className={classes.link}>
                      <Badge color="green" size="xl" variant="filled">
                        <IconCheck size={15} />
                      </Badge>
                    </Link>
                  )}
                </Group>
                <Group
                  position="apart"
                  className={classes.item}
                  noWrap
                  spacing="xl"
                >
                  <div>
                    <Link
                      onClick={() => {
                        exploreMetrics(false);
                      }}
                      to={"/metrics"}
                      className={classes.link}
                    >
                      <Text ta="left">Explore Metrics</Text>
                      <Text ta="left" size="xs" color="dimmed">
                        Understand your business
                      </Text>
                    </Link>
                  </div>
                  {orgFetcher.isLoading && <Loader></Loader>}
                  {orgWithOnboarding?.explored_metrics === false && (
                    <Button
                      onClick={() => {
                        exploreMetrics(true);
                      }}
                    >
                      Explore
                    </Button>
                  )}
                  {orgWithOnboarding?.explored_metrics && (
                    <Link
                      onClick={() => {
                        exploreMetrics(false);
                      }}
                      to={"/metrics"}
                      className={classes.link}
                    >
                      <Badge color="green" size="xl" variant="filled">
                        <IconCheck size={15} />
                      </Badge>
                    </Link>
                  )}
                </Group>
                <Group
                  position="apart"
                  className={classes.item}
                  noWrap
                  spacing="xl"
                >
                  <div>
                    <Link
                      onClick={() => {
                        inviteYourTeammates(false);
                      }}
                      to={"/settings/user-management"}
                      className={classes.link}
                    >
                      <Text ta="left">Invite Your Teammates</Text>
                      <Text ta="left" size="xs" color="dimmed">
                        Collaborate with them
                      </Text>
                    </Link>
                  </div>
                  {orgFetcher.isLoading && <Loader></Loader>}
                  {orgWithOnboarding?.invited_your_team_mates === false && (
                    <Button
                      onClick={() => {
                        inviteYourTeammates(true);
                      }}
                    >
                      Invite
                    </Button>
                  )}
                  {orgWithOnboarding?.invited_your_team_mates && (
                    <Link
                      onClick={() => {
                        inviteYourTeammates(false);
                      }}
                      to={"/settings/user-management"}
                      className={classes.link}
                    >
                      <Badge color="green" size="xl" variant="filled">
                        <IconCheck size={15} />
                      </Badge>
                    </Link>
                  )}
                </Group>
              </div>
            </Card>
            <Space h={"md"}></Space>
            <Card withBorder radius="md" p="xl" className={classes.card}>
              <Group
                position="apart"
                className={classes.item}
                noWrap
                spacing="xl"
              >
                <div>
                  <Text ta="left">Questions?</Text>
                </div>
                <Anchor href="mailto:support@bearworks.com">
                  <Button>Get in touch</Button>
                </Anchor>
              </Group>
            </Card>
          </Container>
        </Center>
      </div>
    </>
  );
}
