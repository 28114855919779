import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import { AiOutlineCloudSync } from "react-icons/ai";

export const processNotification = (id: string, message: string) => {
  notifications.show({
    id: id,
    title: "Processing",
    message: message,
    loading: true,
  });
};

export const successNotification = (id: string, message: string) => {
  notifications.hide(id);
  notifications.cleanQueue();
  notifications.show({
    id,
    autoClose: 2000,
    title: "Success",
    message: message,
    color: "green",
    icon: <IconCheck />,
    loading: false,
  });
};

export const errorNotification = (id: string, message?: string) => {
  notifications.hide(id);
  notifications.cleanQueue();
  notifications.show({
    id,
    autoClose: 2000,
    title: "Error",
    message: message || "Error during the operation",
    color: "red",
    icon: <IconX />,
    loading: false,
  });
};

export const syncNotification = (id: string) => {
  notifications.show({
    id,
    autoClose: 4000,
    title: "Syncing might take some time",
    message: "You will receive an email when it is completed.",
    color: "blue",
    icon: <AiOutlineCloudSync />,
    loading: false,
  });
};
