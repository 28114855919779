import React from "react";
import { Text, Grid, Card, Button, Modal } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { FaSalesforce, FaHubspot } from "react-icons/fa";
import { TbWebhook } from "react-icons/tb";
import { MdEmail } from "react-icons/md";
import { GrStripe } from "react-icons/gr";
import { useTranslation } from "react-i18next";

import { EIntegrationType, IAddIntegration } from "interfaces/integrations";
import { useCanImpersonate } from "hooks/useCanImpersonate";
import { SalesForceForm } from "./salesforce-form";
import { WebhookForm } from "./webhook-form";
import { HubSpotForm } from "./hubspot-form";
import { StripeForm } from "./stripe-form";
import { ActivityForm } from "./activity-form";

interface IAddIntegrationCardProps {
  integration: IAddIntegration;
}

export const AvailableIntegrationCard: React.FC<IAddIntegrationCardProps> = ({
  integration,
}) => {
  const { t } = useTranslation();
  const [opened, { open, close }] = useDisclosure(false);
  const isMobile = useMediaQuery("(max-width: 50em)");
  const canImpersonate = useCanImpersonate();

  return (
    <Card shadow="sm" padding="lg" radius="md" h="100%" withBorder>
      <Grid mb={5} align="center">
        <Grid.Col span="content">
          {integration.type === EIntegrationType.SALESFORCE && (
            <FaSalesforce size={50} />
          )}
          {integration.type === EIntegrationType.HUBSPOT && (
            <FaHubspot size={50} />
          )}
          {integration.type === EIntegrationType.STRIPE && (
            <GrStripe size={50} />
          )}
          {integration.type === EIntegrationType.ACTIVITY && (
            <MdEmail size={50} />
          )}
          {integration.type === EIntegrationType.WEBHOOK && (
            <TbWebhook size={50} />
          )}
        </Grid.Col>
        <Grid.Col span={6}>
          <Text align="left">{integration.name}</Text>
        </Grid.Col>
      </Grid>

      <Text align="left" size="sm" color="dimmed">
        {integration.description}
      </Text>

      <Grid align="left">
        <Grid.Col span="content">
          <Button mt="md" radius="md" onClick={open}>
            {t("Integrate")}
          </Button>
        </Grid.Col>
      </Grid>
      <Modal
        fullScreen={isMobile}
        opened={opened}
        onClose={close}
        withCloseButton={false}
      >
        {integration.type === EIntegrationType.SALESFORCE && (
          <SalesForceForm
            opened={opened}
            onClose={close}
            canImpersonate={canImpersonate}
          />
        )}
        {integration.type === EIntegrationType.HUBSPOT && (
          <HubSpotForm
            opened={opened}
            onClose={close}
            canImpersonate={canImpersonate}
          />
        )}
        {integration.type === EIntegrationType.STRIPE && (
          <StripeForm opened={opened} onClose={close} />
        )}
        {integration.type === EIntegrationType.ACTIVITY && (
          <ActivityForm opened={opened} onClose={close} />
        )}
        {integration.type === EIntegrationType.WEBHOOK && (
          <WebhookForm opened={opened} onClose={close} />
        )}
      </Modal>
    </Card>
  );
};
