import React from "react";
import {
  Button,
  TextInput,
  Grid,
  Title,
  Text,
  LoadingOverlay,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";
import { FaExchangeAlt } from "react-icons/fa";
import { MdMail } from "react-icons/md";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  loadOrgSelectionFromLocalStorage,
  useAuthInfo,
} from "@propelauth/react";
import Nango from "@nangohq/frontend";

import { getEnvironment } from "utils/env";
import bearLogo from "assets/logo.png";
import googleLogo from "assets/google.svg";
import { postFetcher } from "fetchers/fetchers";
import { getFetcher } from "fetchers/fetchers";
import { IError } from "interfaces/errors";
import { useQuery } from "@tanstack/react-query";
import {
  errorNotification,
  processNotification,
  successNotification,
  syncNotification,
} from "utils/notifications";

interface IActivityFormProps {
  initialValues?: any;
  opened?: boolean;
  onClose: () => void;
}

export const ActivityForm: React.FC<IActivityFormProps> = ({
  initialValues,
  onClose,
}) => {
  const { t } = useTranslation();
  const authInfo = useAuthInfo();
  const queryClient = useQueryClient();

  const { data: integrations } = useQuery<any, IError>(
    ["/api/integrations/organization"],
    () => getFetcher("/api/integrations/organization", authInfo?.accessToken)
  );

  const form = useForm({
    initialValues,
  });

  const { mutate: mutateCreate, isLoading } = useMutation(
    (payload: any) =>
      postFetcher("/api/integrations/activity", authInfo?.accessToken, payload),
    {
      onSuccess: () => {
        onClose();
        queryClient.invalidateQueries(["/api/integrations/organization"]);
        successNotification(
          "createActivityIntegration",
          t("Activity capture integration has been saved.")
        );
        if (integrations.length === 0) {
          syncNotification("createActivityIntegration");
        }
      },
      onError: (error: IError) => {
        console.error(error);
        errorNotification("createActivityIntegration");
      },
    }
  );

  return (
    <div>
      <LoadingOverlay visible={isLoading} />
      <Grid>
        <Grid.Col span={12}>
          <Grid justify="center" align="center">
            <Grid.Col span="content">
              <MdMail size={50} />
            </Grid.Col>
            <Grid.Col span="content">
              <FaExchangeAlt size={20} color="#ADB5BD" />
            </Grid.Col>
            <Grid.Col span="content">
              <img src={bearLogo} width={50} className="bear-logo" alt="logo" />
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col span={12}>
          <Title align="center" order={4}>
            {t("Connect Activity Capture with Bearworks")}
          </Title>
        </Grid.Col>
        <Grid.Col span={12}>
          <form
            onSubmit={form.onSubmit(async (values: any) => {
              const orgId = loadOrgSelectionFromLocalStorage();
              const encoded = encodeURIComponent(
                window.btoa(
                  unescape(
                    encodeURIComponent(
                      crypto.randomUUID() + orgId + getEnvironment()
                    )
                  )
                )
              );
              const nango = new Nango({
                publicKey: "f74c2661-1bfe-463b-99c2-ea9907062376",
              });
              const result = await nango.auth(
                "Google-Bearweb-Activity",
                encoded
              );
              console.log(result);
              processNotification(
                "createActivityIntegration",
                t("Creating activity capture integration...")
              );
              mutateCreate({
                email_blacklist: (values.email_blacklist || "")
                  .split(",")
                  .map((email: string) => email.trim()),
                encoded_id: encoded,
              });
            })}
          >
            <Grid>
              <Grid.Col span={12}>
                <TextInput
                  label={t("Emails To Ignore")}
                  placeholder={t(
                    "Your email blacklist (emails separated by a comma like 'greg@bearworks.com,no-reply@bearworks.com')"
                  )}
                  {...form.getInputProps("email_blacklist")}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <Grid align="center" justify="space-between">
                  {/* <Grid.Col span="auto">
                    <Button variant="default" mt={15}>
                      {t("Need help?")}
                    </Button>
                  </Grid.Col> */}
                  <Grid.Col span="content">
                    <Button variant="default" onClick={onClose}>
                      {t("Close")}
                    </Button>
                  </Grid.Col>
                  <Grid.Col span="content">
                    <Button
                      variant="default"
                      leftIcon={<img src={googleLogo} width={13} alt="G" />}
                      type="submit"
                    >
                      {t("Sign In with Google")}
                    </Button>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={12}>
                <Text size="xs">
                  {t(
                    "Bearworks use and transfer of information received from Google APIs to any other app will adhere to Google API Services User Data Policy, including the Limited Use requirements."
                  )}
                </Text>
              </Grid.Col>
            </Grid>
          </form>
        </Grid.Col>
      </Grid>
    </div>
  );
};
