import axios from "axios";
import React, { useState, useEffect } from "react";
import { Button, FileInput, Select, Checkbox } from "@mantine/core";
import { useAuthInfo, useActiveOrg } from "@propelauth/react";
import { notifications } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { createStyles } from "@mantine/core";

import { AgGridReact } from "ag-grid-react";

import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { logPageView } from "../utils/segment";
import { getCurrentOrg } from "../utils/org";
import { IError } from "interfaces/errors";
import { getFetcher } from "fetchers/fetchers";

export default function FileForm() {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [disabled, setDisabled] = useState(true);
  const [rowData, setRowData] = useState<any>();
  const [columnDefs, setColumnDefs] = useState<any>();
  const [checked, setChecked] = useState(false);
  const [uploaded, setUploaded] = useState(0);
  const authInfo = useAuthInfo();
  const { t } = useTranslation();
  let accessToken: string | null = null;
  const selectedOrg = useActiveOrg();
  if (!authInfo.loading) {
    accessToken = authInfo.accessToken;
  }
  const [orgID, setOrgID] = useState<string>("");
  useEffect(() => {
    if (orgID && orgID.length > 0) {
      return;
    }
    if (!authInfo.loading) {
      getCurrentOrg(selectedOrg, authInfo, accessToken).then((value) => {
        if (value) {
          let localOrgID = value.orgId;
          if (!localOrgID) {
            setOrgID("");
          } else {
            setOrgID(localOrgID);
          }
        }
      });
    }
  }, [authInfo, accessToken, orgID, selectedOrg]);

  const { data: teamMappingFeature } = useQuery<any, IError>(
    ["/api/features/team_mapping"],
    () => getFetcher("/api/features/team_mapping", authInfo?.accessToken)
  );

  useEffect(() => {
    if (!authInfo.loading) {
      logPageView(
        authInfo.user?.email,
        authInfo.user?.firstName + " " + authInfo.user?.lastName
      );
    }
    // eslint-disable-next-line
  }, [authInfo.loading]);

  const useStyles = createStyles((theme) => ({
    root: {
      position: "relative",
    },

    input: {
      height: "auto",
      paddingTop: 18,
    },

    label: {
      position: "absolute",
      pointerEvents: "none",
      fontSize: theme.fontSizes.xs,
      paddingLeft: theme.spacing.sm,
      paddingTop: `calc(${theme.spacing.sm} / 2)`,
      zIndex: 1,
    },
  }));

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const formData = new FormData();
    if (!selectedFile) {
      return;
    }
    formData.append("csvFile", selectedFile, selectedFile.name);
    let url = "";
    if (window.location.href.includes("http://localhost")) {
      url = "http://localhost:8080/api/quota";
    } else {
      url = "/api/quota";
    }

    axios
      .post(url, formData, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Org-ID": orgID,
        },
      })
      .then((value) => {
        setUploaded(uploaded + 1);
        notifications.show({
          id: "quotafile" + uploaded,

          autoClose: 60000,
          title: "Success!",
          message: "Your Quota File has been successfully uploaded!",
          color: "green",
          icon: <IconCheck />,
          loading: false,
        });
      })
      .catch((reason) => {});
  };

  useEffect(() => {
    if (!orgID || orgID.length === 0) {
      return;
    }
    if (authInfo.loading) {
      return;
    }
    let analyticsUrl = "";
    if (window.location.href.includes("http://localhost")) {
      analyticsUrl = "http://localhost:8080/api/quota/list_csv";
    } else {
      analyticsUrl = "/api/quota/list_csv";
    }
    fetch(analyticsUrl, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Org-ID": orgID,
      },
    }).then((data) => {
      const jsonPromise = data.json();
      Promise.resolve(jsonPromise).then((jsonResponse) => {
        if (jsonResponse) {
          setRowData(jsonResponse.rowData);
          setColumnDefs(jsonResponse.columnDefs);
        }
      });
    });
  }, [authInfo.loading, uploaded, accessToken, orgID]);

  const { classes } = useStyles();
  console.log(classes);
  var companyNameData = ["department", "companyname"];

  if (checked) {
    companyNameData = ["foo", "bar"];
    // TODO: get all table columns
  }

  return (
    <div className="login-wrapper">
      <h2>Upload a Quota Plan </h2>
      <form onSubmit={handleSubmit}>
        <FileInput
          placeholder="Pick your csv file"
          label="Your CSV File"
          onChange={(file) => {
            setSelectedFile(file);
            setDisabled(false);
          }}
        ></FileInput>
        <div>
          <Button type="submit" disabled={disabled}>
            Submit
          </Button>
        </div>
      </form>

      <div className="ag-theme-balham" style={{ height: 400 }}>
        <AgGridReact
          defaultColDef={{
            resizable: true,
            sortable: true,
          }}
          enableRangeSelection={true}
          rowSelection="multiple"
          className="ag-theme-balham"
          rowData={rowData}
          columnDefs={columnDefs}
          statusBar={{
            statusPanels: [
              {
                statusPanel: "agAggregationComponent",
                align: "left",
                statusPanelParams: {
                  aggFuncs: ["count", "sum", "min", "max", "avg"],
                },
              },
            ],
          }}
        />
      </div>
      {/* TODO: add in feature flags so we can
      reenable this for testing for some customers or for ourselves */}
      {teamMappingFeature && (
        <div style={{ width: 400 }}>
          <h3>Customize Team Mapping Settings</h3>
          <Checkbox
            label={t("Show All Columns")}
            checked={checked}
            onChange={(event) => {
              setChecked(event.currentTarget.checked);
            }}
          />
          <Select
            style={{ marginTop: 20, zIndex: 2 }}
            data={companyNameData}
            defaultValue="companyname"
            label={t("Team field")}
            classNames={classes}
          />
        </div>
      )}
    </div>
  );
}
