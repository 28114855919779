import React, { useState, useEffect } from "react";
import { Grid, Input, Button, createStyles, Select } from "@mantine/core";
import { measureType } from "./types";
import { FiTrash } from "react-icons/fi";

type removeMeasureFunction = () => void;
type addMeasureFunction = (measure: measureType) => void;

interface customMeasureProps {
  name: string;
  type?: string;
  sql?: string;
  measure: measureType;
  removeMeasure: removeMeasureFunction;
  addMeasure: addMeasureFunction;
}

const MEASURE_TYPES = [
  "string",
  "time",
  "boolean",
  "number",
  "count",
  "countDistinct",
  "countDistinctApprox",
  "sum",
  "avg",
  "min",
  "max",
  "runningTotal",
];

const useStyles = createStyles((theme) => ({
  deleteButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.colors.red[6],
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    fontSize: theme.fontSizes.sm,
  },
  trashIcon: {
    fontSize: theme.fontSizes.lg,
  },
  deleteButtonCol: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    marginBottom: "6px",
  },
}));

export function CustomMeasure(props: customMeasureProps) {
  const [measureType, setMeasureType] = useState<string>(
    props?.measure?.type || ""
  );
  const [sql, setSQL] = useState<string>(props?.measure?.sql || "");
  const [name, setName] = useState<string>(props?.measure?.name || "");

  const { classes } = useStyles();

  useEffect(() => {
    if (
      measureType !== props?.measure?.type ||
      name !== props?.measure?.name ||
      sql !== props?.measure?.sql
    ) {
      props.addMeasure({
        name: name,
        type: measureType,
        sql: sql,
      });
    }
  }, [measureType, sql, name, props]);

  useEffect(() => {
    if (measureType !== "" && measureType && sql !== "" && name !== "") {
      if (
        measureType !== props?.type ||
        name !== props?.name ||
        sql !== props?.sql
      ) {
        setMeasureType(props.type || "");
        setSQL(props.sql || "");
        setName(props.name || "");
      }
    }
    // eslint-disable-next-line
  }, [props]);

  return (
    <>
      <Grid
        grow
        gutter={"xs"}
        style={{
          textAlign: "left",
          fontWeight: 500,
        }}
        align="flex-end"
      >
        <Grid.Col span={4}>
          <Input.Wrapper
            id="dimname"
            label="Measure Name"
            error=""
            withAsterisk
          >
            <Input
              value={name}
              placeholder="What is the name of the measure?"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setName(e.target.value);
              }}
              required
            />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={4}>
          <Input.Wrapper id="modelname" label="SQL" error="" withAsterisk>
            <Input
              value={sql}
              placeholder="What is your sql?"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSQL(e.target.value);
              }}
              required
            />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={3}>
          <Input.Wrapper id="modeltype" label="Type" error="" withAsterisk>
            <Select
              placeholder="Pick one"
              data={MEASURE_TYPES}
              value={measureType}
              onChange={(value: string) => {
                setMeasureType(value);
              }}
              limit={50}
              required
              searchable
              hoverOnSearchChange
            />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={1} className={classes.deleteButtonCol}>
          <Button
            className={classes.deleteButton}
            onClick={() => {
              props.removeMeasure();
            }}
            variant="subtle"
            color="red"
            compact
          >
            <FiTrash className={classes.trashIcon} />
            Delete
          </Button>
        </Grid.Col>
      </Grid>
    </>
  );
}
