import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "@propelauth/react";
import { Notifications } from "@mantine/notifications";

import { MantineProvider } from "@mantine/core";
import runSegmentCollection from "./utils/LoadSegment";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

if (
  process.env.BEARWEB_ENV === "staging" ||
  process.env.BEARWEB_ENV === "production"
) {
  Sentry.init({
    dsn: "https://febe00627c194f0bb8767de81b14ffb1@o4504459159273472.ingest.sentry.io/4504459632574464",
    integrations: [new BrowserTracing()],
    environment: process.env.BEARWEB_ENV || "development",

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const authURL =
  process.env.REACT_APP_AUTH_URL || "https://8681367392.propelauthtest.com";

declare global {
  interface Window {
    analytics: any;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      enabled: true,
      refetchOnReconnect: true,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      retry: 1,
    },
    mutations: {
      retry: 1,
    },
  },
});

root.render(
  // <React.StrictMode>
  <AuthProvider authUrl={authURL}>
    <QueryClientProvider client={queryClient}>
      <MantineProvider>
        <Notifications position="top-right" />
        <App />
      </MantineProvider>
    </QueryClientProvider>
  </AuthProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

runSegmentCollection();
