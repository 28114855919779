import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tabs, createStyles, Text, Group, Center, Button } from "@mantine/core";
import { IconAt } from "@tabler/icons-react";
import {
  useAuthInfo,
  useRedirectFunctions,
  useActiveOrg,
} from "@propelauth/react";
import { logPageView } from "../utils/segment";

const useStyles = createStyles((theme) => ({
  icon: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[3]
        : theme.colors.gray[5],
  },

  name: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}));

interface SettingsProps {}

export default function Settings(props: SettingsProps) {
  const navigate = useNavigate();
  const { routeSlug } = useParams();
  const authInfo = useAuthInfo();
  const { redirectToAccountPage, redirectToOrgPage } = useRedirectFunctions();
  const org = useActiveOrg();

  useEffect(() => {
    if (!authInfo.loading) {
      logPageView(
        authInfo.user?.email,
        authInfo.user?.firstName + " " + authInfo.user?.lastName
      );
    }
    // eslint-disable-next-line
  }, [authInfo.loading]);

  const { classes } = useStyles();
  let tabValue = "settings";
  if (routeSlug === "user-management") {
    tabValue = "user-management";
  }

  if (authInfo.loading) {
    return <></>;
  } else if (!authInfo.isLoggedIn) {
    return <></>;
  }

  const user = authInfo.user;
  const userRole = org?.userAssignedRole;
  function disableAccountManagementButton() {
    return !(userRole === "Admin" || userRole === "Owner");
  }

  function setActiveTab(value: string) {
    if (value === "settings") {
      navigate("/settings");
    } else {
      navigate("/settings/user-management");
    }
  }

  return (
    <Tabs
      value={tabValue}
      onTabChange={setActiveTab}
      style={{ width: "100%", height: "100%" }}
    >
      <Tabs.List>
        <Tabs.Tab value="settings">Settings</Tabs.Tab>
        <Tabs.Tab value="user-management">User Management</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="settings" style={{ width: "100%", height: "100%" }}>
        <Center style={{ width: "100%", height: "80%" }}>
          <div>
            <Group noWrap>
              <div>
                <Group noWrap spacing={10} mt={3}>
                  <Text size="lg" weight={500} className={classes.name}>
                    {user.firstName} {user.lastName}
                  </Text>
                </Group>

                <Group noWrap spacing={10} mt={3}>
                  Email:{" "}
                  <IconAt stroke={1.5} size={16} className={classes.icon} />
                  <Text size="xs" color="dimmed">
                    {user.email}
                  </Text>
                </Group>

                <Button
                  onClick={() => {
                    redirectToAccountPage();
                  }}
                >
                  Edit Your Account
                </Button>
              </div>
            </Group>
          </div>
        </Center>
      </Tabs.Panel>
      <Tabs.Panel
        value="user-management"
        style={{ width: "100%", height: "100%" }}
      >
        <Center style={{ width: "100%", height: "80%" }}>
          <Button
            disabled={disableAccountManagementButton()}
            onClick={() => {
              redirectToOrgPage();
            }}
          >
            Manage Users
          </Button>
        </Center>
      </Tabs.Panel>
    </Tabs>
  );
}
