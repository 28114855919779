import React, { useEffect, useState } from "react";

import { TableSheet } from "components/table-sheet";

interface IDataTableProps {
  rowData: any[];
  isLoading?: boolean;
}

export const DataTable: React.FC<IDataTableProps> = ({
  rowData,
  isLoading = false,
}) => {
  const [tableLimit, setTableLimit] = useState<number>(10);
  const [tableOffset, setTableOffset] = useState<number>(0);
  const [tableTotal, setTableTotal] = useState<number>(0);

  useEffect(() => {
    if (rowData) {
      setTableTotal(rowData.length);
    }
  }, [rowData]);

  return (
    <TableSheet
      isLoading={isLoading}
      queryRowData={rowData.slice(tableOffset, tableOffset + tableLimit)}
      readOnlyMode={true}
      pagination={{
        limit: tableLimit,
        offset: tableOffset,
        total: tableTotal,
        onChange: (offset, limit) => {
          setTableOffset(offset);
          setTableLimit(limit);
        },
      }}
    />
  );
};
