import React, { useEffect } from "react";

import { useAuthInfo } from "@propelauth/react";
import { useNavigate } from "react-router-dom";

interface signupCheckProps {
  children: JSX.Element | React.ReactFragment | React.ReactNode;
}

export default function SignupCheck(props: signupCheckProps) {
  const authInfo = useAuthInfo();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authInfo.loading && authInfo.isLoggedIn) {
      const orgs = authInfo.orgHelper.getOrgs();
      if (orgs.length === 0) {
        if (window.location.pathname !== "/signup/org") {
          navigate("/signup/org");
        }
      }
    }
  }, [authInfo, navigate]);

  return <>{props.children}</>;
}
