import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Button, Grid, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mantine/hooks";

import {
  IMetaData,
  IMetricFilter,
  IMetricQuery,
  IMetricType,
} from "interfaces/metrics";
import { MetricsFilters } from "components/metrics-filters";
import {
  IMetricQueryAdvanced,
  MetricsFiltersAdvanced,
} from "components/metrics-filters-advanced";
import {
  IMetricQueryTimeDimension,
  MetricsFiltersTimeDimensionAdvanced,
} from "components/metrics-filters-time-dimension";
import { useSearchParams } from "react-router-dom";
import { MetricsQueryWidget } from "components/metrics-query-widget";

interface IMetricsExplorerQueryFormProps {
  metaData?: IMetaData;
  runBtnText?: string;
  value?: IMetricQuery;
  onChange: (value: IMetricQuery) => void;
}

const parseFilters = (uriFilters: string) => {
  try {
    return JSON.parse(decodeURIComponent(uriFilters));
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const getStartDate = (startDate: string | null) => {
  return startDate
    ? new Date(startDate)
    : new Date(new Date().setFullYear(new Date().getFullYear() - 1));
};

export const getEndDate = (endDate: string | null) => {
  return endDate ? new Date(endDate) : new Date();
};

export const getGroup = (name: string) => {
  if (name.toLowerCase().includes("hubspot")) {
    return "Hubspot";
  }
  if (name.toLowerCase().includes("salesforce")) {
    return "Salesforce";
  }
  if (name.toLowerCase().includes("stripe")) {
    return "Stripe";
  }
  if (
    name.toLowerCase().includes("calendar") ||
    name.toLowerCase().includes("email") ||
    name.toLowerCase().includes("contact")
  ) {
    return "Activity";
  }
  return "Other";
};

export const MetricsExplorerQueryForm: React.FC<
  IMetricsExplorerQueryFormProps
> = ({ metaData, runBtnText, value, onChange }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 50em)");
  const [search, setSearch] = useSearchParams();

  const [timeDimensionSuggestions, setTimeDimensionSuggestions] = useState<
    any[]
  >([]);

  const [measures, setMeasures] = useState<string[]>(
    value?.measures ||
      (search.get("measures") || "").split(",").filter((v) => v !== "")
  );
  const [dimensions, setDimensions] = useState<string[]>(
    value?.dimensions ||
      (search.get("dimensions") || "").split(",").filter((v) => v !== "")
  );
  const [filters, setFilters] = useState<IMetricFilter[]>(
    value?.filters || parseFilters(search.get("filters") || "[]")
  );

  // time dimension
  const [timeDimension, setTimeDimension] = useState<string | undefined>(
    value?.timeDimension || search.get("time") || undefined
  );
  const [granularity, setGranularity] = useState<string | undefined>(
    value?.granularity || search.get("granularity") || "day"
  );
  const [startDate, setStartDate] = useState<Date | undefined>(
    value?.startDate || getStartDate(search.get("start_date"))
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    value?.endDate || getEndDate(search.get("end_date"))
  );

  // advanced
  const [fill, setFill] = useState<string>(value?.fill || "default");
  const [transform, setTransform] = useState<string | undefined>(
    value?.transform
  );

  useEffect(() => {
    const fromTemplate = !!search.get("template_id");
    if (!fromTemplate) return;

    const newMeasures = (search.get("measures") || "")
      .split(",")
      .filter((v) => v !== "");
    const newDimensions = (search.get("dimensions") || "")
      .split(",")
      .filter((v) => v !== "");
    const newFilters = parseFilters(search.get("filters") || "[]");
    const newTimeDimension = search.get("time") || undefined;

    setMeasures(newMeasures);
    setDimensions(newDimensions);
    setFilters(newFilters);
    setTimeDimension(newTimeDimension);

    onChange({
      measures: newMeasures ? newMeasures : measures,
      dimensions: newDimensions ? newDimensions : dimensions,
      filters: newFilters ? newFilters : filters,
      // time dimension
      timeDimension: newTimeDimension ? newTimeDimension : timeDimension,
      granularity,
      startDate,
      endDate,
      // advanced
      fill,
      transform,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    const tables: string[] = _.uniq(
      measures.concat(dimensions).map((value) => value.split(".")[0])
    );
    const allTimeDimensions: Record<string, IMetricType[]> =
      metaData?.all_time_dimensions || {};
    const timeDimensions: IMetricType[] = [];
    for (const table of tables) {
      timeDimensions.push(...(allTimeDimensions[table] || []));
    }
    setTimeDimensionSuggestions(_.uniq(timeDimensions));
  }, [measures, dimensions, metaData?.all_time_dimensions]);

  return (
    <Grid mt={10} mb={10} align="start">
      <Grid.Col md={12} lg={4}>
        <Title align="left" order={4} mb={10}>
          {t("Measures")}
        </Title>
        <Text color="gray" align="left" size="xs" mb={10}>
          {t("Select measures")}
        </Text>
        <MetricsQueryWidget
          placeholder={t("Pick multi")}
          disabled={!metaData}
          options={(metaData?.measures || []).map((value) => ({
            value: value.value,
            label: value.title,
            group: getGroup(value.title),
          }))}
          value={measures}
          onChange={(values: string[]) => {
            setMeasures(values);
            search.set("measures", values.join(","));
            search.delete("template_id");
            search.delete("template_name");
            setSearch(search);
          }}
        />
      </Grid.Col>
      <Grid.Col md={12} lg={4}>
        <Title align="left" order={4} mb={10}>
          {t("Dimensions")}
        </Title>
        <Text color="gray" align="left" size="xs" mb={10}>
          {t("Select dimensions")}
        </Text>
        <MetricsQueryWidget
          placeholder={t("Pick multi")}
          disabled={!metaData}
          options={(metaData?.dimensions || []).map((value) => ({
            value: value.value,
            label: value.title,
            group: getGroup(value.title),
          }))}
          value={dimensions}
          onChange={(values: string[]) => {
            setDimensions(values);
            search.set("dimensions", values.join(","));
            search.delete("template_id");
            search.delete("template_name");
            setSearch(search);
          }}
        />
      </Grid.Col>
      <Grid.Col md={12} lg={4}>
        <Grid mt={isMobile ? 0 : 57}>
          <Grid.Col span="content">
            <MetricsFiltersTimeDimensionAdvanced
              timeDimensionSuggestions={timeDimensionSuggestions}
              initialValues={{
                timeDimension:
                  search.get("time") || timeDimensionSuggestions[0]?.value,
                granularity: search.get("granularity") || "day",
                startDate: getStartDate(search.get("start_date")),
                endDate: getEndDate(search.get("end_date")),
              }}
              onChange={(value: IMetricQueryTimeDimension) => {
                setTimeDimension(value.timeDimension);
                setGranularity(value.granularity);
                setStartDate(value.startDate);
                setEndDate(value.endDate);
                search.set("time", value.timeDimension || "");
                search.set("granularity", value.granularity || "day");
                search.set(
                  "start_date",
                  value.startDate ? value.startDate.toISOString() : ""
                );
                search.set(
                  "end_date",
                  value.endDate ? value.endDate.toISOString() : ""
                );
                search.delete("template_id");
                search.delete("template_name");
                setSearch(search);
              }}
            />
          </Grid.Col>
          <Grid.Col span="content">
            <MetricsFiltersAdvanced
              initialValues={{
                fill: "default",
              }}
              onChange={(value: IMetricQueryAdvanced) => {
                setFill(value.fill || "default");
                setTransform(value.transform);
              }}
            />
          </Grid.Col>
          <Grid.Col span="content">
            <MetricsFilters
              memberOptions={metaData?.dimensions || []}
              value={filters}
              onChange={(values) => {
                setFilters(values);
                search.set(
                  "filters",
                  encodeURIComponent(JSON.stringify(values))
                );
                search.delete("template_id");
                search.delete("template_name");
                setSearch(search);
              }}
            />
          </Grid.Col>
          <Grid.Col span="content">
            <Button
              disabled={!measures.length && !dimensions.length}
              onClick={() => {
                onChange({
                  measures,
                  dimensions,
                  filters,
                  // time dimension
                  timeDimension:
                    timeDimension || timeDimensionSuggestions[0]?.value,
                  granularity,
                  startDate,
                  endDate,
                  // advanced
                  fill,
                  transform,
                });
              }}
            >
              {runBtnText ? runBtnText : t("Run Query")}
            </Button>
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  );
};
