import React, { useState, useEffect } from "react";
import {
  Text,
  Table,
  Grid,
  LoadingOverlay,
  Group,
  Button,
  Modal,
  Card,
  Title,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { useAuthInfo } from "@propelauth/react";
import { FaPlus } from "react-icons/fa";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { getFetcher, postFetcher } from "fetchers/fetchers";
import { logPageView } from "utils/segment";
import { IError } from "interfaces/errors";
import { PageTitle } from "components/page-title";
import { IDashboard } from "interfaces/dashboards";
import { DashboardItem } from "components/dashboard-item";
import {
  errorNotification,
  processNotification,
  successNotification,
} from "utils/notifications";
import { LoadingSpinner } from "components/loading-spinner";

export const DashboardList = () => {
  const { t } = useTranslation();
  const authInfo = useAuthInfo();
  const isMobile = useMediaQuery("(max-width: 50em)");
  const form = useForm();
  const queryClient = useQueryClient();

  const [sortByEmbeddedDashboards, setSortByEmbeddedDashboards] =
    useState<string>("name");
  const [sortByDashboards, setSortByDashboards] = useState<string>("name");
  const [isCreateOpened, { open: isCreateOpen, close: isCreateClose }] =
    useDisclosure(false);

  useEffect(() => {
    if (!authInfo.loading) {
      logPageView(
        authInfo.user?.email,
        authInfo.user?.firstName + " " + authInfo.user?.lastName
      );
    }
    // eslint-disable-next-line
  }, [authInfo.loading]);

  const {
    data: dataEmbeddedDashboards,
    isLoading: isLoadingEmbeddedDashboards,
  } = useQuery<any, IError>(
    ["/api/embedded-dashboards", sortByEmbeddedDashboards],
    () =>
      getFetcher(
        `/api/embedded-dashboards?sort_by=${sortByEmbeddedDashboards}`,
        authInfo?.accessToken
      )
  );

  const { data: dataDashboards, isLoading: isLoadingDashboards } = useQuery<
    any,
    IError
  >(["/api/dashboards", sortByDashboards], () =>
    getFetcher(
      `/api/dashboards?sort_by=${sortByDashboards}`,
      authInfo?.accessToken
    )
  );

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(
    (payload: any) =>
      postFetcher("/api/dashboards", authInfo?.accessToken, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["/api/dashboards"]);
        successNotification(
          "createDashboard",
          t("New dashboard has been created.")
        );
        isCreateClose();
        form.reset();
      },
      onError: () => {
        errorNotification("createDashboard");
      },
    }
  );

  if (isLoadingDashboards) {
    return <LoadingSpinner />;
  }

  return (
    <Grid p={24}>
      <Grid.Col>
        <PageTitle
          title={t("Dashboards")}
          subTitle={t("Create measurable dashboards to track your data")}
          actions={[
            <Button
              variant="outline"
              onClick={() => {
                isCreateOpen();
              }}
              leftIcon={<FaPlus />}
            >
              {t("Create Dashboard")}
            </Button>,
          ]}
        />
        {(dataEmbeddedDashboards || []).length > 0 && (
          <Grid mt={10}>
            <Grid.Col span={12}>
              <Card radius="md">
                <LoadingOverlay visible={isLoadingEmbeddedDashboards} />
                <Card.Section>
                  <Title order={4} align="left" pl={20}>
                    {t("Embedded Dashboards")}
                  </Title>
                  {(dataEmbeddedDashboards || []).length > 0 ? (
                    <Table m={10}>
                      <thead>
                        <tr>
                          <th>
                            <Group>
                              <Text>{t("Dashboard")}</Text>
                              {sortByEmbeddedDashboards === "name" && (
                                <AiOutlineArrowDown
                                  cursor={"pointer"}
                                  onClick={() => {
                                    setSortByEmbeddedDashboards("-name");
                                  }}
                                />
                              )}
                              {sortByEmbeddedDashboards === "-name" && (
                                <AiOutlineArrowUp
                                  cursor={"pointer"}
                                  onClick={() => {
                                    setSortByEmbeddedDashboards("name");
                                  }}
                                />
                              )}
                            </Group>
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataEmbeddedDashboards.map((value: IDashboard) => (
                          <DashboardItem
                            key={value.id}
                            dashboard={value}
                            isEmbedded
                          />
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <Text align="left" color="dimmed" size="md" p={20}>
                      {t("No embedded dashboards")}
                    </Text>
                  )}
                </Card.Section>
              </Card>
            </Grid.Col>
          </Grid>
        )}
        <Grid mt={10}>
          <Grid.Col span={12}>
            <Card radius="md">
              <LoadingOverlay visible={isLoadingDashboards} />
              <Card.Section>
                <Title order={4} align="left" pl={20}>
                  {t("Your Dashboards")}
                </Title>
                {(dataDashboards || []).length > 0 ? (
                  <Table m={10}>
                    <thead>
                      <tr>
                        <th>
                          <Group>
                            <Text>{t("Dashboard")}</Text>
                            {sortByDashboards === "name" && (
                              <AiOutlineArrowDown
                                cursor={"pointer"}
                                onClick={() => {
                                  setSortByDashboards("-name");
                                }}
                              />
                            )}
                            {sortByDashboards === "-name" && (
                              <AiOutlineArrowUp
                                cursor={"pointer"}
                                onClick={() => {
                                  setSortByDashboards("name");
                                }}
                              />
                            )}
                          </Group>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataDashboards.map((value: IDashboard) => (
                        <DashboardItem key={value.id} dashboard={value} />
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <Text align="center" color="white" size="md" p={20}>
                    <Button
                      onClick={() => {
                        isCreateOpen();
                      }}
                    >
                      {t("Create Dashboard")}
                    </Button>
                  </Text>
                )}
              </Card.Section>
            </Card>
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Modal
        fullScreen={isMobile}
        opened={isCreateOpened}
        onClose={() => {
          isCreateClose();
        }}
        title={<Text size="lg">{t("Create Dashboard")}</Text>}
        size={320}
      >
        <LoadingOverlay visible={isLoadingCreate} />
        <Grid>
          <form
            onSubmit={form.onSubmit((values: any) => {
              processNotification(
                "createDashboard",
                t("Creating new dashboard...")
              );
              mutateCreate({
                name: values.name,
                dashboard: [],
              });
            })}
          >
            <Grid.Col span={12}>
              <TextInput
                withAsterisk
                label={t("Dashboard Name")}
                placeholder={t("What is the name of the new dashboard?")}
                {...form.getInputProps("name")}
              />
            </Grid.Col>
            <Grid.Col span={12} mt={10}>
              <Grid justify="end">
                <Grid.Col span="content">
                  <Button
                    variant="outline"
                    onClick={() => {
                      isCreateClose();
                    }}
                  >
                    {t("Close")}
                  </Button>
                </Grid.Col>
                <Grid.Col span="content">
                  <Button type="submit">{t("Create New Dashboard")}</Button>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </form>
        </Grid>
      </Modal>
    </Grid>
  );
};
