import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Divider,
  Grid,
  Input,
  LoadingOverlay,
  Modal,
  Paper,
  SegmentedControl,
  Text,
  Title,
  createStyles,
  NumberInput,
  Select,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAuthInfo } from "@propelauth/react";

import { logPageView } from "utils/segment";

import { PageTitle } from "components/page-title";
import { deleteFetcher, getFetcher, postFetcher } from "fetchers/fetchers";
import { LoadingSpinner } from "components/loading-spinner";
import { MetricsExplorerQueryForm } from "components/metrics-explorer-query-form";
import { IMetaData } from "interfaces/metrics";
import { IError } from "interfaces/errors";
import { useDisclosure } from "@mantine/hooks";
import { processNotification, successNotification } from "utils/notifications";
import { useForm } from "@mantine/form";
import CountChart from "charts/CountChart";
import { IIntegration } from "interfaces/integrations";
import { EIntegrationType } from "interfaces/integrations";

const useStyles = createStyles((theme) => ({
  inputField: {
    marginBottom: theme.spacing.md,
    width: "33%",
  },
  submitButton: {
    textAlign: "right",
  },
  paper: {
    marginTop: theme.spacing.lg,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  marginBottomConditionally: {
    marginBottom: theme.spacing.md,
  },
}));

export const Alert: React.FC = () => {
  const { t } = useTranslation();
  const authInfo = useAuthInfo();
  const { alertID } = useParams();
  const navigate = useNavigate();
  const [isDeleteOpened, { open: isDeleteOpen, close: isDeleteClose }] =
    useDisclosure(false);

  const form = useForm<any>({
    initialValues: {
      deactivated: "false",
      operator: "equals",
      frequency: 30,
    },
  });

  const isNewAlert = alertID === "new";
  const { classes } = useStyles();

  useEffect(() => {
    if (!authInfo.loading) {
      logPageView(
        authInfo.user?.email,
        authInfo.user?.firstName + " " + authInfo.user?.lastName
      );
    }
    // eslint-disable-next-line
  }, [authInfo.loading]);

  const { data: metaData, isLoading } = useQuery<IMetaData, IError>(
    ["/api/query/get_metadata"],
    () => postFetcher("/api/query/get_metadata", authInfo?.accessToken, {})
  );

  const { data: integrations, isLoading: isLoadingIntegrations } = useQuery<
    any,
    IError
  >(["/api/integrations/organization"], () =>
    getFetcher("/api/integrations/organization", authInfo?.accessToken)
  );

  const webhookAutocompleteData: any = (integrations || [])
    .filter(
      (integration: IIntegration) =>
        integration.integration_type === EIntegrationType.WEBHOOK
    )
    .map((integration: IIntegration) => ({
      label: integration.integration_name,
      value: integration.integration_id,
      id: integration.integration_id,
    }));

  const { mutate: mutateGet, isLoading: isLoadingGet } = useMutation(
    (alertId: string) =>
      getFetcher(`/api/alerts/alerts/${alertId}`, authInfo?.accessToken),
    {
      onSuccess: (response: any) => {
        form.setValues({
          ...response,
          deactivated: response.deactivated.toString(),
          alert_on_state_change_only:
            response.alert_on_state_change_only.toString(),
          webhook: response.webhook?.id,
        });
      },
    }
  );

  const { mutate: mutateSave, isLoading: isLoadingSave } = useMutation(
    (payload: any) =>
      postFetcher("/api/alerts/alerts", authInfo?.accessToken, payload),
    {
      onSuccess: (response) => {
        successNotification("Alert", t("The alert has been saved."));
        navigate(`/alerts/${response.id}`);
      },
    }
  );

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    () => deleteFetcher(`/api/alerts/alerts/${alertID}`, authInfo?.accessToken),
    {
      onSuccess: (response) => {
        successNotification("Alert", t("The alert has been deleted."));
        navigate("/alerts/");
      },
    }
  );

  useEffect(() => {
    if (alertID && alertID !== "new") {
      mutateGet(alertID);
    }
    // eslint-disable-next-line
  }, [mutateGet, alertID]);

  if (isLoadingGet || isLoading || isLoadingIntegrations) {
    return <LoadingSpinner />;
  }

  const disableSubmit = () => {
    const formValues = form.values as any;
    if (
      formValues?.name &&
      formValues?.operator &&
      formValues?.cube_query &&
      formValues?.comparison
    ) {
      return false;
    }
    return true;
  };

  return (
    <Grid p={24}>
      <Grid.Col>
        <PageTitle title={isNewAlert ? t("New Alert") : t("Alert")} />
        <Grid>
          <Grid.Col span={12}>
            <form
              onSubmit={form.onSubmit((values: any) => {
                mutateSave({
                  ...values,
                  deactivated: values.deactivated === "true",
                  alert_on_state_change_only:
                    values.alert_on_state_change_only === "true",
                });
              })}
            >
              <Paper
                className={classes.paper}
                p="md"
                radius="sm"
                style={{ marginBottom: "20px", textAlign: "left" }}
              >
                <Title align="left" order={4} mb={10}>
                  {t("Name")}
                </Title>
                <Text color="gray" align="left" size="xs" mb={10}>
                  {t("What is your alert name?")}
                </Text>
                <Input
                  placeholder={t("My New Alert")}
                  {...form.getInputProps("name")}
                />
              </Paper>

              <Paper
                className={classes.paper}
                p="md"
                radius="sm"
                style={{ marginBottom: "20px", textAlign: "left" }}
              >
                <MetricsExplorerQueryForm
                  metaData={metaData}
                  runBtnText={t("Apply Query")}
                  {...form.getInputProps("cube_query")}
                />
              </Paper>

              <Paper
                className={classes.paper}
                p="md"
                radius="sm"
                style={{ marginBottom: "20px", textAlign: "left" }}
              >
                {/* TODO: redesign me */}
                <CountChart
                  metricQuery={form.values.cube_query}
                  alerted={false}
                />
              </Paper>

              <Paper
                className={classes.paper}
                p="md"
                radius="sm"
                style={{ marginBottom: "20px", textAlign: "left" }}
              >
                <Title align="left" order={4} mb={10}>
                  {t("Alert Settings")}
                </Title>
                <Input.Wrapper
                  label="What comparison do you want to make?"
                  mt={15}
                >
                  <br />
                  <SegmentedControl
                    {...form.getInputProps("operator")}
                    data={[
                      { value: "equals", label: "Equals" },
                      { value: "greater_than", label: "Greater Than" },
                      { value: "less_than", label: "Less Than" },
                      {
                        value: "greater_than_or_equal_to",
                        label: "Greater Than Or Equal To",
                      },
                      {
                        value: "less_than_or_equal_to",
                        label: "Less Than Or Equal To",
                      },
                      { value: "not_equals", label: "Not Equal" },
                    ]}
                  />
                </Input.Wrapper>
                <NumberInput
                  placeholder="What value do you want to compare it against?"
                  label="What value do you want to compare it against?"
                  withAsterisk
                  mt={15}
                  {...form.getInputProps("comparison")}
                />
                <NumberInput
                  placeholder="How often (in minutes) do you want the alert to run?"
                  label="How often (in minutes) do you want the alert to run?"
                  withAsterisk
                  mt={15}
                  {...form.getInputProps("frequency")}
                />
                <Input.Wrapper
                  label="Would you like to enable or disable this alert?"
                  mt={15}
                >
                  <br />
                  <SegmentedControl
                    {...form.getInputProps("deactivated")}
                    data={[
                      { value: "false", label: "Enable Alert" },
                      { value: "true", label: "Disable Alert" },
                    ]}
                  />
                </Input.Wrapper>
                <Input.Wrapper
                  label="Would you like the alert to send a webhook only on a change, or to send it on every run?"
                  mt={15}
                >
                  <br />
                  <SegmentedControl
                    {...form.getInputProps("alert_on_state_change_only")}
                    data={[
                      { value: "true", label: "Only on a change" },
                      { value: "false", label: "Every run" },
                    ]}
                  />
                </Input.Wrapper>
                <Select
                  mt={15}
                  label="Webhook"
                  data={webhookAutocompleteData}
                  searchable
                  clearable
                  dropdownPosition="top"
                  {...form.getInputProps("webhook")}
                />
              </Paper>

              <div style={{ textAlign: "right" }}>
                {!isNewAlert && (
                  <Button
                    color="red"
                    onClick={isDeleteOpen}
                    style={{ marginRight: "8px" }}
                  >
                    {t("Delete")}
                  </Button>
                )}
                <Button
                  type="submit"
                  disabled={disableSubmit()}
                  loading={isLoadingSave}
                >
                  {t("Save")}
                </Button>
              </div>
            </form>
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Modal
        opened={isDeleteOpened}
        onClose={isDeleteClose}
        title={<Text size="lg">{t("Are you sure?")}</Text>}
      >
        <LoadingOverlay visible={isLoadingDelete} />
        <Grid align="center" justify="space-between">
          <Grid.Col span="content">
            {t("Are you sure you want to delete this alert?")}
          </Grid.Col>
        </Grid>
        <Divider my="sm" color="#E9E9E9" />
        <Grid align="center" justify="space-between">
          <Grid.Col span="content">
            <Button variant="default" onClick={isDeleteClose}>
              {t("No")}
            </Button>
          </Grid.Col>
          <Grid.Col span="content">
            <Button
              autoFocus
              color="red"
              onClick={() => {
                processNotification("Alert", t("Deleting alert..."));
                mutateDelete();
              }}
            >
              {t("Yes")}
            </Button>
          </Grid.Col>
        </Grid>
      </Modal>
    </Grid>
  );
};
