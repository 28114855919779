import React, { useEffect } from "react";
import { Grid, Button, Popover, Text, Select } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useTranslation } from "react-i18next";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import { FaCalendarDay } from "react-icons/fa";

export interface IMetricQueryTimeDimension {
  timeDimension?: string;
  granularity?: string;
  startDate?: Date;
  endDate?: Date;
}

interface IMetricsFiltersTimeDimensionProps {
  timeDimensionSuggestions?: string[];
  initialValues: IMetricQueryTimeDimension;
  onChange: (value: IMetricQueryTimeDimension) => void;
}

export const MetricsFiltersTimeDimensionAdvanced: React.FC<
  IMetricsFiltersTimeDimensionProps
> = ({ timeDimensionSuggestions, initialValues, onChange }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 50em)");
  const [opened, { toggle, close }] = useDisclosure(false);
  const form: any = useForm({
    initialValues,
    validate: {
      startDate: (value) =>
        value && form.values.endDate && value > form.values.endDate
          ? t(
              "End date cannot be earlier than the start date. Please ensure that the start date comes before the end date and try again."
            )
          : undefined,
      endDate: (value) =>
        value && form.values.endDate && value < form.values.startDate
          ? t(
              "End date cannot be earlier than the start date. Please ensure that the end date comes after the start date and try again."
            )
          : undefined,
    },
  });

  useEffect(() => {
    if (form.values.timeDimension !== initialValues?.timeDimension) {
      // we update default time dimension value dynaically based on suggestions in parent component
      // so we need to update form values when suggestions change
      form.setValues({ timeDimension: initialValues?.timeDimension });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues?.timeDimension]);

  return (
    <Popover
      opened={opened}
      onChange={toggle}
      closeOnClickOutside
      closeOnEscape
    >
      <Popover.Target>
        <Button variant="outline" onClick={() => toggle()}>
          <FaCalendarDay />
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <div style={{ width: isMobile ? "300px" : "500px" }}>
          <Text align="left" mb={10}>
            {t("Time Dimension")}
          </Text>
          <form
            onSubmit={form.onSubmit((values: IMetricQueryTimeDimension) => {
              onChange(values);
              close();
            })}
          >
            <Grid>
              {timeDimensionSuggestions && (
                <Grid.Col>
                  <Text align="left" mb={10} size="xs">
                    {t("Dimension")}
                  </Text>
                  <Select
                    clearable
                    searchable
                    placeholder={t("Pick one")}
                    data={timeDimensionSuggestions || []}
                    hoverOnSearchChange
                    {...form.getInputProps("timeDimension")}
                  />
                </Grid.Col>
              )}
              <Grid.Col>
                <Text align="left" mb={10} size="xs">
                  {t("Granularity")}
                </Text>
                <Select
                  placeholder={t("Pick one")}
                  searchable
                  data={[
                    {
                      value: "second",
                      label: t("Second"),
                    },
                    {
                      value: "minute",
                      label: t("Minute"),
                    },
                    {
                      value: "hour",
                      label: t("Hour"),
                    },
                    {
                      value: "day",
                      label: t("Day"),
                    },
                    {
                      value: "week",
                      label: t("Week"),
                    },
                    {
                      value: "month",
                      label: t("Month"),
                    },
                    {
                      value: "quarter",
                      label: t("Quarter"),
                    },
                    {
                      value: "year",
                      label: t("Year"),
                    },
                  ]}
                  hoverOnSearchChange
                  {...form.getInputProps("granularity")}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Text align="left" mb={10} size="xs">
                  {t("Range: Start Date")}
                </Text>
                <DateInput
                  placeholder={t("Pick one")}
                  {...form.getInputProps("startDate")}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Text align="left" mb={10} size="xs">
                  {t("Range: End Date")}
                </Text>
                <DateInput
                  placeholder={t("Pick one")}
                  {...form.getInputProps("endDate")}
                />
              </Grid.Col>
              <Grid.Col>
                <Grid justify="space-between">
                  <Grid.Col span="content">
                    <Button
                      variant="outline"
                      onClick={() => {
                        form.reset();
                        close();
                        onChange({});
                      }}
                    >
                      {t("Reset")}
                    </Button>
                  </Grid.Col>
                  <Grid.Col span="content">
                    <Button type="submit">{t("Apply")}</Button>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          </form>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};
