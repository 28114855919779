export function isStaging() {
  return window.location.href.includes("staging.bearworks.com");
}

export function isProd() {
  return window.location.href.includes("app.bearworks.com");
}

export function getEnvironment() {
  if (isProd()) {
    return "production";
  } else if (isStaging()) {
    return "staging";
  } else {
    return "local";
  }
}

export function getURL(path: string) {
  let url = "";
  if (window.location.href.includes("http://localhost")) {
    url = "http://localhost:8080/api" + path;
  } else {
    url = "/api" + path;
  }

  return url;
}
