import { loadOrgSelectionFromLocalStorage } from "@propelauth/react";
import axios from "axios";

const requestOptions = () => {
  const orgId = loadOrgSelectionFromLocalStorage();
  return {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "Org-ID": orgId,
    },
  };
};

const instance = axios.create({
  baseURL: window.location.href.includes("http://localhost")
    ? "http://localhost:8080"
    : "",
  ...requestOptions(),
});

export const getFetcher = async (
  url: string,
  accessToken: string | null | undefined
): Promise<any> => {
  if (!accessToken) {
    console.error("No access token provided", url);
    return null;
  }
  const response = await instance.get(url, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.data;
};

export const postFetcher = async (
  url: string,
  accessToken: string | null | undefined,
  payload: any
): Promise<any> => {
  if (!accessToken) {
    console.error("No access token provided");
    return null;
  }
  const response = await instance.post(url, payload, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.data;
};

export const patchFetcher = async (
  url: string,
  accessToken: string | null | undefined,
  payload: any
): Promise<any> => {
  if (!accessToken) {
    console.error("No access token provided");
    return null;
  }
  const response = await instance.put(url, payload, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.data;
};

export const deleteFetcher = async (
  url: string,
  accessToken: string | null | undefined
): Promise<any> => {
  if (!accessToken) {
    console.error("No access token provided");
    return null;
  }
  const response = await instance.delete(url, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.data;
};
