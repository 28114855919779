import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { Grid, LoadingOverlay, Pagination, Text, Title } from "@mantine/core";
import { AgGridReact } from "ag-grid-react";
import { ICellEditorParams } from "ag-grid-community";

interface IPagination {
  offset: number;
  limit: number;
  total: number;
  onChange: (offset: number, limit: number) => void;
}

interface ITableSheetProps {
  title?: string;
  subTitle?: string;
  isLoading: boolean;
  queryRowData: any[];
  userFormulasRowData?: any[];
  userSolvedRowData?: any[];
  onCellValueChanged?: (event: any) => void;
  readOnlyMode?: boolean;
  pagination?: IPagination;
  height?: number;
}

const getColumnLetter = (columnNumber: number): string => {
  let columnNumberCopy = columnNumber.valueOf();
  let columnName = "";

  let remainder = columnNumberCopy % 26;
  let letter = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".charAt(remainder);
  columnName += letter;
  columnNumberCopy = Math.floor(columnNumberCopy / 26);

  if (columnNumberCopy > 0) {
    return getColumnLetter(columnNumberCopy - 1) + letter;
  } else {
    return columnName;
  }
};

const MAX_COUNT_USER_COLUMNS = 100;

export const TableSheet: React.FC<ITableSheetProps> = ({
  title,
  subTitle,
  isLoading,
  queryRowData,
  userFormulasRowData,
  userSolvedRowData,
  onCellValueChanged,
  readOnlyMode,
  pagination,
  height,
}) => {
  const rowData = useMemo(() => {
    if (userSolvedRowData === undefined) return queryRowData;
    const newRowData = [];
    const maxCount = Math.max(queryRowData.length, userSolvedRowData.length);
    for (let i = 0; i < maxCount; i++) {
      newRowData.push({
        ...(userSolvedRowData[i] || {}),
        ...(queryRowData[i] || {}),
      });
    }
    return newRowData;
  }, [queryRowData, userSolvedRowData]);

  const columnDefs = useMemo(() => {
    if (!rowData.length) return [];
    const newColumnDefs = [];
    const rowHeaders = Object.keys(rowData[0]).filter(
      (header) => !header.includes("User")
    );

    for (let i = 0; i < MAX_COUNT_USER_COLUMNS; i++) {
      if (rowHeaders[i]) {
        newColumnDefs.push({
          headerName: `(${getColumnLetter(i)}) ${rowHeaders[i]}`,
          field: rowHeaders[i],
        });
      } else if (!readOnlyMode) {
        const j = i - rowHeaders.length + 1;
        newColumnDefs.push({
          headerName: `(${getColumnLetter(i)}) User-${j}`,
          editable: !readOnlyMode,
          field: `User-${j}`,
        });
      }
    }
    return newColumnDefs;
  }, [rowData, readOnlyMode]);

  const CustomCellEditor = forwardRef<
    HTMLInputElement,
    ICellEditorParams & { formulas: any }
  >((props, ref) => {
    const [value, setValue] = useState("");
    const rowIndex = props.rowIndex;
    const colKey = props.colDef.field;
    const formulas = props.formulas;

    useEffect(() => {
      if (rowIndex === null || !colKey || !formulas) return;
      const formula = (formulas[rowIndex] || {})[colKey];
      if (formula) {
        setValue(formula);
      }
    }, [rowIndex, colKey, formulas]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        props.stopEditing(); // Stop editing on Enter key press
      }
    };

    const getValue = () => {
      return value;
    };
    React.useImperativeHandle(ref as any, () => ({ getValue }));

    return (
      <input
        ref={ref}
        style={{
          height: "100%",
          width: "100%",
          border: 0,
        }}
        type="text"
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        autoFocus
        onFocus={(event) => event.target.select()}
      />
    );
  });

  const totalPages = pagination
    ? Math.floor(pagination.total / pagination.limit) + 1
    : 0;
  return (
    <>
      <Title align="left" order={6} mb={10}>
        {title}
      </Title>
      <Text color="gray" align="left" size="xs" mb={10}>
        {subTitle}
      </Text>
      <div className="ag-theme-balham" style={{ height: height || 400 }}>
        <div style={{ width: "100%", height: "100%", position: "relative" }}>
          <LoadingOverlay visible={isLoading} overlayBlur={2} />
          <AgGridReact
            defaultColDef={{
              resizable: true,
              sortable: true,
              cellEditorFramework: CustomCellEditor,
              cellEditorParams: { formulas: userFormulasRowData },
            }}
            rowData={rowData}
            columnDefs={columnDefs}
            onCellValueChanged={onCellValueChanged}
          />
        </div>
      </div>
      {pagination && totalPages > 1 && (
        <Grid justify="end" mt={15}>
          <Grid.Col span="content">
            <Pagination
              size="sm"
              total={totalPages || 1}
              value={Math.floor(pagination.offset / pagination.limit) + 1}
              onChange={(page: number) => {
                pagination.onChange(
                  (page - 1) * pagination.limit,
                  pagination.limit
                );
              }}
            />
          </Grid.Col>
        </Grid>
      )}
    </>
  );
};
