import React, { useState, useEffect } from "react";
import {
  Text,
  Table,
  Grid,
  LoadingOverlay,
  Group,
  Button,
  Card,
  Title,
} from "@mantine/core";
import { useAuthInfo } from "@propelauth/react";
import { FaPlus } from "react-icons/fa";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { getFetcher } from "fetchers/fetchers";
import { logPageView } from "utils/segment";
import { IError } from "interfaces/errors";
import { PageTitle } from "components/page-title";
import { ISheet } from "interfaces/sheets";
import { SheetItem } from "components/sheet-item";
import { LoadingSpinner } from "components/loading-spinner";

export const SheetList = () => {
  const { t } = useTranslation();
  const authInfo = useAuthInfo();
  const navigate = useNavigate();

  const [sortBySheet, setSortBySheet] = useState<string>("name");

  useEffect(() => {
    if (!authInfo.loading) {
      logPageView(
        authInfo.user?.email,
        authInfo.user?.firstName + " " + authInfo.user?.lastName
      );
    }
    // eslint-disable-next-line
  }, [authInfo.loading]);

  const { data: dataSheet, isLoading: isLoadingSheet } = useQuery<any, IError>(
    ["/api/sheets", sortBySheet],
    () =>
      getFetcher(`/api/sheets?sort_by=${sortBySheet}`, authInfo?.accessToken)
  );

  if (isLoadingSheet) {
    return <LoadingSpinner />;
  }

  return (
    <Grid p={24}>
      <Grid.Col>
        <PageTitle
          title={t("Spreadsheets")}
          subTitle={t("Dive deep into your data")}
          actions={[
            <Button
              variant="outline"
              onClick={() => {
                navigate(`/sheets/new`);
              }}
              leftIcon={<FaPlus />}
            >
              {t("Create Sheet")}
            </Button>,
          ]}
        />
        <Grid mt={10}>
          <Grid.Col span={12}>
            <Card radius="md">
              <LoadingOverlay visible={isLoadingSheet} />
              <Card.Section>
                <Title order={4} align="left" pl={20}>
                  {t("Your Sheets")}
                </Title>
                {(dataSheet || []).length > 0 ? (
                  <Table m={10}>
                    <thead>
                      <tr>
                        <th>
                          <Group>
                            <Text>{t("Spreadsheet")}</Text>
                            {sortBySheet === "name" && (
                              <AiOutlineArrowDown
                                cursor={"pointer"}
                                onClick={() => {
                                  setSortBySheet("-name");
                                }}
                              />
                            )}
                            {sortBySheet === "-name" && (
                              <AiOutlineArrowUp
                                cursor={"pointer"}
                                onClick={() => {
                                  setSortBySheet("name");
                                }}
                              />
                            )}
                          </Group>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataSheet.map((value: ISheet) => (
                        <SheetItem key={value.id} sheet={value} />
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <Text align="center" color="white" size="md" p={20}>
                    <Button
                      onClick={() => {
                        navigate(`/sheets/new`);
                      }}
                    >
                      {t("Create Sheet")}
                    </Button>
                  </Text>
                )}
              </Card.Section>
            </Card>
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  );
};
