import React, { useEffect } from "react";
import { Button, TextInput, Grid, Title, LoadingOverlay } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";
import { FaExchangeAlt, FaHubspot } from "react-icons/fa";
import { useMutation } from "@tanstack/react-query";

import bearLogo from "assets/logo.png";
import { postFetcher } from "fetchers/fetchers";
import { useAuthInfo } from "@propelauth/react";

interface IHubSpotFormProps {
  initialValues?: any;
  opened?: boolean;
  onClose: () => void;
  canImpersonate?: boolean;
}

export const HubSpotForm: React.FC<IHubSpotFormProps> = ({
  initialValues,
  opened,
  onClose,
  canImpersonate,
}) => {
  const { t } = useTranslation();
  const authInfo = useAuthInfo();

  const form = useForm({
    initialValues,
  });

  const { mutate: mutateCreate, isLoading } = useMutation(
    (payload: any) =>
      postFetcher("/api/integrations/hubspot", authInfo?.accessToken, payload),
    {
      onSuccess: (response) => {
        onClose();
        window.location.href = response.url;
      },
    }
  );

  useEffect(() => {
    if (opened && !canImpersonate) {
      mutateCreate({
        account_name: "Hubspot Account",
        redirect_url: window.location.href,
        redirect_host: window.location.host,
      });
      onClose();
    }
  }, [opened, onClose, canImpersonate, mutateCreate]);

  return (
    <div>
      <LoadingOverlay visible={isLoading} />
      <Grid>
        <Grid.Col span={12}>
          <Grid justify="center" align="center">
            <Grid.Col span="content">
              <FaHubspot size={50} />
            </Grid.Col>
            <Grid.Col span="content">
              <FaExchangeAlt size={20} color="#ADB5BD" />
            </Grid.Col>
            <Grid.Col span="content">
              <img src={bearLogo} width={50} className="bear-logo" alt="logo" />
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col span={12}>
          <Title align="center" order={4}>
            {t("Connect Hubspot with Bearworks")}
          </Title>
        </Grid.Col>
        <Grid.Col span={12}>
          <form
            onSubmit={form.onSubmit((values) => {
              mutateCreate({
                ...values,
                redirect_url: window.location.href,
                redirect_host: window.location.host,
              });
            })}
          >
            <Grid>
              <Grid.Col span={12}>
                <TextInput
                  withAsterisk
                  label={t("What's Your Hubspot Account Name?")}
                  placeholder={t("my account")}
                  {...form.getInputProps("account_name")}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <Grid align="center" justify="space-between">
                  {/* <Grid.Col span="auto">
                    <Button variant="default" mt={10}>
                      {t("Need help?")}
                    </Button>
                  </Grid.Col> */}
                  <Grid.Col span="content">
                    <Button variant="default" onClick={onClose}>
                      {t("Close")}
                    </Button>
                  </Grid.Col>
                  <Grid.Col span="content">
                    <Button type="submit">{t("Integrate")}</Button>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          </form>
        </Grid.Col>
      </Grid>
    </div>
  );
};
