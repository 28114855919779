import React, { useEffect, useState } from "react";
import {
  Card,
  Text,
  TypographyStylesProvider,
  createStyles,
  Loader,
} from "@mantine/core";
import { useAuthInfo, useActiveOrg } from "@propelauth/react";
import { MetricQuery } from "../types/metrics";
import { getCurrentOrg } from "../utils/org";

interface CountChartProps {
  metricQuery: MetricQuery | undefined;
  alerted?: boolean;
}

const useStyles = createStyles((theme, _params) => {
  return {
    card: {
      // this is too short compared to the other charts, it needs to have this
      height: "100%",
    },
  };
});

export default function CountChart(props: CountChartProps) {
  const [analyticsNumber, setAnalyticsNumber] = useState<number>();
  const [finishedLoading, setFinishedLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [color, setColor] = useState("black");
  const { classes } = useStyles();
  const selectedOrg = useActiveOrg();
  const authInfo = useAuthInfo();
  let accessToken: string | null = null;
  if (!authInfo.loading) {
    accessToken = authInfo.accessToken;
  }
  const [orgID, setOrgID] = useState<string>("");
  useEffect(() => {
    if (orgID && orgID.length > 0) {
      return;
    }
    if (!authInfo.loading) {
      getCurrentOrg(selectedOrg, authInfo, accessToken).then((value) => {
        if (value) {
          let localOrgID = value.orgId;
          if (!localOrgID) {
            setOrgID("");
          } else {
            setOrgID(localOrgID);
          }
        }
      });
    }
  }, [authInfo, accessToken, orgID, selectedOrg]);

  if (analyticsNumber) {
    if (props.alerted) {
      if (color !== "red") {
        setColor("red");
      }
    } else {
      if (color !== "black") {
        setColor("black");
      }
    }
  }

  useEffect(() => {
    if (!orgID || orgID.length === 0) {
      return;
    }

    function getColumnCount() {
      if (
        !props.metricQuery ||
        (props.metricQuery.measures.length === 0 &&
          props.metricQuery.dimensions.length === 0 &&
          props.metricQuery.timeDimension &&
          props.metricQuery.timeDimension.length === 0)
      ) {
        // if there is no query, just return this
        return;
      }
      let analyticsUrl = "";
      if (window.location.href.includes("http://localhost")) {
        analyticsUrl = "http://localhost:8080/api/query/query/number";
      } else {
        analyticsUrl = "/api/query/query/number";
      }
      fetch(analyticsUrl, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "Org-ID": orgID,
        },
        body: JSON.stringify({
          measures: props.metricQuery?.measures,
          dimensions: props.metricQuery?.dimensions,
          timeDimension: props.metricQuery?.timeDimension,
          filters: props.metricQuery?.filters,
        }),
      }).then((data) => {
        let jsonPromise = data.json();
        Promise.resolve(jsonPromise).then((jsonResponse) => {
          setFinishedLoading(true);
          console.log(jsonResponse);
          if (typeof jsonResponse == "object") {
            setError(
              "The response is not a number, please formulate the query again"
            );
            return;
          }
          let n = parseFloat(jsonResponse);
          console.log(n);
          if (typeof n !== "number") {
            setError(
              "The response is not a number, please formulate the query again"
            );
          } else {
            setAnalyticsNumber(n);
          }
        });
      });
    }
    getColumnCount();
  }, [
    props.metricQuery,
    accessToken,
    analyticsNumber,
    finishedLoading,
    props.metricQuery?.measures,
    props.metricQuery?.dimensions,
    props.metricQuery?.timeDimension,
    props.metricQuery?.filters,
    orgID,
  ]);

  function writeNumber() {
    if (
      !props.metricQuery ||
      (props.metricQuery.measures.length === 0 &&
        props.metricQuery.dimensions.length === 0 &&
        props.metricQuery.timeDimension &&
        props.metricQuery.timeDimension.length === 0)
    ) {
      return <>Add a query</>;
    }
    if (!finishedLoading) {
      return (
        <>
          <Loader />
        </>
      );
    } else if (analyticsNumber) {
      return <>{analyticsNumber}</>;
    } else if (error) {
      return <>{error}</>;
    }
  }

  return (
    <Card radius="md" p="xl" shadow="sm" withBorder className={classes.card}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <TypographyStylesProvider color={color}>
          <Text size="xl" weight={700} color={color}>
            {writeNumber()}
          </Text>
        </TypographyStylesProvider>
      </div>
    </Card>
  );
}
