import React from "react";
import queryString from "query-string";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  createStyles,
  Badge,
  Button,
  Grid,
  Text,
  Title,
  Card,
  Tooltip,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ITemplate } from "interfaces/templates";
import { AiOutlineLink } from "react-icons/ai";
import { successNotification } from "utils/notifications";
// import chartTemplateBg from "assets/chart_template_bg.svg";

const useStyles = createStyles((theme) => ({
  iconRightTop: {
    position: "absolute",
    top: 40,
    right: 40,
    cursor: "pointer",
  },
}));

interface IMetricsQueryTemplateItemDescriptionProps {
  disabled?: boolean;
  template: ITemplate;
  onUseTemplate: () => void;
  onCloseDescription: () => void;
}

export const MetricsQueryTemplateItemDescription: React.FC<
  IMetricsQueryTemplateItemDescriptionProps
> = ({ disabled, template, onUseTemplate, onCloseDescription }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigate = useNavigate();

  const url = `/metrics?${queryString.stringify({
    measures: template.measures || "",
    dimensions: template.dimensions || "",
    time: template.time || "",
    filters: template.filters ? JSON.stringify(template.filters) : "",
    template_id: template.id,
    template_name: template.name,
    template_categories: template.categories,
  })}`;

  return (
    <Card h="100%" w="100%" withBorder p={40} pt={30} mt={20}>
      <Grid align="end">
        <Grid.Col xs={12} lg={7}>
          <Text size={24} weight={400}>
            {template.name}
            <CopyToClipboard
              text={url}
              onCopy={() => {
                successNotification(
                  "url",
                  "Template url has been copied to clipboard."
                );
              }}
            >
              <AiOutlineLink className={classes.iconRightTop} />
            </CopyToClipboard>
          </Text>
          {template.description && (
            <Text size="sm" mt={5}>
              {template.description}
            </Text>
          )}
          <Title order={5} mt={20} weight={400}>
            {t("This template is used for:")}
          </Title>
          <Text size="sm" mt={10}>
            {template.use_for}
          </Text>
          {template.measures && (
            <>
              <Title mt={20} order={4} weight={700}>
                {t("Measures in use")}
              </Title>
              {template.measures.map((measure: string) => (
                <Badge
                  key={measure}
                  mt={10}
                  radius={5}
                  variant="outline"
                  color="gray"
                  p={12}
                  mr={5}
                  style={{ textTransform: "unset" }}
                >
                  <span style={{ color: "black" }}>{measure}</span>
                </Badge>
              ))}
            </>
          )}
          {template.dimensions && (
            <>
              <Title mt={20} order={4} weight={700}>
                {t("Dimensions in use")}
              </Title>
              {template.dimensions.map((dimension: string) => (
                <Badge
                  key={dimension}
                  mt={10}
                  radius={5}
                  variant="outline"
                  color="gray"
                  p={12}
                  mr={5}
                  style={{ textTransform: "unset" }}
                >
                  <span style={{ color: "black" }}>{dimension}</span>
                </Badge>
              ))}
            </>
          )}
        </Grid.Col>
        <Grid.Col xs={12} lg={5}>
          <Grid justify="end">
            <Grid.Col span="content">
              {/* <img src={chartTemplateBg} alt="Chart" /> */}
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col span={12}>
          <Grid justify="space-between" mt={15}>
            <Grid.Col span="content"></Grid.Col>
            <Grid.Col span="content">
              <Button color="gray" onClick={onCloseDescription}>
                {t("Cancel")}
              </Button>
              {disabled ? (
                <Tooltip
                  label={t(`Connect ${template.use_for} to use this template.`)}
                >
                  <Button
                    ml={10}
                    // tooltip doesn't work for disabled={disabled}
                    variant="outline"
                    color="gray"
                    style={{ cursor: "not-allowed" }}
                  >
                    {t("Use Template")}
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  ml={10}
                  onClick={() => {
                    navigate(url);
                    onUseTemplate();
                    onCloseDescription();
                  }}
                >
                  {t("Use Template")}
                </Button>
              )}
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
    </Card>
  );
};
