import React, { useEffect } from "react";
import { Grid, LoadingOverlay } from "@mantine/core";
import { useAuthInfo } from "@propelauth/react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import emptyAsset from "assets/empty.svg";

import { logPageView } from "utils/segment";
import { PageTitle } from "components/page-title";
import { getFetcher } from "fetchers/fetchers";
import { IError } from "interfaces/errors";
import { EmptyState } from "components/empty-state";

export const EmbeddedDashboard = () => {
  const { t } = useTranslation();
  const authInfo = useAuthInfo();
  const { dashboardID } = useParams();

  useEffect(() => {
    if (!authInfo.loading) {
      logPageView(
        authInfo.user?.email,
        authInfo.user?.firstName + " " + authInfo.user?.lastName
      );
    }
    // eslint-disable-next-line
  }, [authInfo.loading]);

  const { data: dashboard, isLoading } = useQuery<any, IError>(
    [`/api/embedded-dashboards/${dashboardID}`],
    () =>
      getFetcher(
        `/api/embedded-dashboards/${dashboardID}`,
        authInfo?.accessToken
      )
  );

  const iframeUrl = dashboard?.iframe_url;

  return (
    <Grid p={24}>
      <Grid.Col>
        <PageTitle
          title={
            dashboard?.name
              ? t("Dashboard: ") + dashboard?.name
              : t("Dashboard")
          }
        />
        <LoadingOverlay visible={isLoading} />
        {!isLoading && iframeUrl && (
          // eslint-disable-next-line jsx-a11y/iframe-has-title
          <iframe
            src={iframeUrl}
            frameBorder={0}
            width={1500}
            height={1500}
            allowTransparency
          />
        )}
        {!isLoading && !iframeUrl && (
          <EmptyState
            header={t("No data")}
            illustration={<img alt="" src={emptyAsset} />}
            description={t("No meta data")}
          />
        )}
      </Grid.Col>
    </Grid>
  );
};
