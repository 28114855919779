import { isStaging, isProd } from "./env";

export function logPageView(
  email: string | undefined,
  name: string | undefined
) {
  if (isStaging() || isProd()) {
    window.analytics.page();
    window.analytics.identify(email, { name: name, email: email });
  }
}
