import FourOhFour from "errors/404";
import { useQuery } from "@tanstack/react-query";
import { getFetcher } from "fetchers/fetchers";
import { IError } from "interfaces/errors";
import { useAuthInfo } from "@propelauth/react";
import { Anchor, Divider } from "@mantine/core";

export default function AllOrgs() {
  const authInfo = useAuthInfo();

  const { data: organization, isLoading: isOrganizationLoading } = useQuery<
    any,
    IError
  >(["/api/orgs/org_info"], () =>
    getFetcher("/api/orgs/org_info", authInfo?.accessToken)
  );

  const { data: orgs, isLoading: isOrgsLoading } = useQuery<any, IError>(
    ["/api/orgs/orgs"],
    () => getFetcher("/api/orgs/orgs", authInfo?.accessToken)
  );

  if (authInfo.loading) {
    return <FourOhFour />;
  }

  if (!organization || !orgs) {
    return <FourOhFour />;
  }

  if (isOrganizationLoading || isOrgsLoading) {
    return <FourOhFour />;
  }

  if (organization && organization?.can_impersonate) {
    return (
      <>
        <div>
          {orgs.map((org: any) => {
            let base = "";
            if (window.location.href.includes("http://localhost")) {
              base = "http://localhost:3000";
            } else if (
              window.location.href.includes("https://app.bearworks.com")
            ) {
              base = "https://app.bearworks.com";
            } else if (
              window.location.href.includes("https://staging.bearworks.com")
            ) {
              base = "https://staging.bearworks.com";
            }
            return (
              <div>
                <Anchor href={`${base}/?org_id=${org.propel_id}`}>
                  {org.org_name}
                </Anchor>
                <Divider></Divider>
              </div>
            );
          })}
        </div>
      </>
    );
  }

  return <FourOhFour />;
}
