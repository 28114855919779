import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Grid,
  MultiSelect,
  Popover,
  Select,
  Text,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";

import { IMetricFilter, IMetricType } from "interfaces/metrics";
import { getGroup } from "components/metrics-explorer-query-form";

const ALL_OPERATORS: Record<string, string[]> = {
  equals: ["string", "number", "time", "boolean"],
  notEquals: ["string", "number", "time", "boolean"],
  contains: ["string"],
  notContains: ["string"],
  startsWith: ["string"],
  endsWith: ["string"],
  gt: ["number"],
  gte: ["number"],
  lt: ["number"],
  lte: ["number"],
  set: ["string", "number", "time"],
  notSet: ["string", "number", "time"],
  inDateRange: ["time"],
  notInDateRange: ["time"],
  beforeDate: ["time"],
  afterDate: ["time"],
};

const OPERATORS_OPTIONS: Record<string, any> = {
  equals: { value: "equals", label: "Equals" },
  notEquals: { value: "notEquals", label: "Not Equals" },
  contains: { value: "contains", label: "Contains" },
  notContains: { value: "notContains", label: "Does not contain" },
  startsWith: { value: "startsWith", label: "Starts With" },
  endsWith: { value: "endsWith", label: "Ends With" },
  gt: { value: "gt", label: "Greater Than" },
  gte: { value: "gte", label: "Greater Than or Equal to" },
  lt: { value: "lt", label: "Less Than" },
  lte: { value: "lte", label: "Less Than or Equal to" },
  set: { value: "set", label: "Is Set" },
  notSet: { value: "notSet", label: "Is Not Set" },
  inDateRange: { value: "inDateRange", label: "Is in Date Range" },
  notInDateRange: { value: "notInDateRange", label: "Is Not in Date Range" },
  beforeDate: { value: "beforeDate", label: "Before Date" },
  afterDate: { value: "afterDate", label: "After Date" },
};

interface IFiltersProps {
  memberOptions?: IMetricType[];
  value?: IMetricFilter[];
  onChange: (value: IMetricFilter[]) => void;
}

export const MetricsFilters: React.FC<IFiltersProps> = ({
  memberOptions,
  value,
  onChange,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 50em)");
  const [opened, { toggle }] = useDisclosure(false);
  const [operatorOptions, setOperatorOptions] = useState<IMetricType[]>([]);
  const [valuesOptions, setValuesOptions] = useState<any[]>([]);

  const [member, setMember] = useState<string | null>(null);
  const [operator, setOperator] = useState<string | null>(null);
  const [values, setValues] = useState<string[]>([]);

  const isMemberDisabled = !memberOptions || memberOptions.length === 0;
  const isOperatorDisabled =
    !member || !operatorOptions || operatorOptions.length === 0;
  const isValueDisabled = !member || !operator;

  useEffect(() => {
    if (!member) {
      setOperator(null);
    }
  }, [member]);

  useEffect(() => {
    if (!operator) {
      setValues([]);
    }
  }, [operator]);

  useEffect(() => {
    setOperatorOptions(
      Object.keys(ALL_OPERATORS)
        .filter((key: string) => {
          if (!member) {
            return false;
          }
          const selectedMember = (memberOptions || []).find(
            (d) => d.name === member
          );
          if (!selectedMember) {
            return false;
          }
          return ALL_OPERATORS[key].includes(selectedMember.type);
        })
        .map((key: string) => OPERATORS_OPTIONS[key])
    );
  }, [member, memberOptions]);

  return (
    <Popover
      opened={opened}
      onChange={toggle}
      closeOnClickOutside
      closeOnEscape
    >
      <Popover.Target>
        <Button variant="outline" onClick={() => toggle()}>
          {t("Filters")}{" "}
          {value && value.length > 0 && <Text ml={2}>({value.length})</Text>}
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <div style={{ width: isMobile ? "300px" : "500px" }}>
          <Text align="left" mb={10}>
            {t("Filters")}
          </Text>
          {value && value.length > 0 && (
            <>
              {value.map((filter: IMetricFilter, index: number) => {
                const filterDimension = (memberOptions || []).find(
                  (d) => d.name === filter.member
                );
                const filterOperator = Object.values(
                  OPERATORS_OPTIONS || []
                ).find((o) => o.value === filter.operator);
                return (
                  <Grid
                    mb={5}
                    key={index}
                    justify="space-between"
                    align="center"
                  >
                    <Grid.Col span="content">
                      <Text size="sm">
                        {filterDimension?.label} - {filterOperator?.label}
                        {!["set", "notSet"].includes(filter.operator || "") &&
                          ` - ${filter.values.join(", ")}`}
                      </Text>
                    </Grid.Col>
                    <Grid.Col span="content">
                      <Button
                        variant="light"
                        radius="md"
                        size="xs"
                        color="red"
                        onClick={() => {
                          const newValue = [...value];
                          newValue.splice(index, 1);
                          onChange(newValue);
                        }}
                      >
                        {t("Remove")}
                      </Button>
                    </Grid.Col>
                  </Grid>
                );
              })}
              <Divider mb={15} />
            </>
          )}
          <Grid>
            <Grid.Col span={12}>
              <Select
                disabled={isMemberDisabled}
                placeholder={t("Select a member")}
                data={(memberOptions || []).map((value) => ({
                  value: value.value,
                  label: value.title,
                  group: getGroup(value.title),
                }))}
                searchable
                value={member}
                onChange={(value) => setMember(value)}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <Select
                disabled={isOperatorDisabled}
                data={operatorOptions || []}
                placeholder={
                  isOperatorDisabled
                    ? t("Select dimension value firstly")
                    : t("Select an operator")
                }
                searchable
                value={operator}
                onChange={(value) => setOperator(value)}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <MultiSelect
                disabled={isValueDisabled}
                placeholder={
                  isValueDisabled
                    ? t("Select dimension and operator values firstly")
                    : t("Input values")
                }
                data={valuesOptions || []}
                rightSection={<></>}
                getCreateLabel={(query) => `${query}`}
                searchable
                creatable
                value={values}
                onCreate={(query) => {
                  setValues([...values, query]);
                  setValuesOptions([
                    ...valuesOptions,
                    { value: query, label: query },
                  ]);
                  return query;
                }}
                onChange={(value) => setValues(value)}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <Grid justify="end">
                <Grid.Col span="content">
                  <Button
                    disabled={
                      !member ||
                      !operator ||
                      (values.length === 0 &&
                        !["set", "notSet"].includes(operator))
                    }
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      if (
                        !member ||
                        !operator ||
                        (values.length === 0 &&
                          !["set", "notSet"].includes(operator))
                      ) {
                        return;
                      }
                      onChange([
                        ...(value || []),
                        {
                          member,
                          operator,
                          values,
                        },
                      ]);
                      setMember(null);
                      setOperator(null);
                      setValues([]);
                    }}
                  >
                    {t("Add Filter")}
                  </Button>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};
