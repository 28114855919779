import { Text } from "@mantine/core";
import { Link } from "react-router-dom";

interface functionProps {
  salesforce?: boolean;
  hubspot?: boolean;
}

export default function CreateAnIntegration(props: functionProps) {
  if (props.salesforce) {
    return (
      <>
        <Text size="xl">
          You currently do not have salesforce set up as an integration,{" "}
          <Link to="/integrations">set it up</Link> and come back!
        </Text>
      </>
    );
  } else if (props.hubspot) {
    return (
      <>
        <Text size="xl">
          You currently do not have hubspot set up as an integration,{" "}
          <Link to="/integrations">set it up</Link> and come back!
        </Text>
      </>
    );
  }

  return (
    <>
      <Text size="xl">
        As a new user, you should start by{" "}
        <Link to="/integrations">creating an integration!</Link>
      </Text>
    </>
  );
}
