import { LoadingOverlay, createStyles, useMantineTheme } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  backgroundColor: {
    backgroundColor: theme.colors.gray[0],
  },
}));

export const LoadingSpinner: React.FC = () => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  return (
    <>
      <div
        style={{ width: "100%", height: "100%", position: "relative" }}
        className={classes.backgroundColor}
      >
        <LoadingOverlay
          loaderProps={{ size: "xl" }}
          visible={true}
          overlayOpacity={0}
          overlayBlur={1}
          overlayColor={theme.colors.gray[0]}
          className={classes.backgroundColor}
        />
      </div>
    </>
  );
};
