import React from "react";
import {
  Button,
  Divider,
  Grid,
  LoadingOverlay,
  Modal,
  Text,
  createStyles,
  TextInput,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useAuthInfo } from "@propelauth/react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteFetcher, patchFetcher, postFetcher } from "fetchers/fetchers";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { FaCopy, FaPen, FaTrash } from "react-icons/fa";
import { IDashboard } from "interfaces/dashboards";
import { useForm } from "@mantine/form";
import {
  errorNotification,
  processNotification,
  successNotification,
} from "utils/notifications";

const useStyles = createStyles((theme) => ({
  rowName: {
    textAlign: "left",
    cursor: "pointer",
  },
  rowActions: {
    textAlign: "right",
  },
}));

interface IDashboardItemProps {
  dashboard: IDashboard;
  isEmbedded?: boolean;
}

export const DashboardItem: React.FC<IDashboardItemProps> = ({
  dashboard,
  isEmbedded,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 50em)");
  const authInfo = useAuthInfo();
  const queryClient = useQueryClient();
  const { classes } = useStyles();
  const copyForm = useForm({
    initialValues: {
      name: `${dashboard.name} (copy)`,
    },
  });
  const updateForm = useForm({
    initialValues: {
      name: dashboard.name,
    },
  });
  const [isDeleteOpened, { open: isDeleteOpen, close: isDeleteClose }] =
    useDisclosure(false);
  const [isCopyOpened, { open: isCopyOpen, close: isCopyClose }] =
    useDisclosure(false);
  const [isUpdateOpened, { open: isUpdateOpen, close: isUpdateClose }] =
    useDisclosure(false);

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    () =>
      deleteFetcher(`/api/dashboards/${dashboard.id}`, authInfo?.accessToken),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["/api/dashboards"]);
        successNotification(
          "deleteDashboard",
          t("The dashboard has been deleted.")
        );
        isDeleteClose();
      },
      onError: () => {
        errorNotification("deleteDashboard");
      },
    }
  );

  const { mutate: mutateCopy, isLoading: isLoadingCopy } = useMutation(
    (payload: any) =>
      postFetcher("/api/dashboards/", authInfo?.accessToken, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["/api/dashboards"]);
        successNotification(
          "copyDashboard",
          t("The dashboard has been copied.")
        );
        isCopyClose();
      },
      onError: () => {
        errorNotification("copyDashboard");
      },
    }
  );

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
    (payload: any) =>
      patchFetcher(
        `/api/dashboards/${dashboard.id}`,
        authInfo?.accessToken,
        payload
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["/api/dashboards"]);
        successNotification(
          "updateDashboard",
          t("The dashboard has been updated.")
        );
        isUpdateClose();
      },
      onError: () => {
        errorNotification("updateDashboard");
      },
    }
  );

  return (
    <tr>
      <td
        className={classes.rowName}
        onClick={() => {
          if (isEmbedded) {
            navigate(`/dashboard/embedded/${dashboard.id}`);
            return;
          }
          navigate(`/dashboard/${dashboard.id}`);
        }}
      >
        {dashboard.name}
      </td>
      {!isEmbedded && (
        <td className={classes.rowActions}>
          <Button
            variant="light"
            size="xs"
            p={8}
            m={0}
            mr={10}
            radius={"50%"}
            onClick={isDeleteOpen}
            color="red"
            disabled={isLoadingDelete}
          >
            <FaTrash />
          </Button>
          <Button
            variant="light"
            size="xs"
            p={8}
            m={0}
            mr={10}
            radius={"50%"}
            onClick={isCopyOpen}
            disabled={isLoadingUpdate}
          >
            <FaCopy />
          </Button>
          <Button
            variant="light"
            size="xs"
            p={8}
            m={0}
            mr={20}
            radius={"50%"}
            onClick={isUpdateOpen}
            disabled={isLoadingUpdate}
          >
            <FaPen />
          </Button>
          <Modal
            opened={isDeleteOpened}
            onClose={isDeleteClose}
            title={<Text size="lg">{t("Are you sure?")}</Text>}
          >
            <LoadingOverlay visible={isLoadingDelete} />
            <Grid align="center" justify="space-between">
              <Grid.Col span="content">
                {t("Are you sure you want to delete this dashboard?")}
              </Grid.Col>
            </Grid>
            <Divider my="sm" color="#E9E9E9" />
            <Grid align="center" justify="space-between">
              <Grid.Col span="content">
                <Button variant="default" onClick={isDeleteClose}>
                  {t("No")}
                </Button>
              </Grid.Col>
              <Grid.Col span="content">
                <Button
                  autoFocus
                  color="red"
                  onClick={() => {
                    processNotification(
                      "deleteDashboard",
                      t("Deleting dashboard...")
                    );
                    mutateDelete();
                  }}
                >
                  {t("Yes")}
                </Button>
              </Grid.Col>
            </Grid>
          </Modal>
          <Modal
            fullScreen={isMobile}
            opened={isCopyOpened}
            onClose={() => {
              isCopyClose();
            }}
            title={<Text size="lg">{t("Copy Dashboard")}</Text>}
            size={320}
          >
            <LoadingOverlay visible={isLoadingCopy} />
            <Grid>
              <form
                onSubmit={copyForm.onSubmit((values: any) => {
                  processNotification(
                    "copyDashboard",
                    t("Copying dashboard...")
                  );
                  mutateCopy({
                    name: values.name,
                    default: false,
                    dashboard: dashboard.dashboard || [],
                  });
                })}
              >
                <Grid.Col span={12}>
                  <TextInput
                    withAsterisk
                    label={t("Dashboard Name")}
                    placeholder={t("What is the name of the new dashboard?")}
                    {...copyForm.getInputProps("name")}
                  />
                </Grid.Col>
                <Grid.Col span={12} mt={10}>
                  <Grid justify="end">
                    <Grid.Col span="content">
                      <Button
                        variant="outline"
                        onClick={() => {
                          isCopyClose();
                        }}
                      >
                        {t("Close")}
                      </Button>
                    </Grid.Col>
                    <Grid.Col span="content">
                      <Button type="submit">{t("Copy Dashboard")}</Button>
                    </Grid.Col>
                  </Grid>
                </Grid.Col>
              </form>
            </Grid>
          </Modal>
          <Modal
            fullScreen={isMobile}
            opened={isUpdateOpened}
            onClose={() => {
              isUpdateClose();
            }}
            title={<Text size="lg">{t("Update Dashboard")}</Text>}
            size={320}
          >
            <LoadingOverlay visible={isLoadingUpdate} />
            <Grid>
              <form
                onSubmit={updateForm.onSubmit((values: any) => {
                  processNotification(
                    "updateDashboard",
                    t("Updating dashboard...")
                  );
                  mutateUpdate({
                    name: values.name,
                  });
                })}
              >
                <Grid.Col span={12}>
                  <TextInput
                    withAsterisk
                    label={t("Dashboard Name")}
                    placeholder={t("What is the name of the new dashboard?")}
                    {...updateForm.getInputProps("name")}
                  />
                </Grid.Col>
                <Grid.Col span={12} mt={10}>
                  <Grid justify="end">
                    <Grid.Col span="content">
                      <Button
                        variant="outline"
                        onClick={() => {
                          isUpdateClose();
                        }}
                      >
                        {t("Close")}
                      </Button>
                    </Grid.Col>
                    <Grid.Col span="content">
                      <Button type="submit">{t("Update Dashboard")}</Button>
                    </Grid.Col>
                  </Grid>
                </Grid.Col>
              </form>
            </Grid>
          </Modal>
        </td>
      )}
    </tr>
  );
};
