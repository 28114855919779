import { useAuthInfo } from "@propelauth/react";
import { useQuery } from "@tanstack/react-query";
import { getFetcher } from "fetchers/fetchers";
import { IError } from "interfaces/errors";

export const useCanImpersonate = () => {
  const authInfo = useAuthInfo();

  const { data: organization } = useQuery<any, IError>(
    ["/api/orgs/org_info"],
    () => getFetcher("/api/orgs/org_info", authInfo?.accessToken),
    {
      retry: false,
    }
  );
  return organization?.can_impersonate;
};
