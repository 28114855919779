import React, { useEffect, useMemo, useState } from "react";
import { Card, Grid, Tabs, Text } from "@mantine/core";
import Chart from "react-apexcharts";
import queryString from "query-string";
import { useMutation } from "@tanstack/react-query";
import { useAuthInfo } from "@propelauth/react";

import { IMetricFilter } from "interfaces/metrics";
import { postFetcher } from "fetchers/fetchers";
import { TableSheet } from "components/table-sheet";
import { errorNotification } from "utils/notifications";
import { IError } from "interfaces/errors";
import { useTranslation } from "react-i18next";

interface IMetricChartProps {
  chartType?: string;
  title?: string;
  // base query
  measures: string[];
  dimensions: string[];
  filters?: IMetricFilter[];
  // time dimension
  timeDimension?: string;
  granularity?: string;
  startDate?: Date;
  endDate?: Date;
  // advanced
  transform?: string;
  fill?: string | null;
  // actions
  actions?: React.ReactNode[];
}

export const MetricChart: React.FC<IMetricChartProps> = ({
  chartType,
  title,
  startDate,
  endDate,
  measures,
  dimensions,
  timeDimension,
  transform,
  filters,
  granularity,
  fill,
  actions,
}) => {
  const { t } = useTranslation();
  const authInfo = useAuthInfo();
  const [chartJsonResponse, setChartJsonResponse] = useState<any>();
  const [tableLimit, setTableLimit] = useState<number>(10);
  const [tableOffset, setTableOffset] = useState<number>(0);
  const [tableTotal, setTableTotal] = useState<number>(0);

  const { mutate: mutateChartQuery, isLoading: isLoadingChartQuery } =
    useMutation(
      (payload: any) =>
        postFetcher(
          `/api/query/chart_query?${queryString.stringify({
            transpose: fill && fill !== "default" ? fill : undefined,
          })}`,
          authInfo?.accessToken,
          payload
        ),
      {
        onSuccess: (response) => {
          setChartJsonResponse(response);
        },
        onError: (error: IError) => {
          console.error(error);
          errorNotification("chartQuery");
        },
      }
    );

  const { mutate: mutateTableQuery, isLoading: isLoadingTableQuery } =
    useMutation(
      (payload: any) =>
        postFetcher(
          `/api/query/query?${queryString.stringify({
            transpose: fill && fill !== "default" ? fill : undefined,
            limit: tableLimit,
            offset: tableOffset,
          })}`,
          authInfo?.accessToken,
          payload
        ),
      {
        onSuccess: (response) => {
          setChartJsonResponse(response.results || []);
          setTableTotal(response.total || 0);
        },
        onError: (error: IError) => {
          console.error(error);
          errorNotification("tableQuery");
        },
      }
    );

  useEffect(() => {
    if (chartType === "table") {
      mutateTableQuery({
        measures,
        dimensions,
        filters,
        timeDimension,
        transform,
        startDate,
        endDate,
        granularity,
      });
    }
    if (chartType === "line") {
      mutateChartQuery({
        measures,
        dimensions,
        filters,
        timeDimension,
        transform,
        startDate,
        endDate,
        granularity,
      });
    }
  }, [
    measures,
    dimensions,
    filters,
    timeDimension,
    transform,
    chartType,
    startDate,
    endDate,
    granularity,
    mutateTableQuery,
    mutateChartQuery,
    tableLimit,
    tableOffset,
  ]);

  if (chartJsonResponse && chartType === "table") {
    const categories = Object.keys((chartJsonResponse || [])[0] || {});
    const dataSeries: number[] = Object.values((chartJsonResponse || [])[0] || {});
    return (
      <Card mb={10} p="xl" shadow="sm" withBorder style={{ minHeight: 270 }}>
        <Grid justify="space-between">
          <Grid.Col xs={8} xl={9}>
            <Text size="md" align="left" truncate>
              {title || chartJsonResponse.name}
            </Text>
          </Grid.Col>
          <Grid.Col xs={4} xl={3}>
            <Grid justify="end">
              {actions?.map((action, index) => (
                <Grid.Col span="content" key={index}>
                  {action}
                </Grid.Col>
              ))}
            </Grid>
          </Grid.Col>
        </Grid>
        <Tabs defaultValue="table">
          <Tabs.List>
            <Tabs.Tab value="table">{t("Table")}</Tabs.Tab>
            <Tabs.Tab value="bars">{t("Bars")}</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="table" pt="xs">
            <TableSheet
              height={292}
              isLoading={isLoadingTableQuery}
              queryRowData={chartJsonResponse}
              readOnlyMode={true}
              pagination={{
                limit: tableLimit,
                offset: tableOffset,
                total: tableTotal,
                onChange: (offset, limit) => {
                  setTableOffset(offset);
                  setTableLimit(limit);
                },
              }}
            />
          </Tabs.Panel>
          <Tabs.Panel value="bars" pt="xs">
            <Chart
              height={270}
              series={[
                {
                  name: "Count",
                  data: dataSeries,
                },
              ]}
              options={{
                chart: {
                  type: "bar",
                  animations: {
                    enabled: false,
                  },
                },
                grid: {
                  xaxis: {
                    lines: {
                      show: false,
                    },
                  },
                },
                xaxis: {
                  categories: categories,
                },
                responsive: [
                  {
                    breakpoint: 480,
                    options: {
                      legend: {
                        position: "bottom",
                      },
                    },
                  },
                ],
              }}
              type="bar"
            />
          </Tabs.Panel>
        </Tabs>
      </Card>
    );
  }

  if (!isLoadingChartQuery && chartJsonResponse && chartType === "line") {
    return (
      <Card mb={10} p="xl" shadow="sm" withBorder style={{ minHeight: 270 }}>
        <Grid justify="space-between" mb={10}>
          <Grid.Col xs={8} xl={9}>
            <Text size="md" align="left" truncate>
              {title || chartJsonResponse.name}
            </Text>
          </Grid.Col>
          <Grid.Col xs={4} xl={3}>
            <Grid justify="end">
              {actions?.map((action, index) => (
                <Grid.Col span="content" key={index}>
                  {action}
                </Grid.Col>
              ))}
            </Grid>
          </Grid.Col>
        </Grid>
        <Tabs defaultValue="line">
          <Tabs.List>
            <Tabs.Tab value="line">{t("Line")}</Tabs.Tab>
            <Tabs.Tab value="bars">{t("Bars")}</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="line" pt="xs">
            <Chart
              height={270}
              series={chartJsonResponse.series}
              options={{
                chart: {
                  type: "line",
                  animations: {
                    enabled: false,
                  },
                },
                grid: {
                  xaxis: {
                    lines: {
                      show: false,
                    },
                  },
                },
                xaxis: {
                  categories: chartJsonResponse.categories,
                },
                responsive: [
                  {
                    breakpoint: 480,
                    options: {
                      legend: {
                        position: "bottom",
                      },
                    },
                  },
                ],
              }}
              type="line"
            />
          </Tabs.Panel>
          <Tabs.Panel value="bars" pt="xs">
            <Chart
              height={270}
              series={chartJsonResponse.series}
              options={{
                chart: {
                  type: "bar",
                  animations: {
                    enabled: false,
                  },
                },
                grid: {
                  xaxis: {
                    lines: {
                      show: false,
                    },
                  },
                },
                xaxis: {
                  categories: chartJsonResponse.categories,
                },
                responsive: [
                  {
                    breakpoint: 480,
                    options: {
                      legend: {
                        position: "bottom",
                      },
                    },
                  },
                ],
              }}
              type="bar"
            />
          </Tabs.Panel>
        </Tabs>
      </Card>
    );
  }

  return null;
};
