import React, { useEffect, useState } from "react";
import {
  createStyles,
  Navbar,
  Text,
  getStylesRef,
  rem,
  Grid,
} from "@mantine/core";
import {
  IconHome,
  IconDatabase,
  IconCloudDataConnection,
  IconLayoutDashboard,
  IconLogout,
  IconFileImport,
  IconAlertTriangle,
  IconGauge,
  IconSettings,
  IconChevronRight,
  IconUsers,
  IconSkull,
  IconBoxModel,
  IconTable,
  IconChevronLeft,
  IconMessage2,
} from "@tabler/icons-react";
import logo from "assets/logo-white-background.png";
import { Link } from "react-router-dom";
import { useAuthInfo, useLogoutFunction } from "@propelauth/react";
import { useQuery } from "@tanstack/react-query";
import { getFetcher } from "fetchers/fetchers";
import { IError } from "interfaces/errors";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor: theme.fn.variant({
      variant: "filled",
      color: theme.primaryColor,
    }).background,
    transition: "all 0.2s",
  },

  navbarImpersonated: {
    backgroundColor: theme.colors.orange[7],
  },

  header: {
    paddingBottom: theme.spacing.xs,
    marginBottom: theme.spacing.xs,
    borderBottom: `${rem(1)} solid ${theme.fn.lighten(
      theme.fn.variant({ variant: "filled", color: theme.primaryColor })
        .background!,
      0.1
    )}`,
  },

  footer: {
    paddingTop: theme.spacing.xs,
    marginTop: theme.spacing.xs,
    borderTop: `${rem(1)} solid ${theme.fn.lighten(
      theme.fn.variant({ variant: "filled", color: theme.primaryColor })
        .background!,
      0.1
    )}`,
  },

  link: {
    ...theme.fn.focusStyles(),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: theme.fontSizes.sm,
    color: theme.white,
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor: theme.fn.lighten(
        theme.fn.variant({ variant: "filled", color: theme.primaryColor })
          .background!,
        0.1
      ),
    },

    span: {
      marginLeft: theme.spacing.xs,
    },
  },

  linkIcon: {
    ref: getStylesRef("icon"),
    color: theme.white,
    opacity: 0.75,
    cursor: "pointer",
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.lighten(
        theme.fn.variant({ variant: "filled", color: theme.primaryColor })
          .background!,
        0.15
      ),
      [`& .${getStylesRef("icon")}`]: {
        opacity: 0.9,
      },
    },
  },
}));

interface INavbarProps {
  displayLogoutOnly?: boolean;
  hidden?: boolean;
  hideLogout?: boolean;
}

export const NavbarSimpleColored: React.FC<INavbarProps> = ({
  displayLogoutOnly,
  hidden,
  hideLogout,
}) => {
  const { classes, cx } = useStyles();
  const isMobile = useMediaQuery("(max-width: 50em)");
  const { t } = useTranslation();
  const authInfo = useAuthInfo();
  const logoutFn = useLogoutFunction();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const navbarItems = [
    { link: "/", label: "Home", icon: IconHome },
    {
      link: "/integrations",
      label: "Integrations",
      icon: IconCloudDataConnection,
    },
    { link: "/data/salesforce", label: "Data", icon: IconDatabase },
    { link: "/dashboard", label: "Dashboard", icon: IconLayoutDashboard },
    { link: "/quota-file", label: "Quota Plan", icon: IconFileImport },
    { link: "/sheets", label: "Sheet", icon: IconTable },
    { link: "/alerts", label: "Alerts", icon: IconAlertTriangle },
    { link: "/custom-models", label: "Custom Models", icon: IconBoxModel },
    { link: "/metrics", label: "Metric Explorer", icon: IconGauge },
    // TODO: add in feature flags so we can
    // reenable this for testing for some customers or for ourselves
    { link: "/chat", label: "Chat", icon: IconMessage2 },
  ];

  useEffect(() => {
    if (isMobile) {
      setCollapsed(false);
    }
  }, [isMobile]);

  const { data: organization, isLoading: isLoadingOrganization } = useQuery<
    any,
    IError
  >(
    ["/api/orgs/org_info"],
    () => getFetcher("/api/orgs/org_info", authInfo?.accessToken),
    {
      enabled: !!authInfo?.accessToken,
      onError: (error) => {
        if (error.response?.status === 401) {
          if (window.location.pathname !== "/signup/org") {
            window.location.reload();
          }
        }
      },
    }
  );

  const { data: chatFeature } = useQuery<any, IError>(
    ["/api/features/chat"],
    () => getFetcher("/api/features/chat", authInfo?.accessToken),
    {
      enabled: !!authInfo?.accessToken,
    }
  );

  const { data: rawDataFeature } = useQuery<any, IError>(
    ["/api/features/chat"],
    () => getFetcher("/api/features/raw-data", authInfo?.accessToken),
    {
      enabled: !!authInfo?.accessToken,
    }
  );

  const { data: customModelsFeature } = useQuery<any, IError>(
    ["/api/features/custom-models"],
    () => getFetcher("/api/features/custom-models", authInfo?.accessToken),
    {
      enabled: !!authInfo?.accessToken,
    }
  );

  const orgIDs = authInfo.orgHelper?.getOrgIds();
  const orgID = organization?.orgId;
  let impersonation = false;
  if (orgIDs && organization && orgID) {
    if (!orgIDs.includes(orgID)) {
      impersonation = true;
    }
  }
  const currentOrgName = organization?.orgName;
  const multiOrg = orgIDs && orgIDs.length > 1;
  let canImpersonate = false;
  if (!isLoadingOrganization && organization) {
    canImpersonate = organization?.can_impersonate;
  }

  const urlSearchParams = new URLSearchParams(window.location.search);
  const iOrgId = urlSearchParams.get("org_id");

  return (
    <Navbar
      width={{ sm: collapsed ? 80 : 250 }}
      p="md"
      className={!impersonation ? classes.navbar : classes.navbarImpersonated}
      hiddenBreakpoint="sm"
      hidden={hidden}
    >
      <Navbar.Section grow>
        {collapsed ? (
          <Grid className={classes.header} justify="center" align="center">
            {!isMobile && (
              <Grid.Col span="content">
                <IconChevronRight
                  onClick={() => setCollapsed(false)}
                  className={classes.linkIcon}
                  stroke={1.5}
                />
              </Grid.Col>
            )}
          </Grid>
        ) : (
          <Grid
            className={classes.header}
            justify="space-between"
            align="center"
          >
            <Grid.Col span="content">
              <img width={60} src={logo} alt="logo" />
              {impersonation && (
                <Text weight={900} size={"lg"} color={"violet"}>
                  DANGER
                </Text>
              )}
            </Grid.Col>
            {!isMobile && (
              <Grid.Col span="content">
                <IconChevronLeft
                  onClick={() => setCollapsed(true)}
                  className={classes.linkIcon}
                  stroke={1.5}
                />
              </Grid.Col>
            )}
          </Grid>
        )}

        {!displayLogoutOnly &&
          navbarItems.map((item) => {
            if (item.label === "Chat") {
              if (!chatFeature) {
                return <span key={item.label}></span>;
              }
            }

            if (item.label === "Custom Models") {
              if (!customModelsFeature) {
                return <span key={item.label}></span>;
              }
            }

            if (item.label === "Data") {
              if (!rawDataFeature) {
                return <span key={item.label}></span>;
              }
            }

            return (
              <Link
                className={cx(classes.link, {
                  [classes.linkActive]:
                    item.link === "/"
                      ? window.location.pathname === "/"
                      : window.location.pathname.startsWith(item.link),
                })}
                to={iOrgId ? `${item.link}?org_id=${iOrgId}` : `${item.link}`}
                key={item.label}
              >
                <item.icon className={classes.linkIcon} stroke={1.5} />
                {!collapsed && <span>{item.label}</span>}
              </Link>
            );
          })}
      </Navbar.Section>
      {!hideLogout && (
        <Navbar.Section className={classes.footer}>
          {!displayLogoutOnly && (
            <Link
              className={cx(classes.link, {
                [classes.linkActive]:
                  window.location.pathname.startsWith("/settings"),
              })}
              to={iOrgId ? `/settings?org_id=${iOrgId}` : `/settings`}
              key="/settings"
            >
              <IconSettings className={classes.linkIcon} stroke={1.5} />
              {!collapsed && <span>{t("Settings")}</span>}
            </Link>
          )}
          {!displayLogoutOnly && multiOrg && (
            <Link
              className={cx(classes.link, {
                [classes.linkActive]:
                  window.location.pathname.startsWith("/switch-orgs"),
              })}
              to={iOrgId ? `/switch-orgs?org_id=${iOrgId}` : `/switch-orgs`}
              key="/switch-orgs"
            >
              <IconUsers className={classes.linkIcon} stroke={1.5} />
              {!collapsed && (
                <span>
                  {t("Switch Orgs")} {currentOrgName && currentOrgName}
                </span>
              )}
              {!collapsed && (
                <IconChevronRight className={classes.linkIcon} stroke={1.5} />
              )}
            </Link>
          )}
          {!displayLogoutOnly && !isLoadingOrganization && canImpersonate && (
            <Link
              className={cx(classes.link, {
                [classes.linkActive]:
                  window.location.pathname.startsWith("/orgs"),
              })}
              to="/orgs"
              key="/orgs"
            >
              <IconSkull className={classes.linkIcon} stroke={1.5} />
              {!collapsed && <span>{"All Orgs"}</span>}
            </Link>
          )}
          <a
            href="/"
            className={classes.link}
            onClick={(event) => {
              event.preventDefault();
              logoutFn(true);
            }}
          >
            <IconLogout className={classes.linkIcon} stroke={1.5} />
            {!collapsed && <span>{t("Logout")}</span>}
          </a>
        </Navbar.Section>
      )}
    </Navbar>
  );
};
