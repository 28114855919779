import React from "react";
import {
  createStyles,
  Badge,
  Text,
  Title,
  Card,
  LoadingOverlay,
} from "@mantine/core";
import { BsStar, BsStarFill } from "react-icons/bs";
import { ITemplate, ITemplateFavoritePayload } from "interfaces/templates";
import { useMutation } from "@tanstack/react-query";
import { patchFetcher } from "fetchers/fetchers";
import { useAuthInfo } from "@propelauth/react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  card: {
    cursor: "pointer",
  },

  iconRightTop: {
    position: "absolute",
    top: 10,
    right: 10,
    cursor: "pointer",
  },
}));

interface IMetricsQueryTemplateItemProps {
  template: ITemplate;
  onOpenDescription: () => void;
}

export const MetricsQueryTemplateItem: React.FC<
  IMetricsQueryTemplateItemProps
> = ({ template, onOpenDescription }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const authInfo = useAuthInfo();
  const [search] = useSearchParams();
  const [isFavorite, setIsFavorite] = React.useState<boolean>(
    template.is_favorite
  );
  const usedTemplateId = search.get("template_id");
  const isUsed = template.id === usedTemplateId;

  const { mutate: mutateFavorite, isLoading } = useMutation(
    (payload: ITemplateFavoritePayload) =>
      patchFetcher(
        `/api/templates/${template.id}`,
        authInfo?.accessToken,
        payload
      ),
    {
      onSuccess: (response: ITemplate) => {
        setIsFavorite(response.is_favorite);
      },
    }
  );

  return (
    <Card
      h="100%"
      w="100%"
      withBorder
      p={40}
      pt={30}
      className={classes.card}
      onClick={onOpenDescription}
    >
      <LoadingOverlay visible={isLoading} />
      {isFavorite ? (
        <BsStarFill
          color="#FCC419"
          className={classes.iconRightTop}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            mutateFavorite({ ...template, is_favorite: false });
          }}
        />
      ) : (
        <BsStar
          className={classes.iconRightTop}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            mutateFavorite({ ...template, is_favorite: true });
          }}
        />
      )}
      <Card.Section>
        <Title order={5} weight={400}>
          {template.name}
        </Title>
        <Text size="sm" color="gray">
          {template.description}
        </Text>
      </Card.Section>
      <Card.Section mt={10}>
        {isUsed && (
          <Badge mr={5} radius={3} variant="outline" color="blue">
            {t("Currently Used")}
          </Badge>
        )}
        {(template.categories || []).map((category: string) => (
          <Badge mr={5} radius={3} variant="outline" color="gray">
            {category}
          </Badge>
        ))}
      </Card.Section>
    </Card>
  );
};
