import React, { useMemo, useEffect } from "react";
import { Text, Grid } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { useAuthInfo } from "@propelauth/react";
import { useDisclosure } from "@mantine/hooks";
import { loadOrgSelectionFromLocalStorage } from "@propelauth/react";

import {
  EIntegrationType,
  IAddIntegration,
  IIntegration,
} from "interfaces/integrations";
import { AvailableIntegrationCard } from "components/available-integration-card";
import { IntegratedIntegrationCard } from "components/integrated-integration-card";
import { IError } from "interfaces/errors";
import { getFetcher } from "fetchers/fetchers";
import { LoadingSpinner } from "../../components/loading-spinner";
import { logPageView } from "utils/segment";
import { PageTitle } from "components/page-title";
import { useCanImpersonate } from "hooks/useCanImpersonate";
import { NewIntegrationLoading } from "components/new-integration-loading";
import { OrgWithOnboarding } from "types/types";

export const Integrations: React.FC = () => {
  const { t } = useTranslation();
  const authInfo = useAuthInfo();
  const [opened, { open, close }] = useDisclosure(false);
  const orgID = loadOrgSelectionFromLocalStorage();

  const orgFetcher = useQuery({
    queryKey: [authInfo.loading, authInfo.isLoggedIn, orgID, "/api/onboarding"],
    queryFn: () =>
      getFetcher("/api/integrations/organization", authInfo?.accessToken),
    enabled: (function () {
      if (authInfo.loading) {
        return false;
      }
      if (!orgID) {
        return false;
      }
      if (authInfo.accessToken) {
        return true;
      }
      return false;
    })(),
  });

  const { data: integrations, isLoading } = useQuery<any, IError>(
    ["/api/integrations/organization"],
    () => getFetcher("/api/integrations/organization", authInfo?.accessToken)
  );

  const { data: reSyncFeature } = useQuery<any, IError>(
    ["/api/features/resync"],
    () => getFetcher("/api/features/resync", authInfo?.accessToken)
  );

  useEffect(() => {
    if (!authInfo.loading) {
      logPageView(
        authInfo.user?.email,
        authInfo.user?.firstName + " " + authInfo.user?.lastName
      );
    }
    // eslint-disable-next-line
  }, [authInfo.loading]);

  const canImpersonate = useCanImpersonate();

  const hiddenAvailableIntegrations = useMemo(() => {
    const hiddenIntegrations = [];
    if (
      !canImpersonate &&
      integrations &&
      integrations.find(
        (i: IIntegration) => i.integration_type === EIntegrationType.SALESFORCE
      )
    ) {
      hiddenIntegrations.push(EIntegrationType.SALESFORCE);
    }
    if (
      !canImpersonate &&
      integrations &&
      integrations.find(
        (i: IIntegration) => i.integration_type === EIntegrationType.HUBSPOT
      )
    ) {
      hiddenIntegrations.push(EIntegrationType.HUBSPOT);
    }
    return hiddenIntegrations;
  }, [canImpersonate, integrations]);

  const availableIntegrations: IAddIntegration[] = [
    ...(hiddenAvailableIntegrations.includes(EIntegrationType.SALESFORCE)
      ? []
      : [
          {
            name: t("Salesforce"),
            type: EIntegrationType.SALESFORCE,
            description: t("Integrate Salesforce with Bearworks."),
          },
        ]),
    ...(hiddenAvailableIntegrations.includes(EIntegrationType.HUBSPOT)
      ? []
      : [
          {
            name: t("Hubspot"),
            type: EIntegrationType.HUBSPOT,
            description: t("Integrate Hubspot with Bearworks."),
          },
        ]),
    ...(hiddenAvailableIntegrations.includes(EIntegrationType.STRIPE)
      ? []
      : [
          {
            name: t("Stripe"),
            type: EIntegrationType.STRIPE,
            description: t("Integrate Stripe with Bearworks."),
          },
        ]),
    ...(hiddenAvailableIntegrations.includes(EIntegrationType.ACTIVITY)
      ? []
      : [
          {
            name: t("Activity Capture"),
            type: EIntegrationType.ACTIVITY,
            description: t("Integrate Activity with Bearworks."),
          },
        ]),
    ...(hiddenAvailableIntegrations.includes(EIntegrationType.WEBHOOK)
      ? []
      : [
          {
            name: t("Webhook"),
            type: EIntegrationType.WEBHOOK,
            description: t("Add Webhook to Bearworks."),
          },
        ]),
  ];

  useMemo(() => {
    if (orgFetcher.isLoading) {
      return false;
    }
    const orgWithOnboarding: OrgWithOnboarding | undefined = orgFetcher.data;
    if (!orgWithOnboarding) {
      return false;
    }

    if (orgWithOnboarding.first_sync_complete) {
      return false;
    }

    if (integrations && integrations.length === 1) {
      for (const integration of integrations) {
        const i: IIntegration = integration;
        const createdAt = Date.parse(i.created_at);
        if (createdAt && ~isNaN(createdAt)) {
          if (Date.now() - createdAt <= 5 * 60 * 1000) {
            return open();
          }
        }
      }
    }
    return false;
  }, [integrations, orgFetcher.isLoading, orgFetcher.data, open]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Grid p={24}>
        <Grid.Col>
          <PageTitle title={t("Integrations")} />

          {integrations && integrations.length > 0 && (
            <>
              <Text align="left" mb={10} size="md" color="gray">
                {t("INTEGRATED")}
              </Text>
              <Grid mb={15} align="stretch">
                {integrations.map((integration: IIntegration) => (
                  <Grid.Col md={6} lg={3} key={integration.integration_id}>
                    <IntegratedIntegrationCard
                      integration={integration}
                      reSyncFeature={reSyncFeature}
                    />
                  </Grid.Col>
                ))}
              </Grid>
              <Text align="left" mb={10} size="md" color="gray">
                {t("AVAILABLE INTEGRATIONS")}
              </Text>
            </>
          )}
          <Grid align="stretch">
            {availableIntegrations.map((integration: IAddIntegration) => (
              <Grid.Col md={6} lg={3} key={integration.name}>
                <AvailableIntegrationCard integration={integration} />
              </Grid.Col>
            ))}
          </Grid>
        </Grid.Col>
      </Grid>
      <NewIntegrationLoading onClose={close} opened={opened} />
    </>
  );
};
