import React, { useEffect, useState } from "react";
import {
  Grid,
  LoadingOverlay,
  Button,
  Modal,
  Divider,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { useAuthInfo } from "@propelauth/react";
import { FaPen, FaPlus, FaTrash } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import emptyAsset from "assets/empty.svg";

import { logPageView } from "utils/segment";
import { PageTitle } from "components/page-title";
import { EmptyState } from "components/empty-state";
import { deleteFetcher, getFetcher, patchFetcher } from "fetchers/fetchers";
import { IError } from "interfaces/errors";
import { MetricChart } from "components/metrics-chart";
import { IMetricQuery } from "interfaces/metrics";

import {
  errorNotification,
  processNotification,
  successNotification,
} from "utils/notifications";
import { LoadingSpinner } from "components/loading-spinner";
import {
  IMetricQueryTimeDimension,
  MetricsFiltersTimeDimensionAdvanced,
} from "components/metrics-filters-time-dimension";
import {
  getEndDate,
  getStartDate,
} from "components/metrics-explorer-query-form";

export const Dashboard = () => {
  const { t } = useTranslation();
  const authInfo = useAuthInfo();
  const navigate = useNavigate();
  const { dashboardID } = useParams();
  const queryClient = useQueryClient();
  const isMobile = useMediaQuery("(max-width: 50em)");
  const [search, setSearch] = useSearchParams();

  const [granularity, setGranularity] = useState<string | undefined>(
    search.get("granularity") || "day"
  );
  const [startDate, setStartDate] = useState<Date | undefined>(
    getStartDate(search.get("start_date"))
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    getEndDate(search.get("end_date"))
  );

  const updateForm = useForm();
  const updateChartForm = useForm();
  const [isDeleteOpened, { open: isDeleteOpen, close: isDeleteClose }] =
    useDisclosure(false);
  const [isUpdateOpened, { open: isUpdateOpen, close: isUpdateClose }] =
    useDisclosure(false);
  const [isDeleteChart, setIsDeleteChart] = useState<number | undefined>();
  const [isUpdateChart, setIsUpdateChart] = useState<number | undefined>();

  useEffect(() => {
    if (!authInfo.loading) {
      logPageView(
        authInfo.user?.email,
        authInfo.user?.firstName + " " + authInfo.user?.lastName
      );
    }
    // eslint-disable-next-line
  }, [authInfo.loading]);

  const {
    data: dashboard,
    isLoading,
    refetch,
  } = useQuery<any, IError>(
    [`/api/dashboards/${dashboardID}`],
    () => getFetcher(`/api/dashboards/${dashboardID}`, authInfo?.accessToken),
    {
      onSuccess: (data) => {
        updateForm.setValues({
          name: data.name,
        });
      },
    }
  );

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    () =>
      deleteFetcher(`/api/dashboards/${dashboardID}`, authInfo?.accessToken),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["/api/dashboards"]);
        successNotification(
          "deleteDashboard",
          t("The dashboard has been deleted.")
        );
        isDeleteClose();
        navigate("/dashboard");
      },
      onError: () => {
        errorNotification("deleteDashboard");
      },
    }
  );

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
    (payload: any) =>
      patchFetcher(
        `/api/dashboards/${dashboardID}`,
        authInfo?.accessToken,
        payload
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["/api/dashboards"]);
        successNotification(
          "updateDashboard",
          t("The dashboard has been updated.")
        );
        refetch();
        isUpdateClose();
        setIsDeleteChart(undefined);
        setIsUpdateChart(undefined);
        updateChartForm.reset();
      },
      onError: () => {
        errorNotification("updateDashboard");
      },
    }
  );

  const dashboardItems = dashboard?.dashboard || [];

  const getTitle = (query: IMetricQuery) => {
    if (query.name) {
      return query.name;
    } else {
      return `${query.measures} by ${query.dimensions} over ${query.timeDimension}`;
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid p={24}>
      <Grid.Col>
        <PageTitle
          title={
            dashboard?.name
              ? t("Dashboard: ") + dashboard?.name
              : t("Dashboard")
          }
          actions={[
            <MetricsFiltersTimeDimensionAdvanced
              initialValues={{}}
              onChange={(value: IMetricQueryTimeDimension) => {
                setGranularity(value.granularity);
                setStartDate(value.startDate);
                setEndDate(value.endDate);
                search.set("granularity", value.granularity || "day");
                search.set(
                  "start_date",
                  value.startDate ? value.startDate.toISOString() : ""
                );
                search.set(
                  "end_date",
                  value.endDate ? value.endDate.toISOString() : ""
                );
                setSearch(search);
              }}
            />,
            <Button
              variant="light"
              onClick={isDeleteOpen}
              color="red"
              disabled={isLoadingDelete}
            >
              <FaTrash />
            </Button>,
            <Button
              variant="light"
              onClick={isUpdateOpen}
              disabled={isLoadingUpdate}
            >
              <FaPen />
            </Button>,
            <Button
              onClick={() => {
                navigate("/metrics");
              }}
              leftIcon={<FaPlus />}
            >
              {t("Add Data")}
            </Button>,
          ]}
        />
        <LoadingOverlay visible={isLoading} />
        {!isLoading && dashboardItems.length > 0 && (
          <Grid mt={10}>
            {dashboardItems.map((query: IMetricQuery, index: number) => {
              return (
                <Grid.Col xl={6} key={index}>
                  <MetricChart
                    chartType={
                      query.display_type ||
                      (query.timeDimension ? "line" : "table")
                    }
                    title={getTitle(query)}
                    measures={query.measures}
                    dimensions={query.dimensions}
                    timeDimension={query.timeDimension}
                    filters={query.filters}
                    startDate={startDate || query.startDate}
                    endDate={endDate || query.endDate}
                    granularity={granularity || query.granularity}
                    fill={query.fill}
                    actions={[
                      <Button
                        variant="light"
                        size="xs"
                        p={8}
                        m={0}
                        radius={"50%"}
                        onClick={() => setIsDeleteChart(index)}
                        color="red"
                        disabled={isLoadingUpdate}
                      >
                        <FaTrash />
                      </Button>,
                      <Button
                        variant="light"
                        size="xs"
                        p={8}
                        m={0}
                        radius={"50%"}
                        onClick={() => {
                          setIsUpdateChart(index);
                          updateChartForm.setValues({
                            name: query.name || getTitle(query),
                          });
                        }}
                        disabled={isLoadingUpdate}
                      >
                        <FaPen />
                      </Button>,
                    ]}
                  />
                </Grid.Col>
              );
            })}
          </Grid>
        )}
        {!isLoading && dashboardItems.length === 0 && (
          <EmptyState
            header={t("Add data to fill dashboard")}
            illustration={<img alt="" src={emptyAsset} />}
            description={t(
              "You can add data from metric explorer to fill dashboard"
            )}
            primaryAction={
              <Button
                leftIcon={<FaPlus />}
                onClick={() => {
                  navigate("/metrics");
                }}
              >
                {t("Add Data")}
              </Button>
            }
          />
        )}
        <Modal
          opened={isDeleteChart !== undefined}
          onClose={() => setIsDeleteChart(undefined)}
          title={<Text size="lg">{t("Are you sure?")}</Text>}
        >
          <LoadingOverlay visible={isLoadingDelete} />
          <Grid align="center" justify="space-between">
            <Grid.Col span="content">
              {t("Are you sure you want to delete this chart?")}
            </Grid.Col>
          </Grid>
          <Divider my="sm" color="#E9E9E9" />
          <Grid align="center" justify="space-between">
            <Grid.Col span="content">
              <Button
                variant="default"
                onClick={() => setIsDeleteChart(undefined)}
              >
                {t("No")}
              </Button>
            </Grid.Col>
            <Grid.Col span="content">
              <Button
                autoFocus
                color="red"
                onClick={() => {
                  processNotification(
                    "updateDashboard",
                    t("Deleting chart...")
                  );
                  mutateUpdate({
                    name: dashboard?.name,
                    dashboard: dashboardItems.filter(
                      (item: IMetricQuery, index: number) =>
                        index !== isDeleteChart
                    ),
                  });
                }}
              >
                {t("Yes")}
              </Button>
            </Grid.Col>
          </Grid>
        </Modal>
        <Modal
          fullScreen={isMobile}
          opened={isUpdateChart !== undefined}
          onClose={() => setIsUpdateChart(undefined)}
          title={<Text size="lg">{t("Update Chart")}</Text>}
          size={300}
        >
          <LoadingOverlay visible={isLoadingUpdate} />
          <Grid>
            <form
              onSubmit={updateChartForm.onSubmit((values: any) => {
                processNotification("updateDashboard", t("Updating chart..."));
                mutateUpdate({
                  name: dashboard?.name,
                  dashboard: dashboardItems.map(
                    (item: IMetricQuery, index: number) => {
                      if (index === isUpdateChart) {
                        return {
                          ...item,
                          name: values.name,
                        };
                      } else {
                        return item;
                      }
                    }
                  ),
                });
              })}
            >
              <Grid.Col span={12}>
                <TextInput
                  withAsterisk
                  label={t("Chart Name")}
                  placeholder={t("What is the name of the chart?")}
                  {...updateChartForm.getInputProps("name")}
                />
              </Grid.Col>
              <Grid.Col span={12} mt={10}>
                <Grid justify="end">
                  <Grid.Col span="content">
                    <Button
                      variant="outline"
                      onClick={() => setIsUpdateChart(undefined)}
                    >
                      {t("Close")}
                    </Button>
                  </Grid.Col>
                  <Grid.Col span="content">
                    <Button type="submit">{t("Update Chart")}</Button>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </form>
          </Grid>
        </Modal>
        <Modal
          opened={isDeleteOpened}
          onClose={isDeleteClose}
          title={<Text size="lg">{t("Are you sure?")}</Text>}
        >
          <LoadingOverlay visible={isLoadingDelete} />
          <Grid align="center" justify="space-between">
            <Grid.Col span="content">
              {t("Are you sure you want to delete this dashboard?")}
            </Grid.Col>
          </Grid>
          <Divider my="sm" color="#E9E9E9" />
          <Grid align="center" justify="space-between">
            <Grid.Col span="content">
              <Button variant="default" onClick={isDeleteClose}>
                {t("No")}
              </Button>
            </Grid.Col>
            <Grid.Col span="content">
              <Button
                autoFocus
                color="red"
                onClick={() => {
                  processNotification(
                    "deleteDashboard",
                    t("Deleting dashboard...")
                  );
                  mutateDelete();
                }}
              >
                {t("Yes")}
              </Button>
            </Grid.Col>
          </Grid>
        </Modal>
        <Modal
          fullScreen={isMobile}
          opened={isUpdateOpened}
          onClose={() => {
            isUpdateClose();
          }}
          title={<Text size="lg">{t("Update Dashboard")}</Text>}
          size={320}
        >
          <LoadingOverlay visible={isLoadingUpdate} />
          <Grid>
            <form
              onSubmit={updateForm.onSubmit((values: any) => {
                processNotification(
                  "updateDashboard",
                  t("Updating dashboard...")
                );
                mutateUpdate({
                  name: values.name,
                });
              })}
            >
              <Grid.Col span={12}>
                <TextInput
                  withAsterisk
                  label={t("Dashboard Name")}
                  placeholder={t("What is the name of the new dashboard?")}
                  {...updateForm.getInputProps("name")}
                />
              </Grid.Col>
              <Grid.Col span={12} mt={10}>
                <Grid justify="end">
                  <Grid.Col span="content">
                    <Button
                      variant="outline"
                      onClick={() => {
                        isUpdateClose();
                      }}
                    >
                      {t("Close")}
                    </Button>
                  </Grid.Col>
                  <Grid.Col span="content">
                    <Button type="submit">{t("Update Dashboard")}</Button>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </form>
          </Grid>
        </Modal>
      </Grid.Col>
    </Grid>
  );
};
