import React, { useState } from "react";
import { useAuthInfo } from "@propelauth/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Modal,
  Select,
  Button,
  TextInput,
  Input,
  SegmentedControl,
  Grid,
  LoadingOverlay,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { IError } from "interfaces/errors";
import { getFetcher, postFetcher } from "fetchers/fetchers";
import { IMetricQuery } from "interfaces/metrics";
import {
  errorNotification,
  processNotification,
  successNotification,
} from "utils/notifications";

interface ISaveDashboardModalProps {
  query: IMetricQuery;
  opened: boolean;
  setOpened: (opened: boolean) => void;
}

export const SaveDashboardModal: React.FC<ISaveDashboardModalProps> = ({
  opened,
  setOpened,
  query,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authInfo = useAuthInfo();
  const isMobile = useMediaQuery("(max-width: 50em)");
  const queryClient = useQueryClient();
  const [currentDashboard, setCurrentDashboard] = useState<
    string | undefined
  >();

  const { data: dashboards, isLoading } = useQuery<any, IError>(
    ["/api/dashboards"],
    () => getFetcher("/api/dashboards", authInfo?.accessToken),
    {
      onSuccess: (data) => {
        if ((data?.dashboards || []).length === 0) {
          setCurrentDashboard(undefined);
        } else {
          setCurrentDashboard(data.slice(-1)[0]?.id);
        }
      },
    }
  );

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(
    (payload: any) =>
      postFetcher("/api/dashboards", authInfo?.accessToken, payload),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(["/api/dashboards"]);
        successNotification(
          "updateDashboard",
          t("The metrics have been saved to new dashboard.")
        );
        setCurrentDashboard(response.id);
        form.reset();
      },
      onError: () => {
        errorNotification("updateDashboard");
      },
    }
  );

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
    (payload: any) =>
      postFetcher(
        `/api/dashboards/${currentDashboard}/add-query`,
        authInfo?.accessToken,
        payload
      ),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(["/api/dashboards"]);
        successNotification(
          "updateDashboard",
          t("The metrics have been saved to the dashboard.")
        );
        setCurrentDashboard(response.id);
        form.setFieldValue("dashboard_id", response.id);
        setOpened(false);
      },
      onError: () => {
        errorNotification("updateDashboard");
      },
    }
  );

  const hasDashboards = (dashboards || []).length > 0;
  const form = useForm({ initialValues: { display_type: "line" } });

  return (
    <Modal
      centered
      fullScreen={isMobile}
      opened={opened}
      onClose={() => setOpened(false)}
      title={<Text size="lg">{t("Save this query to a dashboard")}</Text>}
    >
      <LoadingOverlay
        visible={isLoading || isLoadingCreate || isLoadingUpdate}
      />
      <Grid>
        <form
          onSubmit={form.onSubmit((values: any) => {
            processNotification(
              "updateDashboard",
              t("Saving query to dashboard.")
            );
            query.name = values.graph_name;
            query.display_type = values.display_type;
            if (currentDashboard) {
              mutateUpdate({ ...query });
            } else {
              mutateCreate({
                name: values.name,
                dashboard: [query],
              });
            }
          })}
        >
          <Grid.Col span={12}>
            {!currentDashboard ? (
              <TextInput
                withAsterisk
                label={t("Dashboard Name")}
                placeholder={t("What is the name of the new dashboard?")}
                {...form.getInputProps("name")}
              />
            ) : (
              <Select
                label={t("Pick the dashboard to add it to")}
                data={(dashboards || []).map((dashboard: any) => ({
                  value: dashboard.id,
                  label: dashboard.name,
                }))}
                value={currentDashboard}
                onChange={(value: string) => {
                  setCurrentDashboard(value);
                }}
              />
            )}
            {currentDashboard && (
              <Grid justify="start" mt={5}>
                <Grid.Col span="content">
                  <Button
                    size="xs"
                    onClick={() => {
                      return navigate(`/dashboard/${currentDashboard}`);
                    }}
                  >
                    {t("Go to dashboard")}
                  </Button>
                </Grid.Col>
              </Grid>
            )}
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              label={t("Graph Name")}
              placeholder={t("What is the name of the graph?")}
              {...form.getInputProps("graph_name")}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Input.Wrapper label="How would you like to display your data?">
              <SegmentedControl
                data={[
                  { value: "line", label: t("Graph") },
                  { value: "table", label: t("Table") },
                ]}
                {...form.getInputProps("display_type")}
              />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col span={12}>
            {!currentDashboard ? (
              <Grid justify="space-between">
                <Grid.Col span="content">
                  <Button
                    disabled={!hasDashboards}
                    variant="outline"
                    onClick={() => {
                      if (hasDashboards) {
                        setCurrentDashboard(dashboards.slice(-1)[0]?.id);
                      }
                    }}
                  >
                    {t("Update Dashboard")}
                  </Button>
                </Grid.Col>
                <Grid.Col span="content">
                  <Button type="submit">{t("Create New Dashboard")}</Button>
                </Grid.Col>
              </Grid>
            ) : (
              <Grid justify="space-between">
                <Grid.Col span="content">
                  <Button
                    variant="outline"
                    onClick={() => {
                      setCurrentDashboard(undefined);
                    }}
                  >
                    {t("Create New Dashboard")}
                  </Button>
                </Grid.Col>
                <Grid.Col span="content">
                  <Button type="submit">{t("Update Dashboard")}</Button>
                </Grid.Col>
              </Grid>
            )}
          </Grid.Col>
        </form>
      </Grid>
    </Modal>
  );
};
