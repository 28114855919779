import React, { useEffect, useState } from "react";

import {
  createStyles,
  Paper,
  Title,
  Text,
  TextInput,
  Button,
  Container,
  Group,
  Center,
  rem,
  Loader,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import { OrgMemberInfo } from "@propelauth/javascript";
import { notifications } from "@mantine/notifications";
import { IconX } from "@tabler/icons-react";
import { useAuthInfo, useActiveOrg } from "@propelauth/react";

import { logPageView } from "../utils/segment";
import { getCurrentOrg } from "../utils/org";

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: rem(26),
    fontWeight: 900,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  controls: {
    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column-reverse",
    },
  },

  control: {
    [theme.fn.smallerThan("xs")]: {
      width: "100%",
      textAlign: "center",
    },
  },
}));

export default function SignupOrg() {
  const form = useForm();
  const { classes } = useStyles();
  const [orgName, setOrgName] = useState<string>("");
  const [orgs, setOrgs] = useState<OrgMemberInfo[]>([]);
  const navigate = useNavigate();
  const [reloadCount, setReloadCount] = useState<number>(0);
  const [submitting, setSubmitting] = useState(false);
  const authInfo = useAuthInfo();
  const selectedOrg = useActiveOrg();
  let accessToken: string | null = null;
  if (!authInfo.loading) {
    accessToken = authInfo.accessToken;
  }
  const [orgID, setOrgID] = useState<string>("");
  useEffect(() => {
    if (orgID && orgID.length > 0) {
      return;
    }
    if (!authInfo.loading) {
      getCurrentOrg(selectedOrg, authInfo, accessToken).then((value) => {
        if (value) {
          let localOrgID = value.orgId;
          if (!localOrgID) {
            setOrgID("");
          } else {
            setOrgID(localOrgID);
          }
        }
      });
    }
  }, [authInfo, accessToken, orgID, selectedOrg]);

  useEffect(() => {
    if (!authInfo.loading) {
      logPageView(
        authInfo.user?.email,
        authInfo.user?.firstName + " " + authInfo.user?.lastName
      );
    }
    // eslint-disable-next-line
  }, [authInfo.loading]);

  useEffect(() => {
    if (orgs.length !== 0) {
      navigate("/");
    }
    setTimeout(() => {
      setReloadCount((reloadCount) => reloadCount + 1);
    }, 1000);
  });

  if (authInfo.loading || !authInfo.isLoggedIn) {
    // this should not be reachable
    return <></>;
  }

  if (authInfo.orgHelper.getOrgs().length !== orgs.length) {
    setOrgs(authInfo.orgHelper.getOrgs());
  }

  function handleSubmit() {
    if (!orgName) {
      return;
    }
    setSubmitting(true);
    let url = "";
    if (window.location.href.includes("http://localhost")) {
      url = "http://localhost:8080/api/orgs";
    } else {
      url = "/api/orgs";
    }
    return fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Org-ID": orgID,
      },
      body: JSON.stringify({ name: orgName }),
    })
      .then((data) => {
        if (data.status !== 200) {
          setSubmitting(false);
          data.json().then((j) => {
            notifications.show({
              id: "org" + reloadCount,
              autoClose: 10000,
              onClose: () => {},
              title: "Error",
              message: j.error,
              color: "red",
              icon: <IconX />,
              loading: false,
            });
          });
        } else {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
        setSubmitting(false);
        notifications.show({
          id: "org" + reloadCount,

          autoClose: 10000,
          onClose: () => {},
          title: "Error",
          message: "There was an error creating this org, please try again",
          color: "red",
          icon: <IconX />,
          loading: false,
        });
      });
  }

  function disableSubmit() {
    if (submitting) {
      return true;
    }
    if (orgName) {
      return false;
    }
    return true;
  }

  return (
    <>
      <div style={{ height: "100%", width: "100%" }}>
        <Center mx="auto" h={"80%"}>
          <Container size={460} my={30}>
            <Title className={classes.title} align="center">
              Welcome, {authInfo.user?.firstName} {authInfo.user?.lastName}!
            </Title>
            <Text c="dimmed" fz="sm" ta="center">
              Please enter the name of your company to get started.
            </Text>

            <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
              <form
                onSubmit={form.onSubmit(() => {
                  handleSubmit();
                })}
              >
                <TextInput
                  label="Organization Name"
                  placeholder="Bearworks"
                  required
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setOrgName(event.target.value);
                  }}
                />
                <Group position="apart" mt="lg" className={classes.controls}>
                  <Button
                    type="submit"
                    fullWidth
                    className={classes.control}
                    disabled={disableSubmit()}
                  >
                    {submitting && <Loader size={16} />}
                    {!submitting && "Get Started"}
                  </Button>
                </Group>
              </form>
            </Paper>
          </Container>
        </Center>
      </div>
    </>
  );
}
