import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  Button,
  TextInput,
  Grid,
  Title,
  LoadingOverlay,
  Anchor,
  FileInput,
  Text,
  Space,
} from "@mantine/core";
import { useForm, matches } from "@mantine/form";
import { useTranslation } from "react-i18next";
import { FaExchangeAlt } from "react-icons/fa";
import { GrStripe } from "react-icons/gr";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthInfo, useActiveOrg } from "@propelauth/react";
import { useQuery } from "@tanstack/react-query";

import { postFetcher } from "fetchers/fetchers";
import bearLogo from "assets/logo.png";
import { IError } from "interfaces/errors";
import { getFetcher } from "fetchers/fetchers";
import { getCurrentOrg } from "../../../utils/org";
import {
  errorNotification,
  processNotification,
  successNotification,
  syncNotification,
} from "utils/notifications";

interface IStripeFormProps {
  initialValues?: any;
  opened?: boolean;
  onClose: () => void;
}

export const StripeForm: React.FC<IStripeFormProps> = ({
  initialValues,
  onClose,
}) => {
  const { t } = useTranslation();
  const authInfo = useAuthInfo();
  const queryClient = useQueryClient();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [disabled, setDisabled] = useState(true);
  const [orgID, setOrgID] = useState<string>("");

  const selectedOrg = useActiveOrg();
  let accessToken: string | null = null;

  if (!authInfo.loading) {
    accessToken = authInfo.accessToken;
  }

  useEffect(() => {
    if (orgID && orgID.length > 0) {
      return;
    }
    if (!authInfo.loading) {
      getCurrentOrg(selectedOrg, authInfo, accessToken).then((value) => {
        if (value) {
          let localOrgID = value.orgId;
          if (!localOrgID) {
            setOrgID("");
          } else {
            setOrgID(localOrgID);
          }
        }
      });
    }
  }, [authInfo, accessToken, orgID, selectedOrg]);

  const { data: integrations } = useQuery<any, IError>(
    ["/api/integrations/organization"],
    () => getFetcher("/api/integrations/organization", authInfo?.accessToken)
  );

  const form = useForm({
    initialValues,
    validate: {
      stripe_account_id: matches(
        /^acct_[a-zA-Z0-9]{16,100}$/,
        t('Invalid Stripe Account ID (Example: "acct_1A2b3C4d5E6f7G8h9I0j")')
      ),
      stripe_key: matches(
        /^(sk|rk)_(test|live)_[a-zA-Z0-9]{16,100}$/,
        t(
          'Invalid Stripe API keys, make sure you are using the secret key or a restricted key (Example: "sk_test_1A2b3C4d5E6f7G8h9I0j")'
        )
      ),
    },
  });

  const { mutate: mutateCreate, isLoading } = useMutation(
    (payload) =>
      postFetcher("/api/integrations/stripe", authInfo?.accessToken, payload),
    {
      onSuccess: () => {
        onClose();
        queryClient.invalidateQueries(["/api/integrations/organization"]);
        successNotification(
          "createStripeIntegration",
          t("Stripe integration has been saved.")
        );
        if (integrations.length === 0) {
          syncNotification("createStripeIntegration");
        }
      },
      onError: (error: IError) => {
        console.error(error);
        errorNotification("createStripeIntegration");
      },
    }
  );

  const handleCSV = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const formData = new FormData();
    if (!selectedFile) {
      return;
    }
    formData.append("csvFile", selectedFile, selectedFile.name);
    let url = "";
    if (window.location.href.includes("http://localhost")) {
      url = "http://localhost:8080/api/integrations/stripecsv";
    } else {
      url = "/api/integrations/stripecsv";
    }
    axios
      .post(url, formData, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${authInfo?.accessToken}`,
          "Org-ID": orgID,
        },
      })
      .then((value) => {
        onClose();
        queryClient.invalidateQueries(["/api/integrations/organization"]);
        successNotification(
          "createStripeIntegration",
          t("Stripe CSV has been uploaded.")
        );
      })
      .catch((reason) => {
        console.error(reason);
        if (reason.response?.data?.detail) {
          errorNotification(
            "createStripeIntegration",
            reason.response.data.detail
          );
        } else {
          errorNotification("createStripeIntegration", String(reason));
        }
      });
  };

  return (
    <div>
      <LoadingOverlay visible={isLoading} />
      <Grid>
        <Grid.Col span={12}>
          <Grid justify="center" align="center">
            <Grid.Col span="content">
              <GrStripe size={50} />
            </Grid.Col>
            <Grid.Col span="content">
              <FaExchangeAlt size={20} color="#ADB5BD" />
            </Grid.Col>
            <Grid.Col span="content">
              <img src={bearLogo} width={50} className="bear-logo" alt="logo" />
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col span={12}>
          <Title align="center" order={4}>
            {t("Connect Stripe with Bearworks")}
          </Title>
        </Grid.Col>
        <Grid.Col span={12}>
          <form
            onSubmit={form.onSubmit((values) => {
              processNotification(
                "createStripeIntegration",
                t("Saving Stripe integration...")
              );
              mutateCreate(values);
            })}
          >
            <Grid>
              <Grid.Col span={12}>
                <TextInput
                  withAsterisk
                  label={t("Name Your Stripe Account")}
                  placeholder={t("What is my stripe account called?")}
                  {...form.getInputProps("stripe_account_name")}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <TextInput
                  withAsterisk
                  label={
                    <>
                      {t("Your")}{" "}
                      <Anchor
                        href="https://dashboard.stripe.com/settings/account"
                        target="_blank"
                      >
                        {t("Stripe Account ID")}
                      </Anchor>
                    </>
                  }
                  placeholder={t("acct_...")}
                  {...form.getInputProps("stripe_account_id")}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <TextInput
                  withAsterisk
                  label={
                    <>
                      {t("Your")}{" "}
                      <Anchor
                        href="https://dashboard.stripe.com/apikeys"
                        target="_blank"
                      >
                        {t("Stripe secret or restricted key")}
                      </Anchor>
                    </>
                  }
                  placeholder={t("sk_live_...")}
                  {...form.getInputProps("stripe_key")}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <Grid align="center">
                  {/* <Grid.Col span="auto">
                    <Button variant="default" mt={10}>
                      {t("Need help?")}
                    </Button>
                  </Grid.Col> */}
                  <Grid.Col span="auto">
                    <Button variant="default" onClick={onClose}>
                      {t("Close")}
                    </Button>
                  </Grid.Col>
                  <Grid.Col span="content">
                    <Button type="submit">{t("Integrate")}</Button>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          </form>
          <Space h="s" display="inline-block" />
          <Text size="xs" display="block">
            Not ready for the full integration? Export your stripe payments to
            see a static view of the dashboards.
            <Space display="inline-block" w="xs" />
            <Anchor
              size="xs"
              target="_blank"
              href="https://support.stripe.com/questions/exporting-payment-reports"
            >
              {t("Stripe Export Instructions")}
            </Anchor>
          </Text>

          <Space h="s" display="inline-block" />

          <form onSubmit={handleCSV}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <FileInput
                placeholder="Click here to choose your csv file"
                label="Stripe Payment Export"
                multiple={false}
                accept="text/csv"
                onChange={(file) => {
                  setSelectedFile(file);
                  if (file) {
                    setDisabled(false);
                  } else {
                    setDisabled(true);
                  }
                }}
              ></FileInput>
              <Button
                type="submit"
                disabled={disabled}
                style={{ alignSelf: "flex-end" }}
              >
                Upload
              </Button>
            </div>
          </form>
        </Grid.Col>
      </Grid>
    </div>
  );
};
