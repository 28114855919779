import React, { useEffect } from "react";
import {
  Button,
  Card,
  Grid,
  LoadingOverlay,
  Table,
  Text,
  Title,
  createStyles,
} from "@mantine/core";
import { useAuthInfo } from "@propelauth/react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";

import { IError } from "interfaces/errors";
import { getFetcher } from "fetchers/fetchers";
import { logPageView } from "utils/segment";
import { LoadingSpinner } from "components/loading-spinner";
import { PageTitle } from "components/page-title";
import emptyAsset from "assets/empty.svg";
import { EmptyState } from "components/empty-state";

const useStyles = createStyles((theme, _params) => {
  return {
    alertedRow: {
      color: theme.colors.red[7],
      cursor: "pointer",
    },
    normalRow: {
      cursor: "pointer",
    },
    tableLink: {
      textAlign: "left",
      cursor: "pointer",
    },
  };
});

export const AlertList: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const authInfo = useAuthInfo();

  useEffect(() => {
    if (!authInfo.loading) {
      logPageView(
        authInfo.user?.email,
        authInfo.user?.firstName + " " + authInfo.user?.lastName
      );
    }
    // eslint-disable-next-line
  }, [authInfo.loading]);

  const { data: integrations } = useQuery<any, IError>(
    ["/api/onboarding/integrations"],
    () => getFetcher("/api/onboarding/integrations", authInfo?.accessToken)
  );

  const { data: alerts, isLoading } = useQuery<any, IError>(
    ["/api/alerts/alerts"],
    () => getFetcher("/api/alerts/alerts", authInfo?.accessToken)
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid p={24}>
      <Grid.Col>
        <PageTitle
          title={t("Alerts")}
          subTitle={t("Make your metrics actionable")}
          actions={
            integrations && integrations.length > 0
              ? [
                  <Button
                    variant="outline"
                    onClick={() => {
                      navigate("/alerts/new");
                    }}
                    leftIcon={<FaPlus />}
                  >
                    {t("Create Alert")}
                  </Button>,
                ]
              : []
          }
        />
        {integrations && integrations.length > 0 ? (
          <Grid mt={10}>
            <Grid.Col span={12}>
              <Card radius="md">
                <LoadingOverlay visible={isLoading} />
                <Card.Section>
                  <Title order={4} align="left" pl={20}>
                    {t("Your Alerts")}
                  </Title>
                  {(alerts || []).length > 0 ? (
                    <Table m={10}>
                      <thead>
                        <tr>
                          <th>Alert Name</th>
                          <th>Dimensions</th>
                          <th>Measures</th>
                          <th>Operator</th>
                          <th>Compared To</th>
                          <th>Alerted?</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(alerts || []).map((alert: any) => {
                          const alerted =
                            alert.results &&
                            alert.results.length > 0 &&
                            alert.results[0].alerted;
                          return (
                            <tr
                              onClick={() => {
                                navigate(`/alerts/${alert.id}`);
                              }}
                              className={
                                alerted ? classes.alertedRow : classes.normalRow
                              }
                              key={alert.id}
                            >
                              <td className={classes.tableLink}>
                                <div>{alert?.name}</div>
                              </td>
                              <td className={classes.tableLink}>
                                <div>{alert?.cube_query?.dimensions}</div>
                              </td>
                              <td className={classes.tableLink}>
                                <div>{alert?.cube_query?.measures}</div>
                              </td>
                              <td className={classes.tableLink}>
                                <div>{alert.operator}</div>
                              </td>
                              <td className={classes.tableLink}>
                                <div>{alert.comparison}</div>
                              </td>
                              <td className={classes.tableLink}>
                                <div>{alerted ? "alerted" : "not alerted"}</div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  ) : (
                    <Text align="center" color="white" size="md" p={20}>
                      <Button
                        onClick={() => {
                          navigate("/alerts/new");
                        }}
                      >
                        {t("Create Alert")}
                      </Button>
                    </Text>
                  )}
                </Card.Section>
              </Card>
            </Grid.Col>
          </Grid>
        ) : (
          <EmptyState
            header={t("No integrations")}
            illustration={<img alt="" src={emptyAsset} />}
            description={t("You currently do not have integrations")}
            primaryAction={
              <Button
                leftIcon={<FaPlus />}
                onClick={() => {
                  navigate("/integrations");
                }}
              >
                {t("Set it Up")}
              </Button>
            }
          />
        )}
      </Grid.Col>
    </Grid>
  );
};
