import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Text,
  LoadingOverlay,
  Modal,
  Textarea,
  Card,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useAuthInfo } from "@propelauth/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FaBug, FaExclamationCircle } from "react-icons/fa";
import { useMediaQuery } from "@mantine/hooks";

import { postFetcher } from "fetchers/fetchers";
import { IError } from "interfaces/errors";
import { IMetaData, IMetricQuery } from "interfaces/metrics";
import { LoadingSpinner } from "components/loading-spinner";
import { PageTitle } from "components/page-title";
import { MetricsExplorerQueryForm } from "components/metrics-explorer-query-form";
import { SaveDashboardModal } from "components/metrics-save-dashboard";
import { MetricChart } from "components/metrics-chart";
import { MetricsQueryTemplate } from "components/metrics-query-template";
import { logPageView } from "utils/segment";
import { useSearchParams } from "react-router-dom";

export const MetricsExplorer: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 50em)");
  const authInfo = useAuthInfo();
  const [search] = useSearchParams();

  const [openSaveDashboardModal, setOpenSaveDashboardModal] =
    useState<boolean>(false);
  const [openDebugModal, setOpenDebugModal] = useState<boolean>(false);

  const [query, setQuery] = useState<IMetricQuery | undefined>();

  // response
  const [sqlValue, setSqlValue] = useState<string | undefined>();

  const { data: metaData, isLoading } = useQuery<IMetaData, IError>(
    ["/api/query/get_metadata"],
    () => postFetcher("/api/query/get_metadata", authInfo?.accessToken, {})
  );

  const { mutate: mutateQuery, isLoading: isLoadingQuery } = useMutation(
    (payload: any) =>
      postFetcher("/api/query/sql_query", authInfo?.accessToken, payload),
    {
      onSuccess: (response) => {
        setSqlValue(response);
      },
    }
  );

  useEffect(() => {
    if (!authInfo.loading) {
      logPageView(
        authInfo.user?.email,
        authInfo.user?.firstName + " " + authInfo.user?.lastName
      );
    }
    // eslint-disable-next-line
  }, [authInfo.loading]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  let templateName = `Template: ${search.get("template_name")}`;
  if (search.get("template_categories")) {
    if (search.get("template_categories")?.indexOf(",") === -1) {
      templateName = `${templateName}, Categories: ${search.get(
        "template_categories"
      )}`;
    } else {
      const categories = search.get("template_categories")?.replace(/,/g, ", ");
      templateName = `${templateName}, Categories: ${categories}`;
    }
  }

  templateName = t(templateName);

  return (
    <Grid p={24}>
      <Grid.Col>
        <PageTitle
          title={t("Metric Explorer")}
          subTitle={search.get("template_name") ? templateName : undefined}
          actions={[
            sqlValue ? (
              <Button
                variant="outline"
                color="red"
                onClick={() => {
                  setOpenDebugModal(true);
                }}
              >
                <FaBug />
              </Button>
            ) : null,
            <MetricsQueryTemplate />,
            query ? (
              <Button
                variant="outline"
                onClick={() => {
                  setOpenSaveDashboardModal(true);
                }}
              >
                {t("Save Dashboard")}
              </Button>
            ) : null,
          ]}
        />
        <MetricsExplorerQueryForm
          metaData={metaData}
          value={query}
          onChange={(query: IMetricQuery) => {
            setQuery(query);
            mutateQuery({ ...query });
          }}
        />
        {query && !isLoadingQuery && (
          <MetricChart
            chartType="line"
            measures={query.measures}
            dimensions={query.dimensions}
            filters={query.filters}
            timeDimension={query.timeDimension}
            granularity={query.granularity}
            startDate={query.startDate}
            endDate={query.endDate}
            fill={query.fill}
            transform={query.transform}
          />
        )}
        {query && !isLoadingQuery && (
          <MetricChart
            chartType="table"
            measures={query.measures}
            dimensions={query.dimensions}
            filters={query.filters}
            timeDimension={query.timeDimension}
            granularity={query.granularity}
            startDate={query.startDate}
            endDate={query.endDate}
            fill={query.fill}
            transform={query.transform}
          />
        )}
        {(!query || isLoadingQuery) && (
          <>
            <Card p="xl" mb={10} shadow="sm" withBorder>
              <Grid align="center" justify="space-around" h={270}>
                <Grid.Col span="content">
                  <LoadingOverlay visible={isLoadingQuery} />
                  <Text align="center" mb={10}>
                    <FaExclamationCircle />{" "}
                    {t("Run your Query to show line chart")}
                  </Text>
                </Grid.Col>
              </Grid>
            </Card>
            <Card p="xl" shadow="sm" withBorder>
              <Grid align="center" justify="space-around" h={270}>
                <Grid.Col span="content">
                  <LoadingOverlay visible={isLoadingQuery} />
                  <Text align="center" mb={10}>
                    <FaExclamationCircle /> {t("Run your Query to show table")}
                  </Text>
                </Grid.Col>
              </Grid>
            </Card>
          </>
        )}
        {query && (
          <SaveDashboardModal
            query={query}
            opened={openSaveDashboardModal}
            setOpened={setOpenSaveDashboardModal}
          />
        )}
        <Modal
          fullScreen={isMobile}
          opened={openDebugModal}
          onClose={() => {
            setOpenDebugModal(false);
          }}
          title={<Text size="lg">{t("Debug")}</Text>}
        >
          <Textarea value={sqlValue || ""} autosize readOnly />
        </Modal>
      </Grid.Col>
    </Grid>
  );
};
