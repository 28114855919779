import React, { useCallback, useState } from "react";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useAuthInfo, RedirectToLogin } from "@propelauth/react";
import { AppShell, Header, MediaQuery, Burger } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";

import "./App.css";

import Homepage from "containers/homepage";
import { DataDisplay } from "./data/Data";
import QuotaFile from "./data/QuotaFile";
import Settings from "./users/Settings";
import SignupOrg from "./signup/SignupOrg";
import LoadingFullPage from "./utils/Loading";
import OrgSwitcher from "./users/OrgSwitcher";
import FourOhFour from "./errors/404";
import SignupCheck from "./signup/SignupCheck";
import Chat from "./chat/Chat";
import CustomModel from "./custom_model/CustomModel";
import NoChildrenSignupCheck from "signup/NoChildrenSignupCheck";
import { Integrations } from "containers/integrations";
import { IntegrationOauthCallback } from "containers/integrations-oauth-callback";
import { MetricsExplorer } from "containers/metrics-explorer";
import { NavbarSimpleColored } from "components/navbar";
import { Sheet } from "containers/sheet";
import { getFetcher, postFetcher } from "fetchers/fetchers";
import { IError } from "interfaces/errors";
import AllOrgs from "containers/all-orgs";
import { DashboardList } from "containers/dashboard-list";
import { CustomModelList } from "containers/custom-model-list";
import { Dashboard } from "containers/dashboard";
import { EmbeddedDashboard } from "containers/embedded-dashboard";
import { AlertList } from "containers/alert-list";
import { Alert } from "containers/alert";
import { SheetList } from "containers/sheet-list";

const App: React.FC = () => {
  const authInfo = useAuthInfo();
  const [opened, setOpened] = useState(false);

  const { data: customModelsFeature } = useQuery<any, IError>(
    ["/api/features/custom-models"],
    () => getFetcher("/api/features/custom-models", authInfo?.accessToken),
    {
      enabled: !!authInfo?.accessToken,
    }
  );

  const { data: chatFeature } = useQuery<any, IError>(
    ["/api/features/chat"],
    () => getFetcher("/api/features/chat", authInfo?.accessToken),
    {
      enabled: !!authInfo?.accessToken,
    }
  );

  const { data: rawDataFeature } = useQuery<any, IError>(
    ["/api/features/raw-data"],
    () => getFetcher("/api/features/raw-data", authInfo?.accessToken),
    {
      enabled: !!authInfo?.accessToken,
    }
  );

  const { data: userExists, isLoading: userExistsIsLoading } = useQuery<
    any,
    IError
  >(
    ["/api/onboarding/check_exists"],
    () =>
      postFetcher("/api/onboarding/check_exists", authInfo?.accessToken, {}),
    {
      enabled: !!authInfo?.accessToken,
      onError: (error) => {
        if (error.response?.status === 401) {
          if (window.location.pathname !== "/signup/org") {
            window.location.reload();
          }
        }
      },
    }
  );

  const fillInShell = useCallback(
    (
      inside: JSX.Element | React.ReactFragment | React.ReactNode,
      displayLogoutOnly?: boolean,
      hideLogout?: boolean,
      doNotCheckSignup?: boolean
    ) => {
      return (
        <AppShell
          padding="md"
          header={
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Header height={{ sm: 0, base: 25 }}>
                <Burger
                  opened={opened}
                  onClick={() => setOpened((o) => !o)}
                  size="sm"
                  mr="xl"
                />
              </Header>
            </MediaQuery>
          }
          navbar={
            <NavbarSimpleColored
              displayLogoutOnly={displayLogoutOnly}
              hideLogout={hideLogout}
              hidden={!opened}
            />
          }
          styles={(theme) => ({
            main: {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[8]
                  : theme.colors.gray[0],
            },
          })}
        >
          <>
            {(doNotCheckSignup && inside) || (
              <SignupCheck>{inside}</SignupCheck>
            )}
          </>
        </AppShell>
      );
    },
    [opened]
  );

  if (!authInfo.loading && !authInfo.isLoggedIn) {
    return <RedirectToLogin />;
  }

  if (authInfo.loading || !authInfo.accessToken) {
    return fillInShell(<LoadingFullPage />, true, false, true);
  }

  if (!authInfo.loading) {
    <NoChildrenSignupCheck />;
  }

  if (
    !authInfo.loading &&
    authInfo.orgHelper.getOrgs().length !== 0 &&
    userExistsIsLoading
  ) {
    return fillInShell(<LoadingFullPage />, true, false, true);
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: fillInShell(<Homepage />),
    },
    {
      path: "/integrations",
      element: fillInShell(<Integrations />),
    },
    {
      path: "/oauth/:integrationName/callback",
      element: fillInShell(<IntegrationOauthCallback />, true, true),
    },
    {
      path: "/data/:tabValue",
      element: fillInShell(rawDataFeature ? <DataDisplay /> : <FourOhFour />),
    },
    {
      path: "/quota-file",
      element: fillInShell(<QuotaFile />),
    },
    {
      path: "/dashboard",
      element: fillInShell(<DashboardList />),
    },
    {
      path: "/dashboard/embedded/:dashboardID",
      element: fillInShell(<EmbeddedDashboard />),
    },
    {
      path: "/dashboard/:dashboardID",
      element: fillInShell(<Dashboard />),
    },
    {
      path: "/alerts",
      element: fillInShell(<AlertList />),
    },
    {
      path: "/alerts/:alertID",
      element: fillInShell(<Alert />),
    },
    {
      path: "/metrics",
      element: fillInShell(<MetricsExplorer />),
    },
    {
      path: "/settings",
      element: fillInShell(<Settings />),
    },
    {
      path: "/settings/:routeSlug",
      element: fillInShell(<Settings />),
    },
    {
      path: "/signup/org",
      element: fillInShell(<SignupOrg />, true),
    },
    {
      path: "/switch-orgs",
      element: fillInShell(<OrgSwitcher />),
    },
    {
      path: "/chat",
      element: fillInShell(chatFeature ? <Chat /> : <FourOhFour />),
    },
    {
      path: "/custom-models",
      element: fillInShell(
        customModelsFeature ? <CustomModelList /> : <FourOhFour />
      ),
    },
    {
      path: "/custom-models/:customModelID",
      element: fillInShell(
        customModelsFeature ? <CustomModel /> : <FourOhFour />
      ),
    },
    {
      path: "/sheets",
      element: fillInShell(<SheetList />),
    },
    {
      path: "/sheets/:sheetID",
      element: fillInShell(<Sheet />),
    },
    {
      path: "/orgs",
      element: fillInShell(
        userExists && userExists.data && userExists.data.can_impersonate ? (
          <AllOrgs />
        ) : (
          <FourOhFour />
        )
      ),
    },
    {
      path: "*",
      element: fillInShell(<FourOhFour />),
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
