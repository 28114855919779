import React from "react";
import {
  Button,
  Divider,
  Grid,
  LoadingOverlay,
  Modal,
  Text,
  createStyles,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useAuthInfo } from "@propelauth/react";
import { useTranslation } from "react-i18next";
import { ICustomModel } from "interfaces/custom-models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteFetcher } from "fetchers/fetchers";
import { useDisclosure } from "@mantine/hooks";
import { FaTrash } from "react-icons/fa";

import {
  errorNotification,
  processNotification,
  successNotification,
} from "utils/notifications";
import { IError } from "interfaces/errors";

const useStyles = createStyles((theme) => ({
  tableRow: {
    "&:last-child td": {
      borderBottomLeftRadius: theme.radius.md,
      borderBottomRightRadius: theme.radius.md,
    },
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors.gray[0],
    textAlign: "left",
    padding: theme.spacing.md,
    cursor: "pointer",
    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[1],
    },
  },
}));

interface ICustomModelItemProps {
  customModel: ICustomModel;
}

export const CustomModelItem: React.FC<ICustomModelItemProps> = ({
  customModel,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authInfo = useAuthInfo();
  const queryClient = useQueryClient();
  const { classes } = useStyles();
  const [isDeleteOpened, { open: isDeleteOpen, close: isDeleteClose }] =
    useDisclosure(false);

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    () =>
      deleteFetcher(
        `/api/custom_models/${customModel.id}`,
        authInfo?.accessToken
      ),
    {
      onSuccess: () => {
        isDeleteClose();
        queryClient.invalidateQueries(["/api/custom_models"]);
        successNotification(
          "deleteCustomModel",
          t("The custom model has been deleted.")
        );
      },
      onError: (error: IError) => {
        console.error(error);
        errorNotification("deleteCustomModel");
      },
    }
  );

  return (
    <tr>
      <td
        className={classes.tableRow}
        onClick={() => {
          navigate(`/custom-models/${customModel.id}`);
        }}
      >
        {customModel.name}
      </td>
      <td>
        <Button
          variant="light"
          size="xs"
          p={8}
          m={0}
          radius={"50%"}
          onClick={isDeleteOpen}
          color="red"
          disabled={isLoadingDelete}
        >
          <FaTrash />
        </Button>
        <Modal
          opened={isDeleteOpened}
          onClose={isDeleteClose}
          title={<Text size="lg">{t("Are you sure?")}</Text>}
        >
          <LoadingOverlay visible={isLoadingDelete} />
          <Grid align="center" justify="space-between">
            <Grid.Col span="content">
              {t("Are you sure you want to delete this custom model?")}
            </Grid.Col>
          </Grid>
          <Divider my="sm" color="#E9E9E9" />
          <Grid align="center" justify="space-between">
            <Grid.Col span="content">
              <Button variant="default" onClick={isDeleteClose}>
                {t("No")}
              </Button>
            </Grid.Col>
            <Grid.Col span="content">
              <Button
                autoFocus
                color="red"
                onClick={() => {
                  processNotification(
                    "deleteCustomModel",
                    t("Deleting custom model...")
                  );
                  mutateDelete();
                }}
              >
                {t("Yes")}
              </Button>
            </Grid.Col>
          </Grid>
        </Modal>
      </td>
    </tr>
  );
};
