import React from "react";
import { Grid, Button, Select, Popover, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import { FaEllipsisV } from "react-icons/fa";

export interface IMetricQueryAdvanced {
  fill?: string;
  transform?: string;
}

interface IMetricsFiltersAdvancedProps {
  initialValues: IMetricQueryAdvanced;
  onChange: (value: IMetricQueryAdvanced) => void;
}

export const MetricsFiltersAdvanced: React.FC<IMetricsFiltersAdvancedProps> = ({
  initialValues,
  onChange,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 50em)");
  const [opened, { toggle, close }] = useDisclosure(false);
  const form = useForm({ initialValues });

  return (
    <Popover
      opened={opened}
      onChange={toggle}
      closeOnClickOutside
      closeOnEscape
    >
      <Popover.Target>
        <Button variant="outline" onClick={() => toggle()}>
          <FaEllipsisV />
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <div style={{ width: isMobile ? "300px" : "500px" }}>
          <Text align="left" mb={10}>
            {t("Advanced")}
          </Text>
          <form
            onSubmit={form.onSubmit((values: IMetricQueryAdvanced) => {
              onChange(values);
              close();
            })}
          >
            <Grid justify="left">
              <Grid.Col>
                <Text align="left" mb={10} size="xs">
                  {t("Fill (Interpolate)")}
                </Text>
                <Select
                  data={[
                    { value: "default", label: t("null (Default)") },
                    {
                      value: "ffill",
                      label: t("Previous Value (Null and Zero)"),
                    },
                    {
                      value: "ffillnull",
                      label: t("Previous Value (Null only)"),
                    },
                  ]}
                  {...form.getInputProps("fill")}
                />
              </Grid.Col>
              <Grid.Col>
                <Grid justify="space-between">
                  <Grid.Col span="content">
                    <Button
                      variant="outline"
                      onClick={() => {
                        form.reset();
                        close();
                      }}
                    >
                      {t("Reset")}
                    </Button>
                  </Grid.Col>
                  <Grid.Col span="content">
                    <Button type="submit">{t("Apply")}</Button>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          </form>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};
