import { useAuthInfo, saveOrgSelectionToLocalStorage } from "@propelauth/react";

import LoadingFullPage from "../utils/Loading";
import { Grid, Divider, Text, Button, Card, createStyles } from "@mantine/core";

const useStyles = createStyles((theme, _params) => {
  return {
    padding: {
      paddingTop: theme.spacing.lg,
    },
  };
});

export default function OrgSwitcher() {
  const authInfo = useAuthInfo();
  const { classes } = useStyles();

  if (authInfo.loading) {
    return <LoadingFullPage></LoadingFullPage>;
  }

  const orgs = authInfo.orgHelper?.getOrgs();
  orgs?.sort((org1, org2) => {
    if (org1.orgName > org2.orgName) {
      return 1;
    } else if (org1.orgName < org2.orgName) {
      return -1;
    } else {
      return 0;
    }
  });

  function switchOrg(orgID: string) {
    if (!authInfo.loading) {
      saveOrgSelectionToLocalStorage(orgID);
      const accessToken = authInfo.accessToken;
      let url = "";
      if (window.location.href.includes("http://localhost")) {
        url = "http://localhost:8080/api/orgs/set_active";
      } else {
        url = "/api/orgs/set_active";
      }
      fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ org_id: orgID }),
      })
        .then((data) => {
          console.log(data);
          // reload the page when we switch the org
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <>
      <Grid>
        <Grid.Col span={3}>
          <></>
        </Grid.Col>
        <Grid.Col span={6}>
          <Card radius="md" p="xl" shadow="sm">
            <Text size="xl">Switch Your Org</Text>
            <Divider my="sm" />
            {orgs?.map((org, index) => {
              return (
                <div className={classes.padding} key={index}>
                  <Button
                    onClick={() => {
                      switchOrg(org.orgId);
                    }}
                    type="submit"
                    variant="outline"
                  >
                    {`Switch To ${org.orgName}`}
                  </Button>
                </div>
              );
            })}
          </Card>
        </Grid.Col>
        <Grid.Col span={3}>
          <></>
        </Grid.Col>
      </Grid>
    </>
  );
}
