export enum EIntegrationType {
  SALESFORCE = "SALESFORCE",
  HUBSPOT = "HUBSPOT",
  ACTIVITY = "ACTIVITY",
  WEBHOOK = "WEBHOOK",
  STRIPE = "STRIPE",
  STRIPECSV = "STRIPECSV",
}

export interface IAddIntegration {
  name: string;
  type: EIntegrationType;
  description: string;
}

export interface IIntegration {
  org_id: string;

  integration_id: string;
  integration_type: EIntegrationType;
  integration_name: string;
  integration_description: string;
  integration: any;

  need_to_reauth?: boolean;
  webhook_url?: string;
  created_at: string;
  updated_at: string;
}

export interface IHistoryItem {
  title: string;
  description: string;
  date: string;
}

export interface IStatus {
  is_syncing: boolean;
  last_sync_suceeded: string;
  last_run_timestamp?: number;
}
