import React, { useMemo, useState } from "react";
import queryString from "query-string";
import { debounce } from "lodash";
import {
  createStyles,
  Badge,
  Button,
  Drawer,
  Grid,
  Input,
  Popover,
  Select,
  Text,
  Title,
  LoadingOverlay,
  Pagination,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { TbTemplate } from "react-icons/tb";
import { FaFilter, FaSearch } from "react-icons/fa";

import templateBG from "assets/template_bg.svg";
import { useQuery } from "@tanstack/react-query";
import { IError } from "interfaces/errors";
import { ITemplate, ITemplateData } from "interfaces/templates";
import { getFetcher } from "fetchers/fetchers";
import { useAuthInfo } from "@propelauth/react";
import { MetricsQueryTemplateItem } from "components/metrics-query-template-item";
import { MetricsQueryTemplateItemDescription } from "components/metrics-query-template-item-description";

const useStyles = createStyles((theme) => ({
  categoryItem: {
    cursor: "pointer",
    padding: theme.spacing.xs,
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    borderRadius: theme.radius.sm,
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },
  selectedCategoryItem: {
    backgroundColor: theme.colors.gray[0],
  },
  iconRightTop: {
    position: "absolute",
    top: 10,
    right: 10,
    cursor: "pointer",
  },
}));

export const MetricsQueryTemplate: React.FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const authInfo = useAuthInfo();
  const isMobile = useMediaQuery("(max-width: 50em)");

  const [openedDrawer, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [openedFilters, { toggle: toggleFilters }] = useDisclosure(false);
  const [openDescription, setOpenDescription] = useState<
    ITemplate | undefined
  >();

  const [limit] = useState<number>(12);
  const [offset, setOffset] = useState<number>(0);
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [searchVal, setSearchVal] = useState<string | undefined>();
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [availableCategories, setAvailableCategories] = useState<string[]>([]);

  const {
    data: dataTemplates,
    isLoading: isLoadingTemplates,
    refetch,
  } = useQuery<ITemplateData, IError>(
    ["/api/templates", limit, offset, sortBy, searchVal, selectedCategories],
    () =>
      getFetcher(
        `/api/templates?${queryString.stringify({
          limit,
          offset,
          sort_by: sortBy,
          search: searchVal,
          categories: selectedCategories,
        })}`,
        authInfo?.accessToken
      ),
    {
      onSuccess: (data) => {
        if (availableCategories.length === 0) {
          setAvailableCategories(data.integrations_categories || []);
          setSelectedCategories(data.integrations_categories || []);
        }
      },
    }
  );

  const total = dataTemplates?.total || 0;
  const categories = dataTemplates?.categories || [];

  const debounceSetSearchVal = useMemo(() => debounce(setSearchVal, 1000), []);
  const totalPages = Math.round(total / limit);

  return (
    <>
      <Button variant="outline" onClick={() => toggleDrawer()}>
        <TbTemplate />
      </Button>
      <Drawer
        size={isMobile ? "xl" : "70%"}
        position="right"
        opened={openedDrawer}
        onClose={() => {
          closeDrawer();
          setOpenDescription(undefined);
          refetch();
        }}
        title={
          <Title weight={400} order={4}>
            {t("QUERY TEMPLATES")}
          </Title>
        }
      >
        <LoadingOverlay visible={isLoadingTemplates} />
        <Grid mt={10}>
          <Grid.Col xs={12} md={6}>
            <Popover
              opened={openedFilters}
              onChange={toggleFilters}
              closeOnClickOutside
              closeOnEscape
              position="bottom-end"
              width={200}
            >
              <Popover.Target>
                <Button
                  onClick={() => toggleFilters()}
                  leftIcon={<FaFilter />}
                  variant="outline"
                  mr={15}
                >
                  {t("Add Filter")}
                </Button>
              </Popover.Target>
              <Popover.Dropdown>
                <Grid>
                  {categories.map((category: string) => (
                    <Grid.Col
                      key={category}
                      mt={5}
                      mb={5}
                      span={12}
                      style={{ cursor: "pointer" }}
                      className={[
                        classes.categoryItem,
                        selectedCategories.includes(category) &&
                          classes.selectedCategoryItem,
                      ].join(" ")}
                      onClick={() => {
                        setOffset(0);
                        if (selectedCategories.includes(category)) {
                          setSelectedCategories(
                            selectedCategories.filter(
                              (item) => item !== category
                            )
                          );
                          return;
                        }
                        setSelectedCategories([
                          ...selectedCategories,
                          category,
                        ]);
                      }}
                    >
                      <Text
                        color={
                          selectedCategories.includes(category)
                            ? "blue"
                            : "gray"
                        }
                      >
                        {category}
                      </Text>
                    </Grid.Col>
                  ))}
                </Grid>
              </Popover.Dropdown>
            </Popover>
          </Grid.Col>
          <Grid.Col xs={12} md={6}>
            <Grid>
              <Grid.Col xs={12} md={4}>
                <Select
                  value={sortBy}
                  onChange={setSortBy}
                  clearable
                  data={[
                    { value: "name", label: t("Name") },
                    { value: "-created_at", label: t("Date") },
                    { value: "-is_favorite", label: t("Favorite") },
                  ]}
                  placeholder={t("Sort By")}
                />
              </Grid.Col>
              <Grid.Col xs={12} md={8}>
                <Input
                  icon={<FaSearch />}
                  placeholder={t("Search")}
                  defaultValue={searchVal}
                  onChange={(event) => {
                    debounceSetSearchVal(event.currentTarget.value);
                  }}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
        <Grid
          align="center"
          mt={10}
          ml={0}
          style={{
            backgroundImage: `url(${templateBG})`,
            backgroundSize: "cover",
            width: "100%",
            height: 150,
          }}
        >
          <Grid.Col span={12}>
            <Title weight={400} order={4} ml={10} mt={5}>
              {t("Wanna track your trends?")}
            </Title>
            <Title color="blue" weight={400} order={5} ml={10} mt={5}>
              {t("Check these templates")}
            </Title>
            <Grid ml={3} mt={5}>
              <Grid.Col span={12}>
                {categories.map((category: string) => (
                  <Badge
                    key={category}
                    mr={5}
                    style={{ cursor: "pointer" }}
                    radius={3}
                    variant="outline"
                    color={
                      selectedCategories.includes(category) ? "blue" : "gray"
                    }
                    onClick={() => {
                      setOffset(0);
                      if (selectedCategories.includes(category)) {
                        setSelectedCategories(
                          selectedCategories.filter((item) => item !== category)
                        );
                        return;
                      }
                      setOpenDescription(undefined);
                      setSelectedCategories([...selectedCategories, category]);
                    }}
                  >
                    {category}
                  </Badge>
                ))}
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
        {!openDescription ? (
          <>
            <Grid mt={15} align="stretch">
              {(dataTemplates?.results || []).map((item: ITemplate) => (
                <Grid.Col sm={12} md={6} xl={3} key={item.id}>
                  <MetricsQueryTemplateItem
                    key={item.id}
                    template={item}
                    onOpenDescription={() => setOpenDescription(item)}
                  />
                </Grid.Col>
              ))}
              {!isLoadingTemplates &&
                (dataTemplates?.results || []).length === 0 && (
                  <Grid.Col span={12}>
                    <Text align="center" color="gray" mt={20}>
                      {t("No templates found")}
                    </Text>
                  </Grid.Col>
                )}
            </Grid>
            <Grid mt={15} mb={10} justify="end">
              <Grid.Col span="content">
                {total > 0 && (
                  <Pagination
                    total={totalPages ? totalPages : 1}
                    value={Math.floor(offset / limit) + 1}
                    onChange={(page: number) => {
                      setOffset((page - 1) * limit);
                    }}
                  />
                )}
              </Grid.Col>
            </Grid>
          </>
        ) : (
          <MetricsQueryTemplateItemDescription
            template={openDescription}
            disabled={
              (openDescription.categories || []).filter((x) =>
                availableCategories.includes(x)
              ).length === 0
            }
            onCloseDescription={() => setOpenDescription(undefined)}
            onUseTemplate={() => closeDrawer()}
          />
        )}
      </Drawer>
    </>
  );
};
