import React from "react";
import {
  Button,
  Divider,
  Grid,
  LoadingOverlay,
  Modal,
  Text,
  createStyles,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useAuthInfo } from "@propelauth/react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteFetcher } from "fetchers/fetchers";
import { useDisclosure } from "@mantine/hooks";
import { FaTrash } from "react-icons/fa";
import { ISheet } from "interfaces/sheets";
import {
  errorNotification,
  processNotification,
  successNotification,
} from "utils/notifications";

const useStyles = createStyles((theme) => ({
  rowName: {
    textAlign: "left",
    cursor: "pointer",
  },
  rowActions: {
    textAlign: "right",
  },
}));

interface ISheetItemProps {
  sheet: ISheet;
}

export const SheetItem: React.FC<ISheetItemProps> = ({ sheet }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authInfo = useAuthInfo();
  const queryClient = useQueryClient();
  const { classes } = useStyles();
  const [isDeleteOpened, { open: isDeleteOpen, close: isDeleteClose }] =
    useDisclosure(false);

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    () => deleteFetcher(`/api/sheets/${sheet.id}`, authInfo?.accessToken),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["/api/sheets"]);
        successNotification("deleteSheet", t("The sheet has been deleted."));
        isDeleteClose();
      },
      onError: () => {
        errorNotification("deleteSheet");
      },
    }
  );

  return (
    <tr>
      <td
        className={classes.rowName}
        onClick={() => {
          navigate(`/sheets/${sheet.id}`);
        }}
      >
        {sheet.name}
      </td>
      <td className={classes.rowActions}>
        <Button
          variant="light"
          size="xs"
          p={8}
          m={0}
          mr={10}
          radius={"50%"}
          onClick={isDeleteOpen}
          color="red"
          disabled={isLoadingDelete}
        >
          <FaTrash />
        </Button>
        <Modal
          opened={isDeleteOpened}
          onClose={isDeleteClose}
          title={<Text size="lg">{t("Are you sure?")}</Text>}
        >
          <LoadingOverlay visible={isLoadingDelete} />
          <Grid align="center" justify="space-between">
            <Grid.Col span="content">
              {t("Are you sure you want to delete this sheet?")}
            </Grid.Col>
          </Grid>
          <Divider my="sm" color="#E9E9E9" />
          <Grid align="center" justify="space-between">
            <Grid.Col span="content">
              <Button variant="default" onClick={isDeleteClose}>
                {t("No")}
              </Button>
            </Grid.Col>
            <Grid.Col span="content">
              <Button
                autoFocus
                color="red"
                onClick={() => {
                  processNotification("deleteSheet", t("Deleting sheet..."));
                  mutateDelete();
                }}
              >
                {t("Yes")}
              </Button>
            </Grid.Col>
          </Grid>
        </Modal>
      </td>
    </tr>
  );
};
