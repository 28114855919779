import React, { useState } from "react";
import {
  Text,
  Grid,
  Card,
  Button,
  Divider,
  Modal,
  Pagination,
  Title,
  LoadingOverlay,
  Loader,
  Space,
  Center,
  Group,
} from "@mantine/core";
import { loadOrgSelectionFromLocalStorage } from "@propelauth/react";
import { format, fromUnixTime } from "date-fns";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { FaSalesforce, FaHubspot, FaTrash, FaFileCsv } from "react-icons/fa";
import { GrStripe } from "react-icons/gr";
import { MdEmail } from "react-icons/md";
import { TbWebhook } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAuthInfo } from "@propelauth/react";
import Nango from "@nangohq/frontend";

import googleLogo from "assets/google.svg";
import { getEnvironment } from "utils/env";
import {
  EIntegrationType,
  IIntegration,
  IHistoryItem,
  IStatus,
} from "interfaces/integrations";
import { deleteFetcher, getFetcher, patchFetcher } from "fetchers/fetchers";
import { IError } from "interfaces/errors";
import {
  errorNotification,
  processNotification,
  successNotification,
} from "utils/notifications";
import { WebhookForm } from "../available-integration-card/webhook-form";

interface IViewIntegrationCardProps {
  integration: IIntegration;
  reSyncFeature?: boolean;
}

export const IntegratedIntegrationCard: React.FC<IViewIntegrationCardProps> = ({
  integration,
  reSyncFeature,
}) => {
  const { t } = useTranslation();
  const [activePage, setPage] = useState<number>(1);
  const [editOpened, { open: editOpen, close: editClose }] =
    useDisclosure(false);
  const [historyOpened, { close: historyClose }] = useDisclosure(false);
  const [isDeleteOpened, { open: isDeleteOpen, close: isDeleteClose }] =
    useDisclosure(false);
  const [
    isActiviteReAuth,
    { open: isActiviteReAuthOpen, close: isActiviteReAuthClose },
  ] = useDisclosure(false);
  const isMobile = useMediaQuery("(max-width: 50em)");
  const authInfo = useAuthInfo();
  const queryClient = useQueryClient();

  const { data: status, isError } = useQuery<IStatus, IError>(
    [
      "/api/integrations/organization/status",
      integration.integration_type,
      integration.integration_id,
    ],
    () =>
      getFetcher(
        `/api/integrations/organization/status/${integration.integration_type}/${integration.integration_id}`,
        authInfo?.accessToken
      ),
    {
      retry: false,
      refetchOnWindowFocus: true,
      enabled: integration.integration_type !== EIntegrationType.WEBHOOK,
    }
  );

  const { mutate: mutateReAuth, isLoading: isLoadingReAuth } = useMutation(
    (payload: any) =>
      patchFetcher(
        `/api/integrations/${integration.integration_type.toLowerCase()}/re-auth/${
          integration.integration_id
        }`,
        authInfo?.accessToken,
        payload
      ),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(["/api/integrations/organization"]);
        if (response.url) {
          window.location.href = response.url;
        }
      },
    }
  );

  const { mutate: mutateReSync, isLoading: isLoadingReSync } = useMutation(
    (payload: any) =>
      patchFetcher(
        `/api/integrations/${integration.integration_type.toLowerCase()}/re-sync/${
          integration.integration_id
        }`,
        authInfo?.accessToken,
        payload
      ),
    {
      onSuccess: () => {
        successNotification(
          "reSyncIntegration",
          t("Re sync of the integration has been scheduled.")
        );
      },
      onError: (error: IError) => {
        console.error(error);
        errorNotification("reSyncIntegration");
      },
    }
  );

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    () =>
      deleteFetcher(
        `/api/integrations/${integration.integration_type.toLowerCase()}/${
          integration.integration_id
        }`,
        authInfo?.accessToken
      ),
    {
      onSuccess: () => {
        isDeleteClose();
        queryClient.invalidateQueries(["/api/integrations/organization"]);
        successNotification(
          "deleteIntegration",
          t("The integration has been deleted.")
        );
      },
      onError: (error: IError) => {
        console.error(error);
        errorNotification("deleteIntegration");
      },
    }
  );

  const historyItems: IHistoryItem[] = [
    {
      title: "Select This",
      description: "2,075 New Record Added",
      date: "01/18 - 11:45",
    },
    {
      title: "Select This",
      description: "2,075 New Record Added",
      date: "01/18 - 11:45",
    },
    {
      title: "Select This",
      description: "2,075 New Record Added",
      date: "01/18 - 11:45",
    },
  ];

  const lastSynced = status?.last_run_timestamp
    ? fromUnixTime(status?.last_run_timestamp)
    : undefined;

  const syncFailed = status?.last_sync_suceeded === "failed" || isError;
  const isSyncing = status?.is_syncing;

  return (
    <Card shadow="sm" padding="lg" radius="md" h="100%" withBorder>
      <LoadingOverlay visible={isLoadingReAuth || isLoadingReSync} />
      <Button
        variant="light"
        size="xs"
        p={8}
        m={0}
        radius={"50%"}
        onClick={isDeleteOpen}
        color="red"
        style={{ position: "absolute", top: 10, right: 10 }}
        disabled={isLoadingDelete}
      >
        <FaTrash />
      </Button>
      <Grid mb={5} align="center">
        <Grid.Col span="content">
          {integration.integration_type === EIntegrationType.SALESFORCE && (
            <FaSalesforce size={50} />
          )}
          {integration.integration_type === EIntegrationType.HUBSPOT && (
            <FaHubspot size={50} />
          )}
          {integration.integration_type === EIntegrationType.ACTIVITY && (
            <MdEmail size={50} />
          )}
          {integration.integration_type === EIntegrationType.STRIPE && (
            <GrStripe size={50} />
          )}
          {integration.integration_type === EIntegrationType.STRIPECSV && (
            <FaFileCsv size={50} />
          )}
          {integration.integration_type === EIntegrationType.WEBHOOK && (
            <TbWebhook size={50} />
          )}
        </Grid.Col>
        <Grid.Col span={6}>
          <Text align="left">{integration.integration_name}</Text>
        </Grid.Col>
      </Grid>

      <Text align="left" size="sm" color="dimmed" lineClamp={2}>
        {integration.integration_description}
      </Text>

      <Divider my="sm" color="#E9E9E9" />
      {EIntegrationType.WEBHOOK === integration.integration_type && (
        <Group>
          <Text p={0} size="sm" color="dimmed">
            {t("URL:")}
          </Text>
          <Text p={0} size="sm">
            {integration.webhook_url}
          </Text>
        </Group>
      )}
      {lastSynced && (
        <Group>
          <Text p={0} size="sm" color="dimmed">
            {t("Last sync:")}
          </Text>
          <Text p={0} size="sm">
            {format(lastSynced, "hh:mm a MM/dd")}
          </Text>
        </Group>
      )}
      {(isSyncing || syncFailed) && (
        <Group>
          <Text p={0} size="sm" color="dimmed">
            {t("Sync status:")}
          </Text>
          <Text p={0} size="sm" color={syncFailed ? "red" : "blue"}>
            {syncFailed ? (
              t("Failed to sync")
            ) : (
              <Center>
                {t("In Progress")}
                <Space w="xs" />
                <Loader size="xs" />
              </Center>
            )}
          </Text>
        </Group>
      )}

      {/* <Grid align="left">
        <Grid.Col span="content">
          {100 && (
            <Text align="left" size="sm">
              {100} {t("Record added")}
            </Text>
          )}
        </Grid.Col>
      </Grid> */}

      <Grid mt={5} justify="space-between">
        {(integration.integration_type === EIntegrationType.SALESFORCE ||
          integration.integration_type === EIntegrationType.HUBSPOT ||
          integration.integration_type === EIntegrationType.ACTIVITY) && (
          <Grid.Col span="content">
            <Button
              variant="light"
              radius="md"
              onClick={() => {
                if (
                  integration.integration_type === EIntegrationType.ACTIVITY
                ) {
                  isActiviteReAuthOpen();
                } else {
                  mutateReAuth({});
                }
              }}
            >
              {t("Re Auth")}
            </Button>
          </Grid.Col>
        )}
        {![EIntegrationType.WEBHOOK, EIntegrationType.STRIPECSV].includes(
          integration.integration_type
        ) && (
          <Grid.Col span="content">
            <Button
              variant="light"
              color="yellow"
              radius="md"
              onClick={() => {
                mutateReSync({});
              }}
            >
              {t("Re Sync")}
            </Button>
          </Grid.Col>
        )}
        {EIntegrationType.WEBHOOK === integration.integration_type && (
          <Grid.Col span="content">
            <Button
              variant="light"
              color="blue"
              radius="md"
              onClick={() => {
                editOpen();
              }}
            >
              {t("Edit")}
            </Button>
          </Grid.Col>
        )}
        {/* <Grid.Col span="content">
          <Button variant="light" radius="md" onClick={historyOpen} disabled>
            {t("Sync History")}
          </Button>
        </Grid.Col> */}
      </Grid>

      <Modal
        opened={isDeleteOpened}
        onClose={isDeleteClose}
        title={<Text size="lg">{t("Are you sure?")}</Text>}
      >
        <LoadingOverlay visible={isLoadingDelete} />
        <Grid align="center" justify="space-between">
          <Grid.Col span="content">
            {t("Are you sure you want to delete this integration?")}
          </Grid.Col>
        </Grid>
        <Divider my="sm" color="#E9E9E9" />
        <Grid align="center" justify="space-between">
          <Grid.Col span="content">
            <Button variant="default" onClick={isDeleteClose}>
              {t("No")}
            </Button>
          </Grid.Col>
          <Grid.Col span="content">
            <Button
              autoFocus
              color="red"
              onClick={() => {
                processNotification(
                  "deleteIntegration",
                  t("Deleting integration...")
                );
                mutateDelete();
              }}
            >
              {t("Yes")}
            </Button>
          </Grid.Col>
        </Grid>
      </Modal>

      <Modal
        opened={isActiviteReAuth}
        onClose={isActiviteReAuthClose}
        fullScreen={isMobile}
        title={t("Re Auth Activity Capture")}
      >
        <Grid>
          <Grid.Col span="content">
            <Button
              variant="default"
              leftIcon={<img src={googleLogo} width={13} alt="G" />}
              onClick={async () => {
                const orgId = loadOrgSelectionFromLocalStorage();
                const encoded = encodeURIComponent(
                  window.btoa(
                    unescape(
                      encodeURIComponent(
                        crypto.randomUUID() + orgId + getEnvironment()
                      )
                    )
                  )
                );
                const nango = new Nango({
                  publicKey: "f74c2661-1bfe-463b-99c2-ea9907062376",
                });
                const result = await nango.auth(
                  "Google-Bearweb-Activity",
                  encoded
                );
                console.log(result);
                mutateReAuth({ encoded_id: encoded });
                isActiviteReAuthClose();
              }}
            >
              {t("Sign In with Google")}
            </Button>
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={12}>
            <Text size="xs">
              {t(
                "Bearworks use and transfer of information received from Google APIs to any other app will adhere to Google API Services User Data Policy, including the Limited Use requirements."
              )}
            </Text>
          </Grid.Col>
        </Grid>
      </Modal>

      <Modal
        opened={historyOpened}
        onClose={historyClose}
        fullScreen={isMobile}
        title={
          <Grid align="center">
            <Grid.Col span="auto">
              <Grid>
                <Grid.Col pt={0} pb={0} span={12}>
                  <Title align="left" order={4}>
                    {t("Sync History")}
                  </Title>
                </Grid.Col>
                <Grid.Col pt={0} pb={0} span={12}>
                  <Text align="left" size="xs" color="dimmed">
                    {t("Last Sync:")} {integration.created_at}
                  </Text>
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col span="content" mr={10}>
              <Button>{t("Sync Now")}</Button>
            </Grid.Col>
          </Grid>
        }
      >
        <Divider my="sm" color="#E9E9E9" />
        {historyItems.map((item, index) => (
          <div key={index}>
            <Grid align="center" justify="space-between">
              <Grid.Col span="auto">
                <Grid>
                  <Grid.Col span={12}>
                    <Text align="left" size="xs">
                      {item.title}
                    </Text>
                  </Grid.Col>
                  <Grid.Col pt={0} span={12}>
                    <Text align="left" size="xs" color="dimmed">
                      {item.description}
                    </Text>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
              <Grid.Col span="content">
                <Text align="left" size="xs" color="dimmed">
                  {item.date}
                </Text>
              </Grid.Col>
            </Grid>
            <Divider my="sm" color="#E9E9E9" />
          </div>
        ))}
        <Grid justify="end" mt={15}>
          <Grid.Col span="content">
            <Pagination
              size="sm"
              value={activePage}
              onChange={setPage}
              total={10}
            />
          </Grid.Col>
        </Grid>
      </Modal>

      <Modal
        fullScreen={isMobile}
        opened={editOpened}
        onClose={editClose}
        withCloseButton={false}
      >
        {integration.integration_type === EIntegrationType.WEBHOOK && (
          <WebhookForm
            opened={editOpened}
            onClose={editClose}
            editing={true}
            initialValues={{
              name: integration.integration_name,
              webhook_url: integration.webhook_url,
              id: integration.integration_id,
            }}
          />
        )}
      </Modal>
    </Card>
  );
};
