import React, { useState, useEffect } from "react";
import {
  Text,
  Button,
  Table,
  createStyles,
  Grid,
  LoadingOverlay,
  Group,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useAuthInfo } from "@propelauth/react";
import { logPageView } from "utils/segment";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { ICustomModel } from "interfaces/custom-models";
import { getFetcher } from "fetchers/fetchers";
import { useQuery } from "@tanstack/react-query";
import { IError } from "interfaces/errors";
import { PageTitle } from "components/page-title";
import { useTranslation } from "react-i18next";
import { CustomModelItem } from "components/custom-model-item";

const useStyles = createStyles((theme) => ({
  table: {
    borderRadius: theme.radius.md,
    borderCollapse: "separate",
    borderSpacing: "0",
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[3]
    }`,
    boxShadow: theme.shadows.xs,
    marginTop: theme.spacing.lg,
  },
  tableHeader: {
    borderTopLeftRadius: theme.radius.md,
    borderTopRightRadius: theme.radius.md,
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[1],
    fontWeight: 700,
    textAlign: "left",
    padding: theme.spacing.md,
  },
  headerText: {
    fontSize: theme.fontSizes.xs,
  },
}));

export const CustomModelList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authInfo = useAuthInfo();
  const { classes } = useStyles();
  const [sortBy, setSortBy] = useState<string>("name");

  useEffect(() => {
    if (!authInfo.loading) {
      logPageView(
        authInfo.user?.email,
        authInfo.user?.firstName + " " + authInfo.user?.lastName
      );
    }
    // eslint-disable-next-line
  }, [authInfo.loading]);

  const { data: modelListData, isLoading } = useQuery<any, IError>(
    ["/api/custom_models", sortBy],
    () =>
      getFetcher(`/api/custom_models?sort_by=${sortBy}`, authInfo?.accessToken)
  );

  return (
    <Grid p={24}>
      <Grid.Col>
        <PageTitle
          title={t("Custom Models")}
          actions={[
            <Button
              onClick={() => {
                navigate(`/custom-models/new`);
              }}
            >
              {t("Create New Model")}
            </Button>,
          ]}
        />
        <LoadingOverlay visible={isLoading} />
        {(modelListData || []).length > 0 && (
          <Table className={classes.table}>
            <thead>
              <tr>
                <th style={{ width: "90%" }} className={classes.tableHeader}>
                  <Group>
                    <Text className={classes.headerText}>
                      {t("Custom Models")}
                    </Text>
                    {sortBy === "name" && (
                      <AiOutlineArrowDown
                        cursor={"pointer"}
                        onClick={() => {
                          setSortBy("-name");
                        }}
                      />
                    )}
                    {sortBy === "-name" && (
                      <AiOutlineArrowUp
                        cursor={"pointer"}
                        onClick={() => {
                          setSortBy("name");
                        }}
                      />
                    )}
                  </Group>
                </th>
                <th className={classes.tableHeader}></th>
              </tr>
            </thead>
            <tbody>
              {(modelListData || []).map((value: ICustomModel) => (
                <CustomModelItem key={value.id} customModel={value} />
              ))}
            </tbody>
          </Table>
        )}
      </Grid.Col>
    </Grid>
  );
};
