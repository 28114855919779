import React, { useState, useEffect } from "react";
import { Grid, Input, Button, Select, createStyles } from "@mantine/core";
import { dimensionType } from "./types";
import { FiTrash } from "react-icons/fi";

type removeDimensioneFunction = () => void;
type addDimensionFunction = (dimension: dimensionType) => void;

interface customDimensionProps {
  name: string;
  type?: string;
  sql?: string;
  dimension: dimensionType;
  removeDimension: removeDimensioneFunction;
  addDimension: addDimensionFunction;
}

const DIMENSION_TYPES = ["string", "time", "boolean", "number", "geo"];

const useStyles = createStyles((theme) => ({
  deleteButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.colors.red[6],
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    fontSize: theme.fontSizes.sm,
  },
  trashIcon: {
    fontSize: theme.fontSizes.lg,
  },
  deleteButtonCol: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    marginBottom: "6px",
  },
}));

export function CustomDimension(props: customDimensionProps) {
  const [dimensionType, setDimensionType] = useState<string>(
    props?.dimension?.type || ""
  );
  const [sql, setSQL] = useState<string>(props?.dimension?.sql || "");
  const [name, setName] = useState<string>(props?.dimension?.name || "");

  const { classes } = useStyles();

  useEffect(() => {
    if (
      dimensionType !== props?.dimension?.type ||
      name !== props?.dimension?.name ||
      sql !== props?.dimension?.sql
    ) {
      props.addDimension({
        name: name,
        type: dimensionType,
        sql: sql,
      });
    }
    // eslint-disable-next-line
  }, [dimensionType, sql, name, props]);

  useEffect(() => {
    if (dimensionType !== "" && dimensionType && sql !== "" && name !== "") {
      if (
        dimensionType !== props?.type ||
        name !== props?.name ||
        sql !== props?.sql
      ) {
        setDimensionType(props.type || "");
        setSQL(props.sql || "");
        setName(props.name || "");
      }
    }
    // eslint-disable-next-line
  }, [props]);

  return (
    <>
      <Grid
        grow
        gutter={"xs"}
        style={{
          textAlign: "left",
          fontWeight: 500,
        }}
        align="flex-end"
      >
        <Grid.Col span={4}>
          <Input.Wrapper
            id="dimname"
            label="Dimension Name"
            error=""
            withAsterisk
          >
            <Input
              value={name}
              placeholder="What is the name of the dimension?"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setName(e.target.value);
              }}
              required
            />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={4}>
          <Input.Wrapper id="modelname" label="SQL" error="" withAsterisk>
            <Input
              value={sql}
              placeholder="What is your sql?"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSQL(e.target.value);
              }}
              required
            />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={3}>
          <Input.Wrapper id="modeltype" label="Type" error="" withAsterisk>
            <Select
              placeholder="Pick one"
              data={DIMENSION_TYPES}
              value={dimensionType}
              onChange={(value: string) => {
                setDimensionType(value);
              }}
              limit={50}
              required
              searchable
              hoverOnSearchChange
            />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={1} className={classes.deleteButtonCol}>
          <Button
            className={classes.deleteButton}
            onClick={() => {
              props.removeDimension();
            }}
            variant="subtle"
            color="red"
            compact
          >
            <FiTrash className={classes.trashIcon} />
            Delete
          </Button>
        </Grid.Col>
      </Grid>
    </>
  );
}
