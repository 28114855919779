import React from "react";
import { Grid, Text, Title } from "@mantine/core";

interface IEmptyStateProps {
  header: string;
  description: string;
  illustration: React.ReactNode;
  primaryAction?: React.ReactNode;
}

export const EmptyState: React.FC<IEmptyStateProps> = ({
  header,
  description,
  illustration,
  primaryAction,
}) => {
  return (
    <Grid justify="center" mt={"15%"}>
      <Grid.Col span={12}>{illustration}</Grid.Col>
      <Grid.Col span={12}>
        <Title align="center" order={2} mb={10} size="md" transform="uppercase">
          {header}
        </Title>
        <Text align="center" color="dimmed" size="md">
          {description}
        </Text>
      </Grid.Col>
      {primaryAction && <Grid.Col span={12}>{primaryAction}</Grid.Col>}
    </Grid>
  );
};
