import {
  saveOrgSelectionToLocalStorage,
  OrgMemberInfo,
} from "@propelauth/react";
import {
  UseAuthInfoLoggedInProps,
  UseAuthInfoNotLoggedInProps,
} from "@propelauth/react/src/useAuthInfo";
import axios from "axios";

export async function getCurrentOrg(
  selectedOrg: OrgMemberInfo | null | undefined,
  authInfo: UseAuthInfoLoggedInProps | UseAuthInfoNotLoggedInProps,
  accessToken: string | null
) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  if (urlSearchParams.get("org_id")) {
    let tempOrgID = urlSearchParams.get("org_id");
    let url = "";
    if (window.location.href.includes("http://localhost")) {
      url = "http://localhost:8080/api/orgs/org_info";
    } else {
      url = "/api/orgs/org_info";
    }
    let orgInfo: OrgMemberInfo | null = null;
    let orgInfoExists = false;
    await Promise.resolve(
      axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "Org-ID": tempOrgID,
        },
      })
    )
      .then((value) => {
        if (value.status === 200) {
          orgInfoExists = true;
          orgInfo = value.data;
        }
      })
      .catch((reason) => {});
    if (orgInfoExists && orgInfo) {
      saveOrgSelectionToLocalStorage(orgInfo["orgId"]);
      return orgInfo;
    }
  }
  if (!selectedOrg) {
    if (authInfo.orgHelper?.getOrgs()) {
      const orgs = authInfo.orgHelper?.getOrgs();
      orgs.sort((org1, org2) => {
        if (org1.orgName > org2.orgName) {
          return 1;
        } else if (org1.orgName < org2.orgName) {
          return -1;
        } else {
          return 0;
        }
      });
      if (orgs.length !== 0) {
        saveOrgSelectionToLocalStorage(orgs[0].orgId);
        return orgs[0];
      }
      return undefined;
    }
  }
  return selectedOrg;
}
