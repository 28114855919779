import React from "react";
import { Text, Modal, Paper, Button } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

interface ILoadingModalProps {
  opened?: boolean;
  onClose: () => void;
}

export const NewIntegrationLoading: React.FC<ILoadingModalProps> = ({
  opened,
  onClose,
}) => {
  const isMobile = useMediaQuery("(max-width: 50em)");

  return (
    <Modal
      fullScreen={isMobile}
      opened={opened || false}
      withCloseButton={true}
      onClose={() => {
        onClose();
      }}
    >
      <>
        <Paper p="md">
          <Text size={"xl"}>You have created your first integration!</Text>
          <Text>
            It will take some time for it to finish syncing all of your data. We
            will send you an email when it's finished!
          </Text>
          <Text ta="center">
            <Button onClick={onClose}>Close</Button>
          </Text>
        </Paper>
      </>
    </Modal>
  );
};
