import React, { useState, useEffect } from "react";
import {
  Text,
  Input,
  Button,
  Paper,
  createStyles,
  Select,
  Divider,
  Tooltip,
  ThemeIcon,
  Textarea,
} from "@mantine/core";
import { dimensionType, measureType } from "./types";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { CustomMeasure } from "./CustomMeasure";
import { CustomDimension } from "./CustomDimension";
import { useAuthInfo, useActiveOrg } from "@propelauth/react";
import { getCurrentOrg } from "../utils/org";
import { notifications } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { logPageView } from "../utils/segment";
import { BsInfoLg } from "react-icons/bs";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import { PageTitle } from "components/page-title";

const DATABASE_MODELS = [
  "+ Custom SQL",
  "hubspotcampaignmodel",
  "hubspotcompanymodel",
  "hubspotcontactmodel",
  "hubspotdealmodel",
  "hubspotdealpipelinemodel",
  "hubspotdealpipelinestagemodel",
  "hubspotengagementmodel",
  "hubspotengagementsnotemodel",
  "hubspotlineitemmodel",
  "hubspotownermodel",
  "hubspotproductmodel",
  "salesforceaccountmodel",
  "salesforceemailmessagemodel",
  "salesforceleadmodel",
  "salesforceobjectterritory2associationmodel",
  "salesforceopportunitycontactrolemodel",
  "salesforceopportunityfeedmodel",
  "salesforceopportunityhistorymodel",
  "salesforceopportunitylineitemmodel",
  "salesforceopportunitymodel",
  "salesforceopportunitypartnermodel",
  "salesforceopportunitysharemodel",
  "salesforceopportunitystagemodel",
  "salesforceproduct2model",
  "salesforceterritory2model",
  "salesforceusermodel",
  "salesforceuserterritory2associationmodel",
  "calendarevent",
  "contact",
  "csvmodel",
  "email",
  "emailthread",
];

const useStyles = createStyles((theme) => ({
  inputField: {
    marginBottom: theme.spacing.md,
    width: "33%",
  },
  submitButton: {
    textAlign: "right",
  },
  paper: {
    marginTop: theme.spacing.lg,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  marginBottomConditionally: {
    marginBottom: theme.spacing.md,
  },
}));

interface customQueryProps {}

export default function CustomModel(props: customQueryProps) {
  const { customModelID } = useParams();
  const navigate = useNavigate();
  const newModel = customModelID === "new";
  const [name, setName] = useState<string | undefined>();
  const [model, setModel] = useState<string | undefined>();
  const [measures, setMeasures] = useState<measureType[]>([]);
  const [dimensions, setDimensions] = useState<dimensionType[]>([]);
  const [sql, setSQL] = useState<string | undefined>();
  const selectedOrg = useActiveOrg();
  const authInfo = useAuthInfo();
  const { classes } = useStyles();
  const { t } = useTranslation();
  let accessToken: string | null = "";
  if (!authInfo.loading) {
    accessToken = authInfo.accessToken;
  }
  const [orgID, setOrgID] = useState<string>("");
  const [measuresCollapsed, setMeasuresCollapsed] = useState(false);
  const [dimensionsCollapsed, setDimensionsCollapsed] = useState(false);

  function isCustomSQLModel(sql: string, model: string) {
    return sql.length > 0 && model.length === 0;
  }

  const [showCustomSQL, setShowCustomSQL] = useState<boolean>(
    isCustomSQLModel(sql || "", model || "")
  );

  useEffect(() => {
    if (orgID && orgID.length > 0) {
      return;
    }
    if (!authInfo.loading) {
      getCurrentOrg(selectedOrg, authInfo, accessToken).then((value) => {
        if (value) {
          let localOrgID = value.orgId;
          if (!localOrgID) {
            setOrgID("");
          } else {
            setOrgID(localOrgID);
          }
        }
      });
    }
  }, [authInfo, accessToken, orgID, selectedOrg]);

  useEffect(() => {
    if (!authInfo.loading) {
      logPageView(
        authInfo.user?.email,
        authInfo.user?.firstName + " " + authInfo.user?.lastName
      );
    }
    // eslint-disable-next-line
  }, [authInfo.loading]);

  useEffect(() => {
    if (customModelID === "new") {
      return;
    }
    if (!orgID || orgID.length === 0) {
      return;
    }
    let url = "";
    if (window.location.href.includes("http://localhost")) {
      url = `http://localhost:8080/api/custom_models/${customModelID}`;
    } else {
      url = `/api/custom_models/${customModelID}`;
    }
    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Org-ID": orgID,
      },
    }).then((data) => {
      let jsonPromise = data.json();
      Promise.resolve(jsonPromise).then((jsonResponse) => {
        setModel(jsonResponse.model || "");
        setName(jsonResponse.name);
        setDimensions(jsonResponse.dimensions || []);
        setMeasures(jsonResponse.measures || []);
        setSQL(jsonResponse.sql);
        setShowCustomSQL(
          isCustomSQLModel(jsonResponse.sql || "", jsonResponse.model || "")
        );
      });
    });
    // eslint-disable-next-line
  }, [customModelID, orgID]);

  function disableSubmit() {
    const someEmptyMeasureField = measures.some(
      (measure) => !measure.name || !measure.type || !measure.sql
    );
    const someEmptyDimensionField = dimensions.some(
      (dimension) => !dimension.name || !dimension.type || !dimension.sql
    );
    if (someEmptyMeasureField || someEmptyDimensionField) {
      return true;
    }
    if ((model && model.length > 0) || (sql && sql.length > 0)) {
      if (name && name.length > 0) {
        return false;
      }
    }
    return true;
  }

  function submitHandler() {
    let url = "";
    if (window.location.href.includes("http://localhost")) {
      url = "http://localhost:8080/api/custom_models";
    } else {
      url = "/api/custom_models";
    }
    fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Org-ID": orgID,
      },
      body: JSON.stringify({
        id: customModelID,
        model: model === "+ Custom SQL" ? "" : model,
        name: name,
        measures: measures,
        dimensions: dimensions,
        sql: sql,
      }),
    })
      .then((data) => {
        let jsonPromise = data.json();
        Promise.resolve(jsonPromise).then((jsonResponse) => {
          if (customModelID === "new") {
            navigate(`/custom-models/${jsonResponse.id}`);
            console.log(jsonResponse);
            notifications.show({
              id: "custommodel",
              title: "Created Custom Model",
              message: "We have created this Custom Model",

              autoClose: 2000,
              icon: <IconCheck />,
              loading: false,
              color: "green",
            });
          } else {
            notifications.show({
              id: "custommodel",
              title: "Updated Custom Model",
              message: "We have updated this custom model",

              autoClose: 2000,
              onClose: () => {},
              icon: <IconCheck />,
              loading: false,
              color: "green",
            });
            setModel(jsonResponse.model);
            setName(jsonResponse.name);
            setDimensions(jsonResponse.dimensions || []);
            setMeasures(jsonResponse.measures || []);
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function deleteHandler() {
    if (customModelID === "new") {
      return;
    }
    let url = "";
    if (window.location.href.includes("http://localhost")) {
      url = `http://localhost:8080/api/custom_models/${customModelID}`;
    } else {
      url = `/api/custom_models/${customModelID}`;
    }
    fetch(url, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Org-ID": orgID,
      },
    })
      .then((data) => {
        let jsonPromise = data.json();
        Promise.resolve(jsonPromise).then((jsonResponse) => {
          navigate("/custom-models");
          notifications.show({
            id: "alert",
            title: "Custom Model Deleted",
            message: "We have deleted this custom model",

            autoClose: 2000,
            icon: <IconCheck />,
            loading: false,
            color: "green",
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  console.log("hiya");

  return (
    <>
      <PageTitle title={t("Custom Models")} />

      <form
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          submitHandler();
        }}
      >
        <Paper
          className={classes.paper}
          p="md"
          radius="sm"
          style={{ marginBottom: "20px", textAlign: "left" }}
        >
          <Input.Wrapper
            id="modelname"
            label={t("Model Name")}
            error=""
            withAsterisk
            className={classes.inputField}
          >
            <Input
              value={name}
              placeholder={t("My New Model")}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setName(e.target.value)
              }
            />
          </Input.Wrapper>
          <Select
            label={t("Select a Model")}
            placeholder={t("Pick one")}
            data={DATABASE_MODELS}
            value={model === "" && sql ? "+ Custom SQL" : model}
            onChange={(value: string) => {
              setModel(value);
              setShowCustomSQL(value === "+ Custom SQL");
            }}
            required
            searchable
            className={classes.inputField}
          />
          {showCustomSQL && (
            <Input.Wrapper id="customsql" label="Custom SQL" error="">
              <Textarea
                minRows={10}
                value={sql}
                required
                placeholder="select * from public.email;"
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setSQL(e.target.value)
                }
              />
            </Input.Wrapper>
          )}
        </Paper>
        <Paper p="md" radius="sm" style={{ marginBottom: "20px" }}>
          <div
            className={`${classes.header} ${
              measures.length > 0 && !measuresCollapsed
                ? classes.marginBottomConditionally
                : ""
            }`}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Text style={{ fontWeight: "bold", paddingRight: "8px" }}>
                {t("Measures")} {measures.length > 0 && `(${measures.length})`}
              </Text>
              <Tooltip
                label={t(
                  "Measures are quantitative, numeric values that can be measured and aggregated"
                )}
                position="right"
                withArrow
                color="gray"
                arrowSize={4}
              >
                <ThemeIcon variant="outline" radius="xl" color="dark" size="xs">
                  <BsInfoLg />
                </ThemeIcon>
              </Tooltip>
            </div>
            <div>
              <Button
                onClick={() => {
                  const newMeasures = [...measures];
                  newMeasures.push({ name: "" });
                  setMeasures(newMeasures);
                }}
                variant="outline"
                style={{ marginRight: "8px" }}
              >
                {t("Add Measure")}
              </Button>
              {measuresCollapsed ? (
                <SlArrowUp
                  cursor="pointer"
                  onClick={() => {
                    setMeasuresCollapsed(!measuresCollapsed);
                  }}
                />
              ) : (
                <SlArrowDown
                  cursor="pointer"
                  onClick={() => {
                    setMeasuresCollapsed(!measuresCollapsed);
                  }}
                />
              )}
            </div>
          </div>
          <div
            className={`${
              measures.length > 0 && !measuresCollapsed
                ? classes.marginBottomConditionally
                : ""
            }`}
          >
            {measures.length > 0 && !measuresCollapsed && <Divider />}
          </div>
          {!measuresCollapsed &&
            measures?.map((value, index) => {
              return (
                <CustomMeasure
                  measure={value}
                  name={value.name}
                  type={value?.type}
                  sql={value?.sql}
                  key={`${index}-measure}`}
                  removeMeasure={() => {
                    const newMeasures = [];
                    for (let i = 0; i < measures.length; i++) {
                      if (index !== i) {
                        newMeasures.push(measures[i]);
                      }
                    }
                    setMeasures(newMeasures);
                  }}
                  addMeasure={(measure) => {
                    const newMeasures = [...measures];
                    for (let i = 0; i < newMeasures.length; i++) {
                      if (index === i) {
                        newMeasures[i] = measure;
                      }
                    }
                    setMeasures(newMeasures);
                  }}
                />
              );
            })}
        </Paper>
        <Paper p="md" radius="sm" style={{ marginBottom: "20px" }}>
          <div
            className={`${classes.header} ${
              dimensions.length > 0 && !dimensionsCollapsed
                ? classes.marginBottomConditionally
                : ""
            }`}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Text style={{ fontWeight: "bold", paddingRight: "8px" }}>
                {t("Dimensions")}{" "}
                {dimensions.length > 0 && `(${dimensions.length})`}
              </Text>
              <Tooltip
                label={t(
                  "Dimensions are qualitative values, like the name of a company or a sales rep, which can be used to slice up your data"
                )}
                position="right"
                withArrow
                color="gray"
                arrowSize={4}
              >
                <ThemeIcon variant="outline" radius="xl" color="dark" size="xs">
                  <BsInfoLg />
                </ThemeIcon>
              </Tooltip>
            </div>
            <div>
              <Button
                onClick={() => {
                  const newDimensions = [...dimensions];
                  newDimensions.push({ name: "" });
                  setDimensions(newDimensions);
                }}
                variant="outline"
                style={{ marginRight: "8px" }}
              >
                {t("Add Dimension")}
              </Button>
              {dimensionsCollapsed ? (
                <SlArrowUp
                  cursor="pointer"
                  onClick={() => {
                    setDimensionsCollapsed(!dimensionsCollapsed);
                  }}
                />
              ) : (
                <SlArrowDown
                  cursor="pointer"
                  onClick={() => {
                    setDimensionsCollapsed(!dimensionsCollapsed);
                  }}
                />
              )}
            </div>
          </div>
          <div
            className={`${
              dimensions.length > 0 && !dimensionsCollapsed
                ? classes.marginBottomConditionally
                : ""
            }`}
          >
            {dimensions.length > 0 && !dimensionsCollapsed && <Divider />}
          </div>
          {!dimensionsCollapsed &&
            dimensions?.map((value, index) => {
              return (
                <CustomDimension
                  dimension={value}
                  name={value.name}
                  type={value?.type}
                  sql={value?.sql}
                  key={`${index}-dimension}`}
                  removeDimension={() => {
                    const newDimensions = [];
                    for (let i = 0; i < dimensions.length; i++) {
                      if (index !== i) {
                        newDimensions.push(dimensions[i]);
                      }
                    }
                    setDimensions(newDimensions);
                  }}
                  addDimension={(dimension) => {
                    const newDimensions = [...dimensions];
                    for (let i = 0; i < newDimensions.length; i++) {
                      if (index === i) {
                        newDimensions[i] = dimension;
                      }
                    }
                    setDimensions(newDimensions);
                  }}
                />
              );
            })}
        </Paper>
        <div style={{ textAlign: "right" }}>
          {!newModel && (
            <Button
              color="red"
              onClick={() => {
                deleteHandler();
              }}
              style={{ marginRight: "8px" }}
            >
              {t("Delete")}
            </Button>
          )}
          <Button type="submit" disabled={disableSubmit()}>
            {t("Submit")}
          </Button>{" "}
        </div>
      </form>
    </>
  );
}
