import React, { useEffect, useState } from "react";
import { useAuthInfo, useActiveOrg } from "@propelauth/react";

import { TextInput, Button, Loader, createStyles } from "@mantine/core";
import { getCurrentOrg } from "../utils/org";

interface Message {
  content: string;
  role: string;
}

const useStyles = createStyles((theme, _params) => {
  return {
    fredMessage: {
      whiteSpace: "pre-wrap",
      textAlign: "left",
      backgroundColor: theme.colors.blue[1],
      width: "100%",
    },
    youMessage: {
      whiteSpace: "pre-wrap",
      textAlign: "right",
      width: "100%",
    },
  };
});

export default function Chat() {
  const authInfo = useAuthInfo();
  const { classes } = useStyles();
  let accessToken: string | null = "";
  if (!authInfo.loading) {
    accessToken = authInfo.accessToken;
  }
  const selectedOrg = useActiveOrg();

  const [currentMessage, setCurrentMessage] = useState<string>("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [gotMessages, setGotMessages] = useState(false);
  const [orgID, setOrgID] = useState<string>("");
  const [disableSubmit, setDisableSubmit] = useState(true);
  useEffect(() => {
    if (orgID && orgID.length > 0) {
      return;
    }
    if (!authInfo.loading) {
      getCurrentOrg(selectedOrg, authInfo, accessToken).then((value) => {
        if (value) {
          let localOrgID = value.orgId;
          if (!localOrgID) {
            setOrgID("");
          } else {
            setOrgID(localOrgID);
          }
        }
      });
    }
  }, [authInfo, accessToken, orgID, selectedOrg]);

  useEffect(() => {
    if (gotMessages) {
      return;
    }

    if (accessToken && orgID) {
      let url = "";
      if (window.location.href.includes("http://localhost")) {
        url = "http://localhost:8080/api/chat";
      } else {
        url = "/api/chat";
      }
      fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Org-ID": orgID,
        },
      }).then((data) => {
        console.log(data);
        setGotMessages(true);
        setDisableSubmit(false);
        let jsonPromise = data.json();
        Promise.resolve(jsonPromise).then((jsonResponse) => {
          let newMessages = [];
          newMessages.push(jsonResponse);
          setMessages(newMessages);
          setDisableSubmit(false);
        });
      });
    }
  }, [authInfo, accessToken, orgID, selectedOrg, gotMessages]);

  function submit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    let url = "";
    if (window.location.href.includes("http://localhost")) {
      url = "http://localhost:8080/api/chat";
    } else {
      url = "/api/chat";
    }
    let newMessages = [...messages];
    newMessages.push({
      content: currentMessage,
      role: "user",
    });
    setMessages(newMessages);
    setCurrentMessage("");
    setDisableSubmit(true);
    fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Org-ID": orgID,
      },
      body: JSON.stringify(newMessages),
    }).then((data) => {
      let jsonPromise = data.json();
      Promise.resolve(jsonPromise).then((jsonResponse) => {
        let newMessages2 = [...newMessages];
        newMessages2.push(jsonResponse);
        setMessages(newMessages2);
        setDisableSubmit(false);
      });
    });
  }

  return (
    <>
      <div>
        {messages.map((value, idx) => {
          if (value.role === "user") {
            return (
              <div key={idx}>
                <div className={classes.fredMessage}>You: {value.content}</div>
              </div>
            );
          }
          return (
            <div key={idx}>
              <div className={classes.youMessage}>Fred: {value.content}</div>
            </div>
          );
        })}
      </div>
      <form onSubmit={submit} style={{ width: "98%" }}>
        <TextInput
          placeholder="What would you like to ask?"
          onChange={(event) => {
            setCurrentMessage(event.target.value);
          }}
          value={currentMessage}
          rightSection={
            <Button disabled={disableSubmit} type="submit">
              {(disableSubmit && <Loader size={16} />) || "Submit"}
            </Button>
          }
        ></TextInput>
      </form>
    </>
  );
}
