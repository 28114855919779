import { Center, Text } from "@mantine/core";

export default function FourOhFour() {
  return (
    <Center style={{ width: "100%", height: "90%" }}>
      <div>
        <Text size={"xl"} weight={700}>
          404
        </Text>
        <Text size={"xl"}>Sorry, we're unable to find this page</Text>
      </div>
    </Center>
  );
}
