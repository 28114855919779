import React from "react";
import { Grid, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

interface IPageTitleProps {
  title: string;
  subTitle?: string;
  actions?: React.ReactNode[];
}

export const PageTitle: React.FC<IPageTitleProps> = ({
  title,
  subTitle,
  actions,
}) => {
  const isMobile = useMediaQuery("(max-width: 50em)");
  return (
    <Grid justify="space-between">
      <Grid.Col span="content">
        <Title align="left" order={2} mb={10}>
          {title}
        </Title>
        {subTitle && (
          <Text
            align="left"
            color="dimmed"
            style={
              isMobile
                ? { wordWrap: "break-word", maxWidth: "200px" }
                : undefined
            }
          >
            {subTitle}
          </Text>
        )}
      </Grid.Col>
      <Grid.Col span="content">
        {actions && (
          <Grid justify="space-between">
            {actions?.map((action, index) => (
              <Grid.Col key={index} span="content">
                {action}
              </Grid.Col>
            ))}
          </Grid>
        )}
      </Grid.Col>
    </Grid>
  );
};
