import React, { useState, useEffect } from "react";
import { useAuthInfo, useActiveOrg } from "@propelauth/react";
import { Text, Tabs } from "@mantine/core";
import { FaSalesforce, FaHubspot } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import {
  SalesforceTableData,
  HubspotTableData,
  HubspotTableDataLoaded,
  SalesforceTableDataLoaded,
  ActivityCaptureTableData,
  ActivityCaptureTableDataLoaded,
} from "./types";
import { MdEmail } from "react-icons/md";
import { logPageView } from "../utils/segment";
import CreateAnIntegration from "../signup/CreateAnIntegration";
import { getCurrentOrg } from "../utils/org";
import { DataTable } from "components/data-table";

const urls = {
  accounts: "/salesforce/accounts",
  leads: "/salesforce/leads",
  users: "/salesforce/users",
  opportunities: "/salesforce/opportunities",
  opportunity_histories: "/salesforce/opportunity_histories",
  territory2: "/salesforce/territory2",
  user_territory2_associations: "/salesforce/user_territory2_associations",
  object_territory2_associations: "/salesforce/object_territory2_associations",
  opportunity_stages: "/salesforce/opportunity_stages",
  opportunity_feeds: "/salesforce/opportunity_feeds",
  opportunity_shares: "/salesforce/opportunity_shares",
  product2: "/salesforce/product2",
  opportunity_line_items: "/salesforce/opportunity_line_items",
  opportunity_partners: "/salesforce/opportunity_partners",
  opportunity_contact_roles: "/salesforce/opportunity_contact_roles",
  owners: "/hubspot/owners",
  contacts: "/hubspot/contacts",
  companies: "/hubspot/companies",
  engagements_notes: "/hubspot/engagements_notes",
  engagements: "/hubspot/engagements",
  deals: "/hubspot/deals",
  line_items: "/hubspot/line_items",
  deal_pipeline: "/hubspot/deal_pipeline",
  deal_pipeline_stage: "/hubspot/deal_pipeline_stage",
  engagements_call: "/hubspot/engagements_call",
  engagements_email: "/hubspot/engagements_email",
  engagements_meeting: "/hubspot/engagements_meeting",
  engagements_task: "/hubspot/engagements_task",
  // products: "/hubspot/products",
  email: "/activity/email",
  calendar_event: "/activity/calendar_event",
  contact: "/activity/contact",
  email_thread: "/activity/email_thread",
};

export function DataDisplay() {
  const navigate = useNavigate();
  const { tabValue } = useParams();
  const authInfo = useAuthInfo();

  useEffect(() => {
    if (!authInfo.loading) {
      logPageView(
        authInfo.user?.email,
        authInfo.user?.firstName + " " + authInfo.user?.lastName
      );
    }
    // eslint-disable-next-line
  }, [authInfo.loading]);

  const [salesforceSeeded, setSalesforceSeeded] = useState(false);
  const [hubspotSeeded, setHubspotSeeded] = useState(false);
  const [activitySeeded, setActivitySeeded] = useState(false);

  const [hasHubspotIntegration, setHasHubspotIntegration] = useState(false);
  const [hasSalesforceIntegration, setHasSalesforceIntegration] =
    useState(false);
  const hasActivityIntegration = true;
  const [loadingHubspotIntegration, setLoadingHubspotIntegration] =
    useState(true);
  const [loadingSalesforceIntegration, setLoadingSalesforceIntegration] =
    useState(true);
  const loadingActivityIntegration = false;

  let accessToken: string | null = null;
  if (!authInfo.loading) {
    accessToken = authInfo.accessToken;
  }
  const urlSearchParams = new URLSearchParams(window.location.search);
  const iOrgId = urlSearchParams.get("org_id");

  const selectedOrg = useActiveOrg();
  const [orgID, setOrgID] = useState<string>("");
  useEffect(() => {
    if (orgID && orgID.length > 0) {
      return;
    }
    if (!authInfo.loading) {
      getCurrentOrg(selectedOrg, authInfo, accessToken).then((value) => {
        if (value) {
          let localOrgID = value.orgId;
          if (!localOrgID) {
            setOrgID("");
          } else {
            setOrgID(localOrgID);
          }
        }
      });
    }
  }, [authInfo, accessToken, orgID, selectedOrg]);

  useEffect(() => {
    if (!orgID || orgID.length === 0) {
      return;
    }
    let url = "";
    if (window.location.href.includes("http://localhost")) {
      url = "http://localhost:8080/api/integrations/hubspot";
    } else {
      url = "/api/integrations/hubspot";
    }
    fetch(url, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Org-ID": orgID,
      },
    }).then((data) => {
      let jsonPromise = data.json();
      Promise.resolve(jsonPromise).then((jsonResponse) => {
        setLoadingHubspotIntegration(false);
        if (jsonResponse.length > 0) {
          setHasHubspotIntegration(true);
        } else {
          setHasHubspotIntegration(false);
        }
      });
    });
  }, [accessToken, tabValue, orgID]);

  useEffect(() => {
    if (!orgID || orgID.length === 0) {
      return;
    }
    let url = "";
    if (window.location.href.includes("http://localhost")) {
      url = "http://localhost:8080/api/integrations/salesforce";
    } else {
      url = "/api/integrations/salesforce";
    }
    fetch(url, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Org-ID": orgID,
      },
    }).then((data) => {
      let jsonPromise = data.json();
      Promise.resolve(jsonPromise).then((jsonResponse) => {
        setLoadingSalesforceIntegration(false);
        if (jsonResponse.length > 0) {
          setHasSalesforceIntegration(true);
        } else {
          setHasSalesforceIntegration(false);
        }
      });
    });
  }, [accessToken, tabValue, orgID]);

  const [salesforceTableDataLoaded, setSalesforceTableDataLoaded] =
    useState<SalesforceTableDataLoaded>({
      accounts: false,
      leads: false,
      users: false,
      opportunities: false,
      opportunity_histories: false,
      territory2: false,
      user_territory2_associations: false,
      object_territory2_associations: false,
      opportunity_stages: false,
      opportunity_feeds: false,
      opportunity_shares: false,
      product2: false,
      opportunity_line_items: false,
      opportunity_partners: false,
      opportunity_contact_roles: false,
    });

  const [hubspotTableDataLoaded, setHubspotTableDataLoaded] =
    useState<HubspotTableDataLoaded>({
      owners: false,
      contacts: false,
      companies: false,
      engagements_notes: false,
      engagements: false,
      deals: false,
      line_items: false,
      engagements_call: false,
      engagements_email: false,
      engagements_meeting: false,
      engagements_task: false,
      // products: false,
    });

  const [salesforceTableData, setSalesforceTableData] =
    useState<SalesforceTableData>({
      accounts: [],
      leads: [],
      users: [],
      opportunities: [],
      opportunity_histories: [],
      territory2: [],
      user_territory2_associations: [],
      object_territory2_associations: [],
      opportunity_stages: [],
      opportunity_feeds: [],
      opportunity_shares: [],
      product2: [],
      opportunity_line_items: [],
      opportunity_partners: [],
      opportunity_contact_roles: [],
    });

  const [hubspotTableData, setHubspotTableData] = useState<HubspotTableData>({
    owners: [],
    contacts: [],
    companies: [],
    engagements_notes: [],
    engagements: [],
    deals: [],
    line_items: [],
    engagements_call: [],
    engagements_email: [],
    engagements_meeting: [],
    engagements_task: [],
    // products: [],
  });

  const [activityTableData, setActivityTableData] =
    useState<ActivityCaptureTableData>({
      email: [],
      calendar_event: [],
      contact: [],
      email_thread: [],
    });

  const [activityTableDataLoaded, setActivityTableDataLoaded] =
    useState<ActivityCaptureTableDataLoaded>({
      email: false,
      calendar_event: false,
      contact: false,
      email_thread: false,
    });

  useEffect(() => {
    if (!orgID || orgID.length === 0) {
      return;
    }
    async function getData() {
      if (tabValue === "salesforce") {
        if (salesforceSeeded) {
          return;
        }
        setSalesforceSeeded(true);
      } else if (tabValue === "hubspot") {
        if (hubspotSeeded) {
          return;
        }
        setHubspotSeeded(true);
      } else if (tabValue === "activity") {
        if (activitySeeded) {
          return;
        }
        setActivitySeeded(true);
      }

      Object.entries(urls).map(([k, value], idx) => {
        if (tabValue && !value.includes(tabValue)) {
          return undefined;
        }
        let url = "";
        if (window.location.href.includes("http://localhost")) {
          url = "http://localhost:8080/api/data" + value;
        } else {
          url = "/api/data" + value;
        }
        return fetch(url, {
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
            "Org-ID": orgID,
          },
        }).then((data) => {
          let jsonPromise = data.json();
          Promise.resolve(jsonPromise).then((jsonResponse) => {
            if (url.includes("salesforce")) {
              salesforceTableData[k as keyof SalesforceTableData] =
                jsonResponse[k];
              setSalesforceTableData({ ...salesforceTableData });
              salesforceTableDataLoaded[k as keyof SalesforceTableDataLoaded] =
                true;
              setSalesforceTableDataLoaded({ ...salesforceTableDataLoaded });
            }
            if (url.includes("hubspot")) {
              hubspotTableData[k as keyof HubspotTableData] = jsonResponse[k];
              setHubspotTableData({ ...hubspotTableData });
              hubspotTableDataLoaded[k as keyof HubspotTableDataLoaded] = true;
              setHubspotTableDataLoaded({ ...hubspotTableDataLoaded });
            }
            if (url.includes("activity")) {
              activityTableData[k as keyof ActivityCaptureTableData] =
                jsonResponse[k];
              setActivityTableData({ ...activityTableData });
              activityTableDataLoaded[
                k as keyof ActivityCaptureTableDataLoaded
              ] = true;
              setActivityTableDataLoaded({ ...activityTableDataLoaded });
            }
          });
        });
      });
    }
    getData();
  }, [
    tabValue,
    accessToken,
    hubspotSeeded,
    salesforceSeeded,
    hubspotTableData,
    hubspotTableDataLoaded,
    salesforceTableData,
    salesforceTableDataLoaded,
    orgID,
    activityTableData,
    activityTableDataLoaded,
    activitySeeded,
  ]);

  return (
    <>
      <Text size="xl" weight={500}>
        Data
      </Text>
      <Tabs
        radius="lg"
        value={tabValue}
        onTabChange={(value) => {
          if (value === "salesforce") {
            setHubspotSeeded(false);
            setActivitySeeded(false);
          } else if (value === "hubspot") {
            setSalesforceSeeded(false);
            setActivitySeeded(false);
          } else if (value === "activity") {
            setSalesforceSeeded(false);
            setHubspotSeeded(false);
          }
          if (iOrgId) {
            navigate(`/data/${value}?org_id=${iOrgId}`);
          } else {
            navigate(`/data/${value}`);
          }
        }}
      >
        <Tabs.List>
          <Tabs.Tab value="salesforce" icon={<FaSalesforce size={14} />}>
            Salesforce
          </Tabs.Tab>
          <Tabs.Tab value="hubspot" icon={<FaHubspot size={14} />}>
            Hubspot
          </Tabs.Tab>
          <Tabs.Tab value="activity" icon={<MdEmail size={14} />}>
            Activity
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="salesforce" pt="xs">
          {!loadingSalesforceIntegration &&
            hasSalesforceIntegration &&
            Object.entries(salesforceTableData).map(([k, value], idx) => {
              return (
                <div key={idx}>
                  <h2>{k}</h2>
                  <DataTable
                    rowData={value}
                    isLoading={!salesforceTableDataLoaded[k]}
                  />
                </div>
              );
            })}
          {!loadingSalesforceIntegration && !hasSalesforceIntegration && (
            <CreateAnIntegration salesforce={true} />
          )}
        </Tabs.Panel>

        <Tabs.Panel value="hubspot" pt="xs">
          {!loadingHubspotIntegration &&
            hasHubspotIntegration &&
            Object.entries(hubspotTableData).map(([k, value], idx) => {
              return (
                <div key={idx}>
                  <h2>{k}</h2>
                  <DataTable
                    rowData={value}
                    isLoading={!hubspotTableDataLoaded[k]}
                  />
                </div>
              );
            })}
          {!loadingHubspotIntegration && !hasHubspotIntegration && (
            <CreateAnIntegration hubspot={true} />
          )}
        </Tabs.Panel>
        <Tabs.Panel value="activity" pt="xs">
          {!loadingActivityIntegration &&
            hasActivityIntegration &&
            Object.entries(activityTableData).map(([k, value], idx) => {
              console.log(value);
              return (
                <div key={idx}>
                  <h2>{k}</h2>
                  <DataTable
                    rowData={value}
                    isLoading={!activityTableDataLoaded[k]}
                  />
                </div>
              );
            })}
          {!loadingActivityIntegration && !hasActivityIntegration && (
            <CreateAnIntegration hubspot={true} />
          )}
        </Tabs.Panel>
      </Tabs>
    </>
  );
}
