import React from "react";
import {
  Button,
  TextInput,
  Grid,
  Title,
  LoadingOverlay,
  Text,
  Anchor,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";
import { FaExchangeAlt, FaSalesforce } from "react-icons/fa";

import bearLogo from "assets/logo.png";
import { useAuthInfo } from "@propelauth/react";
import { useMutation } from "@tanstack/react-query";
import { postFetcher } from "fetchers/fetchers";

interface ISalesForceFormProps {
  initialValues?: any;
  opened?: boolean;
  onClose: () => void;
  canImpersonate?: boolean;
}

export const SalesForceForm: React.FC<ISalesForceFormProps> = ({
  initialValues,
  opened,
  onClose,
  canImpersonate,
}) => {
  const { t } = useTranslation();
  const authInfo = useAuthInfo();

  const form = useForm({
    initialValues,
  });

  const { mutate: mutateCreate, isLoading } = useMutation(
    (payload: any) =>
      postFetcher(
        "/api/integrations/salesforce",
        authInfo?.accessToken,
        payload
      ),
    {
      onSuccess: (response) => {
        onClose();
        window.location.href = response.url;
      },
    }
  );

  return (
    <div>
      <LoadingOverlay visible={isLoading} />
      <Grid>
        <Grid.Col span={12}>
          <Grid justify="center" align="center">
            <Grid.Col span="content">
              <FaSalesforce size={50} />
            </Grid.Col>
            <Grid.Col span="content">
              <FaExchangeAlt size={20} color="#ADB5BD" />
            </Grid.Col>
            <Grid.Col span="content">
              <img src={bearLogo} width={50} className="bear-logo" alt="logo" />
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col span={12}>
          <Title align="center" order={4}>
            {t("Connect Salesforce with Bearworks")}
          </Title>
        </Grid.Col>
        <Grid.Col span={12}>
          <form
            onSubmit={form.onSubmit((values) => {
              const salesforceInstance = canImpersonate
                ? values.salesforce_instance
                : "Salesforce Account";
              mutateCreate({
                salesforce_instance: salesforceInstance,
                redirect_url: window.location.href,
                redirect_host: window.location.host,
              });
            })}
          >
            <Grid>
              {canImpersonate && (
                <Grid.Col span={12}>
                  <TextInput
                    withAsterisk
                    label={t("What's Your Salesforce Instance Name?")}
                    placeholder={t("my instance")}
                    {...form.getInputProps("salesforce_instance")}
                  />
                </Grid.Col>
              )}
              <Grid.Col span={12}>
                <Text size="sm" align="center" weight={500}>
                  {t("First, make sure to click the link to install the ")}
                  <Anchor
                    target="_blank"
                    href="https://login.salesforce.com/packaging/installPackage.apexp?p0=04tDp000000u5SM"
                  >
                    {t("salesforce package")}
                  </Anchor>
                </Text>
              </Grid.Col>
              <Grid.Col span={12}>
                <Grid align="center">
                  {/* <Grid.Col span="auto">
                    <Button variant="default" mt={10}>
                      {t("Need help?")}
                    </Button>
                  </Grid.Col> */}
                  <Grid.Col span="auto">
                    <Button variant="default" onClick={onClose}>
                      {t("Close")}
                    </Button>
                  </Grid.Col>
                  <Grid.Col span="content">
                    <Button type="submit">{t("Integrate")}</Button>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          </form>
        </Grid.Col>
      </Grid>
    </div>
  );
};
