import React, { useState } from "react";
import _ from "lodash";
import {
  Button,
  Divider,
  Grid,
  Group,
  MultiSelect,
  Popover,
  Text,
  TextInput,
  createStyles,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";

interface IMetricsQueryWidgetProps {
  placeholder?: string;
  disabled?: boolean;
  options: any[];
  value?: string[];
  onChange: (value: string[]) => void;
}

const useStyles = createStyles((_params) => {
  return {
    row: {
      cursor: "pointer",
      ":hover": {
        backgroundColor: "#F8F9FA",
      },
    },
    rowActive: {
      cursor: "pointer",
      color: "#1864AB",
      backgroundColor: "#E7F5FF",
    },
    dropdown: {
      height: 500,
      overflowY: "scroll",
    },
  };
});

export const MetricsQueryWidget: React.FC<IMetricsQueryWidgetProps> = ({
  placeholder,
  disabled,
  options,
  value,
  onChange,
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [activeGroup, setActiveGroup] = useState<string>("");
  const [opened, { toggle }] = useDisclosure(false);
  const [search, setSearch] = useState<string>("");

  const groups = _.groupBy(options, "group");
  return (
    <Popover
      opened={opened}
      onChange={toggle}
      closeOnClickOutside
      closeOnEscape
      disabled={disabled}
      width={400}
    >
      <Popover.Target>
        <div
          onFocus={() => {
            toggle();
          }}
        >
          <MultiSelect
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            data={options}
            styles={{
              dropdown: {
                display: "none",
              },
            }}
            onChange={(value) => {
              onChange(value);
            }}
          />
        </div>
      </Popover.Target>
      <Popover.Dropdown mt={5}>
        <Grid>
          <Grid.Col span={12}>
            <TextInput
              value={search}
              onChange={(event) => {
                setSearch(event.currentTarget.value);
              }}
              placeholder={t("Search Metric")}
              icon={<FaSearch size="0.8rem" />}
            />
          </Grid.Col>
        </Grid>
        <Group mt={15}>
          <Button
            radius={8}
            variant="default"
            onClick={() => {
              setActiveGroup("");
            }}
          >
            {t("All")}
          </Button>
          {Object.keys(groups).map((group: string) => (
            <Button
              radius={8}
              variant={activeGroup === group ? "light" : "default"}
              onClick={() => {
                setActiveGroup(group);
              }}
              key={group}
            >
              {group}
            </Button>
          ))}
        </Group>
        <Grid className={classes.dropdown} mt={10}>
          {Object.keys(groups)
            .filter(
              (group: string) =>
                groups[group].filter(
                  (item: any) =>
                    item.group === group &&
                    (search === "" ||
                      item.label.toLowerCase().includes(search.toLowerCase()))
                ).length > 0 &&
                (activeGroup === "" || activeGroup === group)
            )
            .map((group: any) => (
              <Grid.Col
                key={group}
                mt={10}
                style={{ textAlign: "left" }}
                span={12}
              >
                <Divider label={group} labelProps={{ color: "dimmed" }} />
                {groups[group]
                  .filter(
                    (item: any) =>
                      item.group === group &&
                      (search === "" ||
                        item.label.toLowerCase().includes(search.toLowerCase()))
                  )
                  .map((item: any) => (
                    <Text
                      p={10}
                      ml={-10}
                      mr={-10}
                      size="xs"
                      key={item.value}
                      className={
                        value?.includes(item.value)
                          ? classes.rowActive
                          : classes.row
                      }
                      onClick={() => {
                        if (value?.includes(item.value)) {
                          onChange(
                            value.filter((v: string) => v !== item.value)
                          );
                        } else {
                          onChange([...(value || []), item.value]);
                        }
                      }}
                    >
                      {item.label}
                    </Text>
                  ))}
              </Grid.Col>
            ))}
        </Grid>
      </Popover.Dropdown>
    </Popover>
  );
};
