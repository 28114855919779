import React from "react";
import { Button, TextInput, Grid, Title, LoadingOverlay } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";
import { FaExchangeAlt } from "react-icons/fa";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthInfo } from "@propelauth/react";

import bearLogo from "assets/logo.png";
import { getFetcher, patchFetcher, postFetcher } from "fetchers/fetchers";
import { TbWebhook } from "react-icons/tb";
import { IError } from "interfaces/errors";
import { useQuery } from "@tanstack/react-query";
import {
  errorNotification,
  processNotification,
  successNotification,
  syncNotification,
} from "utils/notifications";

interface IWebhookFormProps {
  initialValues?: any;
  opened?: boolean;
  editing?: boolean;
  onClose: () => void;
}

export const WebhookForm: React.FC<IWebhookFormProps> = ({
  initialValues,
  onClose,
  editing = false,
}) => {
  const { t } = useTranslation();
  const authInfo = useAuthInfo();
  const queryClient = useQueryClient();

  const { data: integrations } = useQuery<any, IError>(
    ["/api/integrations/organization"],
    () => getFetcher("/api/integrations/organization", authInfo?.accessToken)
  );

  const form = useForm({
    initialValues,
  });

  const { mutate: mutateCreate, isLoading } = useMutation(
    (payload) => {
      if (editing) {
        return patchFetcher(
          "/api/integrations/webhook",
          authInfo?.accessToken,
          payload
        );
      } else {
        return postFetcher(
          "/api/integrations/webhook",
          authInfo?.accessToken,
          payload
        );
      }
    },
    {
      onSuccess: () => {
        onClose();
        queryClient.invalidateQueries(["/api/integrations/organization"]);
        successNotification(
          "createWebhookIntegration",
          t("Webhook has been saved.")
        );
        if (integrations.length === 0) {
          syncNotification("createWebhookIntegration");
        }
      },
      onError: (error: IError) => {
        console.error(error);
        errorNotification("createWebhookIntegration");
      },
    }
  );

  return (
    <div>
      <LoadingOverlay visible={isLoading} />
      <Grid>
        <Grid.Col span={12}>
          <Grid justify="center" align="center">
            <Grid.Col span="content">
              <TbWebhook size={50} />
            </Grid.Col>
            <Grid.Col span="content">
              <FaExchangeAlt size={20} color="#ADB5BD" />
            </Grid.Col>
            <Grid.Col span="content">
              <img src={bearLogo} width={50} className="bear-logo" alt="logo" />
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col span={12}>
          <Title align="center" order={4}>
            {editing ? t("Edit Webhook") : t("Add a new Webhook")}
          </Title>
        </Grid.Col>
        <Grid.Col span={12}>
          <form
            onSubmit={form.onSubmit((values) => {
              processNotification(
                "createWebhookIntegration",
                t("Saving webhook...")
              );
              mutateCreate(values);
            })}
          >
            <Grid>
              <Grid.Col span={12}>
                <TextInput
                  withAsterisk
                  label={t("Name Your Webhook")}
                  placeholder={t("my webhook")}
                  {...form.getInputProps("name")}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <TextInput
                  withAsterisk
                  label={t("What Is Your Webhook URL?")}
                  placeholder={t("https://hooks.zapier.com/hooks/catch/...")}
                  {...form.getInputProps("webhook_url")}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <Grid align="center" justify="space-between">
                  {/* <Grid.Col span="auto">
                    <Button variant="default" mt={10}>
                      {t("Need help?")}
                    </Button>
                  </Grid.Col> */}
                  <Grid.Col span="content">
                    <Button variant="default" onClick={onClose}>
                      {t("Close")}
                    </Button>
                  </Grid.Col>
                  <Grid.Col span="content">
                    <Button type="submit">{t("Integrate")}</Button>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          </form>
        </Grid.Col>
      </Grid>
    </div>
  );
};
