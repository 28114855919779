import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";
import {
  Button,
  Divider,
  Grid,
  Input,
  LoadingOverlay,
  Modal,
  Paper,
  Text,
  Title,
  createStyles,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAuthInfo } from "@propelauth/react";

import { logPageView } from "utils/segment";

import { PageTitle } from "components/page-title";
import { TableSheet } from "components/table-sheet";
import { deleteFetcher, getFetcher, postFetcher } from "fetchers/fetchers";
import { LoadingSpinner } from "components/loading-spinner";
import { MetricsExplorerQueryForm } from "components/metrics-explorer-query-form";
import { IMetaData, IMetricQuery } from "interfaces/metrics";
import { IError } from "interfaces/errors";
import { useDisclosure } from "@mantine/hooks";
import {
  errorNotification,
  processNotification,
  successNotification,
} from "utils/notifications";

const useStyles = createStyles((theme) => ({
  inputField: {
    marginBottom: theme.spacing.md,
    width: "33%",
  },
  submitButton: {
    textAlign: "right",
  },
  paper: {
    marginTop: theme.spacing.lg,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  marginBottomConditionally: {
    marginBottom: theme.spacing.md,
  },
}));

export const Sheet: React.FC = () => {
  const { t } = useTranslation();
  const authInfo = useAuthInfo();
  const { sheetID } = useParams();
  const navigate = useNavigate();
  const [isDeleteOpened, { open: isDeleteOpen, close: isDeleteClose }] =
    useDisclosure(false);
  const [tableLimit, setTableLimit] = useState<number>(10);
  const [tableOffset, setTableOffset] = useState<number>(0);
  const [tableTotal, setTableTotal] = useState<number>(0);
  const [name, setName] = useState<string>("");

  const [metricQuery, setMetricQuery] = useState<IMetricQuery | undefined>();
  const [tableQuery, setTableQuery] = useState<IMetricQuery | undefined>();

  const [queryRowData, setQueryRowData] = useState<any[]>([]);
  const [userRowData, setUserRowData] = useState<any[]>([]);
  const [userSolvedRowData, setUserSolvedRowData] = useState<any[]>([]);
  const isNewSheet = sheetID === "new";
  const { classes } = useStyles();

  useEffect(() => {
    if (!authInfo.loading) {
      logPageView(
        authInfo.user?.email,
        authInfo.user?.firstName + " " + authInfo.user?.lastName
      );
    }
    // eslint-disable-next-line
  }, [authInfo.loading]);

  const { data: metaData, isLoading } = useQuery<IMetaData, IError>(
    ["/api/query/get_metadata"],
    () => postFetcher("/api/query/get_metadata", authInfo?.accessToken, {})
  );

  const { mutate: mutateSolve, isLoading: isLoadingSolve } = useMutation(
    (payload: any) =>
      postFetcher("/api/sheets/solve", authInfo?.accessToken, payload),
    {
      onSuccess: (response) => {
        setUserSolvedRowData(response);
      },
    }
  );

  const { mutate: mutateTableQuery, isLoading: isLoadingTableQuery } =
    useMutation(
      (payload: any) =>
        postFetcher(
          `/api/query/query?${queryString.stringify({
            limit: tableLimit,
            offset: tableOffset,
          })}`,
          authInfo?.accessToken,
          payload
        ),
      {
        onSuccess: (response) => {
          if (queryRowData.length > 0) {
            // not first time
            setUserRowData([]);
            setUserSolvedRowData([]);
          }
          setQueryRowData(response.results || []);
          setTableTotal(response.total || 0);
        },
        onError: (error: IError) => {
          console.error(error);
          errorNotification("Sheet");
        },
      }
    );

  useEffect(() => {
    if (tableQuery) {
      mutateTableQuery({
        measures: tableQuery.measures,
        dimensions: tableQuery.dimensions,
        filters: tableQuery.filters,
        timeDimension: tableQuery.timeDimension,
        transform: tableQuery.transform,
      });
    }
  }, [mutateTableQuery, tableQuery, tableLimit, tableOffset]);

  const { mutate: mutateGet, isLoading: isLoadingGet } = useMutation(
    (sheetId: string) =>
      getFetcher(`/api/sheets/${sheetId}`, authInfo?.accessToken),
    {
      onSuccess: (response: any) => {
        setName(response?.name);
        setMetricQuery(response?.query);
        setUserSolvedRowData(response?.saved_result);
        setUserRowData(response?.user_defined_rows);
        if (response?.query) {
          setTableQuery(response?.query);
        }
      },
    }
  );

  const { mutate: mutateSave, isLoading: isLoadingSave } = useMutation(
    (payload: any) =>
      postFetcher(`/api/sheets/${sheetID}`, authInfo?.accessToken, payload),
    {
      onSuccess: (response) => {
        successNotification("Sheet", t("The sheet has been saved."));
        navigate(`/sheets/${response.id}`);
      },
    }
  );

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    () => deleteFetcher(`/api/sheets/${sheetID}`, authInfo?.accessToken),
    {
      onSuccess: (response) => {
        successNotification("Sheet", t("The sheet has been deleted."));
        navigate("/sheets/");
      },
    }
  );

  useEffect(() => {
    if (sheetID && sheetID !== "new") {
      mutateGet(sheetID);
    }
    // eslint-disable-next-line
  }, [mutateGet, sheetID]);

  if (isLoadingGet || isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid p={24}>
      <Grid.Col>
        <PageTitle title={isNewSheet ? t("New Sheet") : t("Sheet")} />
        <Grid>
          <Grid.Col span={12}>
            <Paper
              className={classes.paper}
              p="md"
              radius="sm"
              style={{ marginBottom: "20px", textAlign: "left" }}
            >
              <Title align="left" order={4} mb={10}>
                {t("Spreadsheet Name")}
              </Title>
              <Text color="gray" align="left" size="xs" mb={10}>
                {t("What is your Spreadsheet name?")}
              </Text>
              <Input
                value={name}
                placeholder={t("My New Sheet")}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setName(e.target.value)
                }
              />
            </Paper>
            <Paper
              className={classes.paper}
              p="md"
              radius="sm"
              style={{ marginBottom: "20px", textAlign: "left" }}
            >
              <MetricsExplorerQueryForm
                metaData={metaData}
                runBtnText={t("Apply Query")}
                value={metricQuery}
                onChange={(query) => {
                  setMetricQuery(query);
                  setTableQuery(query);
                  setTableOffset(0);
                }}
              />
            </Paper>
            <Paper
              className={classes.paper}
              p="md"
              radius="sm"
              style={{ marginBottom: "20px", textAlign: "left" }}
            >
              <TableSheet
                title={t("Sheet")}
                subTitle={t(
                  "Provide formulas in sheet cells to calculate metrics."
                )}
                isLoading={isLoadingTableQuery || isLoadingSolve}
                queryRowData={queryRowData}
                userFormulasRowData={userRowData}
                userSolvedRowData={userSolvedRowData}
                onCellValueChanged={(event) => {
                  if (!event.rowIndex && event.rowIndex !== 0) {
                    return;
                  }
                  const newUserDefinedRows = structuredClone(userRowData);
                  newUserDefinedRows[event.rowIndex] = event.data;
                  setUserRowData(newUserDefinedRows);
                  mutateSolve({
                    user_defined_rows: newUserDefinedRows,
                    query: metricQuery,
                  });
                }}
                pagination={{
                  limit: tableLimit,
                  offset: tableOffset,
                  total: tableTotal,
                  onChange: (offset, limit) => {
                    setTableOffset(offset);
                    setTableLimit(limit);
                  },
                }}
              />
            </Paper>
            <div style={{ textAlign: "right" }}>
              {!isNewSheet && (
                <Button
                  color="red"
                  onClick={isDeleteOpen}
                  style={{ marginRight: "8px" }}
                >
                  {t("Delete")}
                </Button>
              )}
              <Button
                disabled={!name || name.length === 0 || !queryRowData}
                onClick={() => {
                  mutateSave({
                    user_defined_rows: userRowData,
                    query: metricQuery,
                    name: name,
                  });
                }}
                loading={isLoadingSave}
              >
                {t("Save")}
              </Button>
            </div>
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Modal
        opened={isDeleteOpened}
        onClose={isDeleteClose}
        title={<Text size="lg">{t("Are you sure?")}</Text>}
      >
        <LoadingOverlay visible={isLoadingDelete} />
        <Grid align="center" justify="space-between">
          <Grid.Col span="content">
            {t("Are you sure you want to delete this sheet?")}
          </Grid.Col>
        </Grid>
        <Divider my="sm" color="#E9E9E9" />
        <Grid align="center" justify="space-between">
          <Grid.Col span="content">
            <Button variant="default" onClick={isDeleteClose}>
              {t("No")}
            </Button>
          </Grid.Col>
          <Grid.Col span="content">
            <Button
              autoFocus
              color="red"
              onClick={() => {
                processNotification("Sheet", t("Deleting sheet..."));
                mutateDelete();
              }}
            >
              {t("Yes")}
            </Button>
          </Grid.Col>
        </Grid>
      </Modal>
    </Grid>
  );
};
